import React from 'react';
import { Button, Select, Input } from 'components';
import { BoardService } from 'services';

interface Props {
	onClose: () => void;
	item?: any;
}

class ModalCreateBoard extends React.Component<Props> {

	state = {
		form: {
			name: '',
			area_id: ''
		},
		areas: []
	}

	componentDidMount() {
		this.load();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	load = async () => {
		const res: any = await BoardService.admin.getAreas();
		this.setState({
			areas: res.areas
		},() => {
			if (this.props.item) {
				this.setState({
					form: {
						...this.state.form,
						name: this.props.item?.name || '',
						area_id: this.props.item?.area_id || ''
					}
				});
			}
		});
	}

	submit = async () => {
		if (this.props.item) {
			await BoardService.admin.update({
				...this.state.form,
				board_id: this.props.item?.id
			});
		}
		else {
			await BoardService.admin.create({
				...this.state.form
			});
		}
		this.props.onClose();
	}

	render() {
		return (
			<div id="modal-create-board">
				<div className="content-create">
					<div className="col-md-12">
						<Input 
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Select
							label="Área"
							name="area_id"
							onChange={ this.change }
							value={ this.state.form.area_id }
							options={ this.state.areas.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
				</div>
				<div className="row row-buttons">
					<div className="col-6">
						<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
					<div className="col-6">
						<Button type="button" className="btn-save" onClick={ () => this.submit() }>
							Guardar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalCreateBoard;