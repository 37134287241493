import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { ChecklistService } from 'services';
import { Globals } from 'utils';
import { ViewIcon, EditIcon, TrashIcon, AddCircleRed, CloseIcon } from 'assets/icons';
import { Input, CreateButton, Table, Pagination, Tooltip, Modal } from 'components';
import ModalView from './modal-view';
import ModalUsers from './modal-users';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	worker: ''
}

class Checklist extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Tarea',
			'Trabajador',
			'Área',
			'Responsable',
			'Acción'
		],
		checklist: null,
		visible_view: false,
		visible: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Checklist',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Checklist'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ChecklistService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.checklist.rows,
			last_page: res.checklist.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.props.history.push('/admin/checklist/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el checklist?',async () => {
			await ChecklistService.admin.delete({
				checklist_id: item.id
			});
			this.load(true);
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			checklist: item
		});
	}

	addUser = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			checklist: item
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.props.history.push('/admin/checklist/edit/' + item.id);
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			checklist: null
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			checklist: null
		},() => this.load(true));
	}
	
	render() {
		const checklist: any = this.state.checklist;
		const { visible_view, visible } = this.state;

		return (
			<div id="checklist">
				<Modal
		          className="modal-view-checklist"
		          visible={ visible_view }
		          title={ checklist?.name }
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalView
		          	checklist={ checklist }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

		      	<Modal
		          className="modal-checklist-users"
		          visible={ visible }
		          title={
		          	<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-order">
		          				<h3>Cargar trabajador</h3>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onClose();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onClose() }
		        >
		          <ModalUsers
		          	checklist={ checklist }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.worker }
							name="worker"
							placeholder="Búsqueda por trabajador"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-6"></div>
					<div className="col-md-6 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Checklist" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.name }</td>
								<td>
									{
										i.users.length == 0 && (
											<span>Sin asignar</span>
										)
									}
									{
										i.users.map((item: any) => {
											return (
												<p className="item-red">{ item?.user?.name + ' ' + item?.user?.lastname }</p>
											)
										})
									}
								</td>
								<td>{ i?.level?.name }</td>
								<td>{ i.supervisor ? (i?.supervisor?.name + ' ' + i?.supervisor?.lastname) : '' }</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Cargar trabajador">
										<a className="link-icon" href="#" onClick={ (e: any) => this.addUser(e,i) }>
											<img src={ AddCircleRed } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Checklist);