import React from 'react';
import { Button } from 'components';
import { Constants } from 'utils';
import { TimeRedIcon, LocationIcon, ProfileIcon, PhoneRedIcon, MailRedIcon } from 'assets/icons';

interface Props {
	onClose: () => void;
	provider: any;
}

class ModalViewProvider extends React.Component<Props> {

	render() {
		const { provider } = this.props;

		return (
			<div id="modal-view-provider">
				<div className="row">
					<div className="col-md-6">
						<p># { provider?.code }</p>
					</div>
					<div className="col-md-6 text-right">
						<div className={ `change-status ${ provider?.status == Constants.STATUS.ACTIVE ? 'active' : 'inactive' }` }>
							<p>{ provider?.status == Constants.STATUS.ACTIVE ? 'Activo' : 'Inactivo' }</p>
						</div>
					</div>
				</div>
				<p className="bold">Denominación comercial</p>
				<p>{ provider?.comercial_designation }</p>
				<hr />
				<p className="item-image bold">
					<img src={ LocationIcon } />
					{ provider?.address }
				</p>
				<p className="item-image red">
					<img src={ TimeRedIcon } />
					{ provider?.schedule_start } { provider?.schedule_start && provider?.schedule_end && '-' } { provider?.schedule_end }
				</p>
				<hr />
				<p className="item-image">
					<img src={ ProfileIcon } />
					<div>
						<p className="bold">Persona de Contacto</p>
						<p>{ provider?.contact_person }</p>
					</div>
				</p>
				<p className="item-image">
					<img src={ PhoneRedIcon } />
					{ provider?.phone }
				</p>
				<p className="item-image">
					<img src={ MailRedIcon } />
					{ provider?.email }
				</p>
				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalViewProvider;