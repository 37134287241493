import React from 'react';
import { Button, Select, Input } from 'components';
import { AreaService } from 'services';

interface Props {
	onClose: () => void;
	item?: any;
	types: any[];
}

class ModalCreateArea extends React.Component<Props> {

	state = {
		form: {
			name: '',
			type_id: ''
		}
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					type_id: this.props.item?.type_id || ''
				}
			});
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async () => {
		if (this.props.item) {
			await AreaService.admin.update({
				...this.state.form,
				area_id: this.props.item?.id
			});
		}
		else {
			await AreaService.admin.create({
				...this.state.form
			});
		}
		this.props.onClose();
	}

	render() {
		return (
			<div id="modal-create-area">
				<div className="content-create row">
					<div className="col-md-12">
						<Input 
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Select
							label="Tipo"
							name="type_id"
							onChange={ this.change }
							value={ this.state.form.type_id }
							options={ this.props.types.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
				</div>
				<div className="row row-buttons">
					<div className="col-6">
						<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
					<div className="col-6">
						<Button type="button" className="btn-save" onClick={ () => this.submit() }>
							Guardar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalCreateArea;