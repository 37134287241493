import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { UserService } from 'services';
import { ContainerRed, Button, Input, Select } from 'components';
import { Globals } from 'utils';

interface MatchParams {
	user_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class CreateUser extends React.Component<Props> {

	state = {
		form: {
			name: '',
			lastname: '',
			sublevel_id: '',
			level_id: '',
			email: '',
			username: '',
			password: '',
			password_confirmation: ''
		},
		levels: [],
		sublevels: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Usuarios',
				onBack: () => {
					this.props.history.push('/admin/users');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Usuarios / ${ this.props.match.params?.user_id ? 'Editar' : 'Crear' } usuario`
			}
		});

		this.loadData();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	loadData = async () => {
		const res: any = await UserService.admin.getData({
			user_id: this.props.match.params?.user_id
		});
		this.setState({
			levels: res.levels,
			sublevels: res.sublevels
		},() => {
			if (res.user) {
				this.setState({
					form: {
						...this.state.form,
						name: res.user?.name || '',
						lastname: res.user?.lastname || '',
						sublevel_id: res.user?.sublevel_id || '',
						level_id: res.user?.level_id || '',
						email: res.user?.email || '',
						username: res.user?.username || '',
					}
				});
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.match.params?.user_id) {
			await UserService.admin.update({
				user_id: this.props.match.params.user_id,
				...this.state.form,
			});
			Globals.showSuccess("Se ha modificado el usuario correctamente");
			this.props.history.push('/admin/users');
		}
		else {
			await UserService.admin.create({
				...this.state.form,
			});
			Globals.showSuccess("Se ha creado el usuario correctamente");
			this.props.history.push('/admin/users');
		}
	}
	
	render() {
		return (
			<div id="create-user">
				<ContainerRed title={ `${ this.props.match.params?.user_id ? 'Editar' : 'Crear' } usuario` }>
					<form className="row" onSubmit={ this.submit }>
						<div className="col-md-6">
							<Input 
								value={ this.state.form.name }
								name="name" 
								label="Nombre"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Input 
								value={ this.state.form.lastname }
								name="lastname" 
								label="Apellido"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Select
								label="Departamento"
								name="level_id"
								onChange={ this.change }
								placeholder="Seleccionar"
								value={ this.state.form.level_id }
								options={ this.state.levels.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-6">
							<Select
								label="Cargo"
								name="sublevel_id"
								onChange={ this.change }
								placeholder="Seleccionar"
								value={ this.state.form.sublevel_id }
								options={ this.state.sublevels.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-6">
							<Input 
								value={ this.state.form.email }
								name="email" 
								label="Correo Electrónico"
								onChange={ this.change } />
						</div>
						<div className="col-12">
							<hr />
						</div>
						<div className="col-md-6">
							<Input 
								value={ this.state.form.username }
								name="username" 
								label="Usuario"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Input 
								type="password"
								value={ this.state.form.password }
								name="password" 
								label={ this.props.match.params?.user_id ? `Cambiar Contraseña (Opcional)` : `Contraseña` }
								onChange={ this.change } />
						</div>
						{
							!this.props.match.params?.user_id && (
								<div className="col-md-6">
									<Input 
										type="password"
										value={ this.state.form.password_confirmation }
										name="password_confirmation" 
										label="Repetir contraseña"
										onChange={ this.change } />
								</div>
							)
						}
						<div className="col-12 text-center">
							<Button type="submit">
								Guardar
							</Button>
						</div>
					</form>
				</ContainerRed>
			</div>
		)
	}
}

export default connect()(CreateUser);