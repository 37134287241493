import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { ContainerRed, Button, Input, Select, SelectSearch, DatePicker } from 'components';
import { OrderService } from 'services';
import { LocationIcon, CalendarWhite, TrashIcon, ProfileIcon, MailIcon, PhoneIcon } from 'assets/icons';
import { Globals, Constants } from 'utils';
import moment from 'moment';

interface MatchParams {
	order_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class CreateOrder extends React.Component<Props> {

	state = {
		form: {
			provider_id: '',
			product_id: '',
			quantity: '',
			brand_id: '',
			presentation_id: '',
			comments: '',
			date: moment().toDate(),
			finish_date: '',
			edit_comments: ''
		},
		providers: [],
		products: [],
		units: [],
		brands: [],
		items: [],
		presentations: [],
		order_number: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Orden de compra',
				onBack: () => {
					this.props.history.push('/admin/orders');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Orden de compra / ${ this.props.match.params.order_id ? 'Editar' : 'Crear' } Orden`
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await OrderService.admin.getProviders({
			order_id: this.props.match.params.order_id
		});
		this.setState({
			providers: res.providers,
			products: res.products,
			units: res.units,
			brands: res.brands,
			order_number: res.order_number
		},() => {
			if (res.order) {
				if (res.order.status != Constants.ORDERS_STATUS.PENDING) {
					this.props.history.push('/admin/orders');
					return false;
				}
				let date: any = {};
				try {
					const _date = res.order?.finish_date && moment(res.order?.finish_date);
					if (_date.isValid())
						date.finish_date = (_date.toDate()) || '';
				}
				catch(e) {}
				try {
					const _date = res.order?.date && moment(res.order?.date);
					if (_date.isValid())
						date.date = (_date.toDate()) || '';
				}
				catch(e) {}
				this.setState({
					form: {
						...this.state.form,
						provider_id: res.order?.provider_id || '',
						comments: res.order?.observations || '',
						...date						
					},
					items: res.order?.details.map((item: any) => {
						return {
							id: item.id,
							product_id: item.product_id,
							quantity: item.quantity,
							brand_id: item.brand_id,
							presentation_id: item.presentation_id,
							presentation_name: item?.presentation?.name || '',
							presentation_unit_id: item?.presentation?.unit_id || ''
						}
					})
				});
			}
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (!this.state.form.product_id) {
			Globals.showError("Debe ingresar el producto");
			return false;
		}

		if (!this.state.form.quantity) {
			Globals.showError("Debe ingresar la cantidad");
			return false;
		}

		if (!this.state.form.brand_id) {
			Globals.showError("Debe ingresar la marca");
			return false;
		}

		const presentation: any = this.state.presentations.find((i: any) => i.id == this.state.form.presentation_id);
		const product: any = this.state.products.find((i: any) => i.id == this.state.form.product_id);

		let items: any = [...this.state.items];
		items.push({
			product_id: this.state.form.product_id,
			quantity: this.state.form.quantity,
			brand_id: this.state.form.brand_id,
			presentation_id: this.state.form.presentation_id,
			presentation_name: presentation?.name,
			presentation_unit_id: presentation?.unit_id,
			unit_id: presentation?.unit_id || product?.unit_id
		});
		this.setState({
			items,
			form: {
				...this.state.form,
				product_id: '',
				quantity: '',
				brand_id: '',
				presentation_id: ''
			},
			presentations: []
		});
	}

	delete = (e: any,index: number) => {
		e.preventDefault();
		Globals.confirm('¿Eliminar el producto de la orden?',() => {
			let items: any = [...this.state.items];
			items.splice(index,1);
			this.setState({
				items
			});
		});
	}

	send = async () => {
		if (this.props.match.params.order_id) {
			const res: any = await OrderService.admin.edit({
				order_id: this.props.match.params.order_id,
				edit_comments: this.state.form.edit_comments,
				provider_id: this.state.form.provider_id,
				comments: this.state.form.comments,
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
				finish_date: this.state.form.finish_date && moment(this.state.form.finish_date).format('YYYY-MM-DD'),
				items: this.state.items
			});
			Globals.showSuccess("Se ha guardado la orden de compra");
			this.props.history.push('/admin/orders');
		}
		else {
			const res: any = await OrderService.admin.create({
				provider_id: this.state.form.provider_id,
				comments: this.state.form.comments,
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
				finish_date: this.state.form.finish_date && moment(this.state.form.finish_date).format('YYYY-MM-DD'),
				items: this.state.items
			});
			Globals.showSuccess("Se ha guardado la orden de compra");
			this.props.history.push('/admin/orders');
		}
	}

	getPresentations = async () => {
		const res: any = await OrderService.admin.getPresentations({
			product_id: this.state.form.product_id
		});
		this.setState({
			presentations: res.presentations
		});
	}
	
	render() {
		const product: any = this.state.products.find((i: any) => i.id == this.state.form.product_id);
		const brand: any = this.state.brands.find((i: any) => i.id == this.state.form.brand_id);
		const provider: any = this.state.providers.find((i: any) => i.id == this.state.form.provider_id);
		const presentation: any = this.state.presentations.find((i: any) => i.id == this.state.form.presentation_id);

		return (
			<div id="create-order">
				<div className="row">
					<div className="col-md-6">
						<ContainerRed title="Selecciona proveedor">	
							<SelectSearch
								label="Proveedor"
								onChange={ (provider: any) => {
									this.setState({
										form: {
											...this.state.form,
											provider_id: provider.value
										}
									});
								} }
								color="gray"
								searchRight
								value={ provider ? {
									value: provider.id,
									label: provider.name
								} : null }
								options={ this.state.providers.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</ContainerRed>

						{
							this.state.form.provider_id && (
								<form onSubmit={ this.submit } className="row">
									<div className="col-12">
										<SelectSearch
											label="Producto"
											onChange={ (product: any) => {
												this.setState({
													form: {
														...this.state.form,
														product_id: product.value
													}
												},() => {
													this.getPresentations();
												});
											} }
											color="gray"
											searchRight
											value={ product ? {
												value: product.id,
												label: product.name
											} : null }
											options={ this.state.products.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) } />
									</div>
									{
										this.state.presentations.length > 0 && (
											<div className="col-12">
												<SelectSearch
													label="Presentación (Opcional)"
													onChange={ (e: any) => {
														this.setState({
															form: {
																...this.state.form,
																presentation_id: e.value
															}
														});
													} }
													color="gray"
													searchRight
													value={ presentation ? {
														value: presentation.id,
														label: presentation.name
													} : null }
													options={ this.state.presentations.map((i: any) => {
														return {
															value: i.id,
															label: i.name
														}
													}) } />
											</div>
										)
									}
									<div className="col-12">
										<Input 
											label="Cantidad"
											type="decimal"
											value={ this.state.form.quantity }
											name="quantity"
											onChange={ this.change } />
									</div>
									<div className="col-12">
										<SelectSearch
											label="Marca"
											onChange={ (brand: any) => {
												this.setState({
													form: {
														...this.state.form,
														brand_id: brand.value
													}
												});
											} }
											color="gray"
											searchRight
											value={ brand ? {
												value: brand.id,
												label: brand.name
											} : null }
											options={ this.state.brands.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) } />
									</div>
									<div className="col-12 text-center">
										<Button type="submit">
											Guardar
										</Button>
									</div>
								</form>
							)
						}
					</div>
					<div className="col-md-6">
						{
							this.state.items.length > 0 && (
								<React.Fragment>
									<ContainerRed titleContainer={
										<div className="row">
											<div className="col-md-6">
												<p className="name">Orden de Compra</p>
												<p className="code">#{ Globals.zeroPad(this.state.order_number) }</p>
											</div>
											<div className="col-md-6 text-right">
												<p className="date">
													<img src={ CalendarWhite } />
													{ moment(this.state.form.date || undefined).format('DD-MM-YYYY') }
												</p>
											</div>
										</div>
									}>	
										<p className="bold">{ provider?.name }</p>
										<p>{ provider?.document_type?.name }{ provider?.document }</p>

										<p className="address">
											<img src={ LocationIcon } />
											{ provider?.address }
										</p>

										{
											provider?.contact_person && (
												<p className="address">
													<img src={ ProfileIcon } />
													<p><span className="bold">Persona de Contacto: </span> { provider?.contact_person }</p>
												</p>
											)
										}

										<div className="row">
											{
												provider?.phone && (
													<div className="col-md-6">
														<p className="address">
															<img src={ PhoneIcon } />
															{ provider?.phone }
														</p>
													</div>
												)
											}
											{
												provider?.phone && (
													<div className="col-md-6">
														<p className="address">
															<img src={ MailIcon } />
															{ provider?.email }
														</p>
													</div>
												)
											}
										</div>

										<table style={{ width: '100%' }}>
											<tr>
												<th style={{ width: '18%', fontSize: '12px' }}>Producto</th>
												<th style={{ width: '18%', fontSize: '12px' }}>Marca</th>
												<th style={{ width: '18%', fontSize: '12px' }}>Presentación</th>
												<th style={{ width: '18%', fontSize: '12px' }}>Cantidad</th>
												<th style={{ width: '18%', fontSize: '12px' }}>Unidad de Medida</th>
												<th style={{ width: '10%', fontSize: '12px' }}></th>
											</tr>
											{
												this.state.items.map((item: any, index: number) => {
													const product: any = this.state.products.find((i: any) => i.id == item.product_id);
													const unit: any = this.state.units.find((i: any) => i.id == (item?.presentation_unit_id || product?.unit_id));
													const brand: any = this.state.brands.find((i: any) => i.id == item.brand_id);

													return (
														<tr>
															<td style={{ width: '18%', fontSize: '12px' }}>{ product?.name }</td>
															<td style={{ width: '18%', fontSize: '12px' }}>{ brand?.name }</td>
															<td style={{ width: '18%', fontSize: '12px' }}>{ item.presentation_name }</td>
															<td style={{ width: '18%', fontSize: '12px' }}>{ item.quantity }</td>
															<td style={{ width: '18%', fontSize: '12px' }}>{ unit?.name }</td>
															<td style={{ width: '10%', fontSize: '12px' }} className="text-center">
																<a className="trash-icon" href="#" onClick={ (e: any) => this.delete(e,index) }>
																	<img src={ TrashIcon } />
																</a>
															</td>
														</tr>
													)
												})
											}
										</table>

										<Input 
											placeholder="Escribir observación (Opcional)"
											value={ this.state.form.comments }
											name="comments"
											className="comments"
											onChange={ this.change } />

										<div className="row row-date">
											<div className="col-md-6">
												<p className="name">Fecha de Entrega</p>
											</div>
											<div className="col-md-6">
												<DatePicker
													calendarIcon={ CalendarWhite }
													placeholder="Fecha"
													showYearDropdown={ true }
													onChange={ (finish_date: string) => {
														this.setState({
															form: {
																...this.state.form,
																finish_date
															}						
														});
													} }
													value={ this.state.form.finish_date }
												/>
											</div>
										</div>
									</ContainerRed>

									{
										this.props.match.params.order_id && (
											<div className="comments-container">
												<Input 
													label="Observación"
													value={ this.state.form.edit_comments }
													name="edit_comments"
													onChange={ this.change } />
											</div>
										)
									}

									<div className="text-center">
										<Button type="button" className="btn-create" onClick={ this.send }>
											Guardar
										</Button>
									</div>
								</React.Fragment>
							)
						}
					</div>
				</div>
				
			</div>
		)
	}
}

export default connect()(CreateOrder);