import React from 'react';
import { Button } from 'components';
import { ReportService } from 'services';
import moment from 'moment';
import { CalendarRed, TimeRedIcon, CheckRedIcon, CircleGreen } from 'assets/icons';
import { ParseDays } from 'utils';

interface Props {
	onClose: () => void;
	response: any;
}

class ModalView extends React.Component<Props> {

	state = {
		checklist: {}
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		if (this.props.response) {
			const res: any = await ReportService.admin.complianceDetails({
				checklist_id: this.props.response?.detail?.checklist_id,
				date: moment(this.props.response?.created_at).format('YYYY-MM-DD'),
				user_id: this.props.response?.user_id
			});

			this.setState({
				checklist: res.checklist
			});
		}		
	}

	render() {
		const checklist: any = this.state.checklist;

		return (
			<div id="modal-view-checklist">
				{ checklist?.level && <p className="title"><strong>Área:</strong> { checklist?.level?.name }</p> }
				{ checklist?.supervisor && <p className="title"><strong>Supervisor:</strong> { checklist?.supervisor?.name + ' ' + checklist?.supervisor?.lastname }</p> }
				{ this.props.response?.user && <p className="title"><strong>Usuario:</strong> { this.props.response?.user?.name + ' ' + this.props.response?.user?.lastname }</p> }
				{ this.props.response?.created_at && <p className="title"><strong>Fecha:</strong> { moment(this.props.response?.created_at).format('YYYY-MM-DD') }</p> }
				{
					checklist && (
						<div className="date">
							<img src={ CalendarRed } />
							<p>{ ParseDays(checklist?.days || {}) }</p>
						</div>
					)
				}
				{
					checklist && (
						<div className="date-container">
							<div style={{ flex: .5 }}>
								<div className="date">
									<img src={ TimeRedIcon } />
									{
										checklist?.since && (
											<p>{ moment(checklist?.since,'HH:mm:ss').format('hh:mm A') }</p>
										)
									}
								</div>
							</div>
							<div style={{ flex: .5 }}>
								<div className="date">
									<img src={ TimeRedIcon } />
									{
										checklist?.until && (
											<p>{ moment(checklist?.until,'HH:mm:ss').format('hh:mm A') }</p>
										)
									}
								</div>
							</div>
						</div>
					)
				}
				<hr />

				{
					checklist?.details?.map((item: any) => {
						return (
							<div className="row">
								<div className="col-md-8">
									<p className="item-checklist-details">{ item.name }</p>
								</div>
								<div className="col-md-4 text-right">
									{
										item.responses.length > 0 ? (
											<img src={ CircleGreen } className="item-checklist-details-icon" />
										) : (
											<img src={ CheckRedIcon } className="item-checklist-details-icon" />
										)
									}
								</div>
							</div>
						)
					})
				}

				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalView;