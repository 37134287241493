import React from 'react';
import { Button } from 'components';
import { ArrowBottom, ArrowUp } from 'assets/icons';
import { Constants } from 'utils';

interface Props {
	onClose: () => void;
	operations: any[];
	add: (item: any, type: number) => void;
}

const ITEMS = {
	CONDITIONED: 1,
	ACTIVITIES: 2,
	RECIPES: 3,
	WEIGHT: 4,
	DECREASE: 5,
	MARINATED: 6
}

class ModalAddWeight extends React.Component<Props> {

	state = {
		selected: null,
	}

	render() {
		return (
			<div id="modal-add-weight">
				<div className="product-container">
					<div className="item-operation" onClick={ () => {
						this.setState({
							selected: this.state.selected == ITEMS.WEIGHT ? null : ITEMS.WEIGHT
						});
					} }>
						<p>Peso</p>
						<img src={ this.state.selected == ITEMS.WEIGHT ? ArrowUp : ArrowBottom } className="arrow" />
					</div>
					{
						this.state.selected == ITEMS.WEIGHT && this.props.operations.filter((i: any) => i.category_id == Constants.OPERATION_CATEGORIES.WEIGHT).map((item: any) => {
							return (
								<p className="item-product" onClick={ () => this.props.add(item,ITEMS.WEIGHT) }>{ item.name }</p>
							)
						})
					}
					<div className="item-operation" onClick={ () => {
						this.setState({
							selected: this.state.selected == ITEMS.DECREASE ? null : ITEMS.DECREASE
						});
					} }>
						<p>Merma</p>
						<img src={ this.state.selected == ITEMS.DECREASE ? ArrowUp : ArrowBottom } className="arrow" />
					</div>
					{
						this.state.selected == ITEMS.DECREASE && this.props.operations.filter((i: any) => i.category_id == Constants.OPERATION_CATEGORIES.DECREASE).map((item: any) => {
							return (
								<p className="item-product" onClick={ () => this.props.add(item,ITEMS.DECREASE) }>{ item.name }</p>
							)
						})
					}
				</div>
				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalAddWeight;