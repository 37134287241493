import PrintJS from 'print-js';
import { Globals, Constants } from 'utils';

const print = (url: string, isPdf: boolean = false) => {
	if (Globals.isImage(url)) {
		const img = new Image();
	    img.src = url;
	    img.onload = function() {
			PrintJS({
				printable: url,
				type: 'image'
			});
	    }		
	}
	else if (Globals.isPdf(url) || isPdf) {
		PrintJS({
			printable: url
		});
	}
	else {
		window.open(url);
	}	
}

export default print;