import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon, EditIcon, TrashIcon, ViewIcon } from 'assets/icons';
import { Pagination, Table, Input, Select, Tooltip, CreateButton, Modal } from 'components';
import moment from 'moment';
import { Constants, Globals } from 'utils';
import { PlanService } from 'services';
import ModalViewPlan from './modal-view-plan';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	department_id: '',
	search: ''
}

class Plans extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Nombre',
			'Departamento',
			'Código',
			'Fecha',
			'Estatus',
			'Acción'
		],
		departments: [],
		visible: false,
		plan: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Plan de producción',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Plan de producción'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await PlanService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.plans.rows,
			last_page: res.plans.count,
			departments: res.departments
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.props.history.push('/admin/production/plans/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.props.history.push('/admin/production/plans/edit/' + item.id);
	}

	view = (e: any, plan: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			plan
		});
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el plan?',async () => {
			await PlanService.admin.delete({
				plan_id: item.id
			});
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			plan: null
		});
	}
	
	render() {
		const { visible } = this.state;
		const plan: any = this.state.plan;

		return (
			<div id="plans">
				<Modal
		          className="modal-view-plan"
		          visible={ visible }
		          title={ plan?.name }
		          onClose={ () => this.onClose() }
		        >
		          <ModalViewPlan
		          	plan={ plan }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<Select
							name="department_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Departamento"
							value={ this.state.form.department_id }
							options={ this.state.departments.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-6"></div>
					<div className="col-md-6 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Plan de producción" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td className="name">{ i?.name }</td>
								<td>{ i?.department?.name }</td>
								<td>{ i?.number }</td>
								<td>{ moment(i?.created_at).format('DD-MM-YYYY HH:mm') }</td>
								<td>
									<div className={ `change-status ${ i.status == Constants.STATUS.ACTIVE ? 'active' : 'inactive' }` }>
										<p>{ i.status == Constants.STATUS.ACTIVE ? 'Culminada' : 'Pendiente' }</p>
									</div>
								</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Plans);