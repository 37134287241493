import React from 'react';
import { OrderService } from 'services';
import { Input } from 'components';
import moment from 'moment';
import { LocationIcon, ProfileIcon, PhoneIcon, MailIcon } from 'assets/icons';

interface Props {
	onClose: () => void;
	order: any;
}

class ModalViewOrder extends React.Component<Props> {

	state = {
		order: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await OrderService.admin.view({
			order_id: this.props.order?.id
		});
		this.setState({
			order: res.order
		});
	}

	render() {
		const order: any = this.state.order;

		return (
			<div id="modal-view-order">
				{
					order?.provider && (
						<div className="container-provider">
							<p className="bold">{ order?.provider?.name }</p>
							<p>{ order?.provider?.document_type?.name }{ order?.provider?.document }</p>

							<p className="address">
								<img src={ LocationIcon } />
								{ order?.provider?.address }
							</p>

							{
								order?.provider?.contact_person && (
									<p className="address">
										<img src={ ProfileIcon } />
										<p><span className="bold">Persona de Contacto: </span> { order?.provider?.contact_person }</p>
									</p>
								)
							}

							<div className="row">
								{
									order?.provider?.phone && (
										<div className="col-md-6">
											<p className="address">
												<img src={ PhoneIcon } />
												{ order?.provider?.phone }
											</p>
										</div>
									)
								}
								{
									order?.provider?.phone && (
										<div className="col-md-6">
											<p className="address">
												<img src={ MailIcon } />
												{ order?.provider?.email }
											</p>
										</div>
									)
								}
							</div>
						</div>
					)
				}

				<table style={{ width: '100%' }}>
					<tr>
						<th style={{ width: '20%' }}>Producto</th>
						<th style={{ width: '20%' }}>Marca</th>
						<th style={{ width: '20%' }}>Presentación</th>
						<th style={{ width: '20%' }}>Cantidad</th>
						<th style={{ width: '20%' }}>Unidad de Medida</th>
					</tr>
					{
						order?.details?.map((item: any, index: number) => {
							return (
								<tr>
									<td style={{ width: '20%' }}>{ item?.product?.name }</td>
									<td style={{ width: '20%' }}>{ item?.brand?.name }</td>
									<td style={{ width: '20%' }}>{ item?.presentation?.name }</td>
									<td style={{ width: '20%' }}>{ item.quantity }</td>
									<td style={{ width: '20%' }}>{ item?.presentation?.unit?.name || item?.product?.unit?.name }</td>
								</tr>
							)
						})
					}
				</table>

				{
					order?.observations && (
						<Input 
							placeholder="Escribir observación (Opcional)"
							value={ order?.observations }
							name="comments"
							className="comments"
							disabled={ true } />
					)
				}

				<div className="row row-date">
					<div className="col-md-6">
						<p className="name">Fecha de Entrega</p>
					</div>
					<div className="col-md-6">
						<p className="date">{ moment(order?.finish_date).format('DD-MM-YYYY') }</p>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalViewOrder;