import React from 'react';
import { Input, Button } from 'components';
import { Globals, Constants, SocketEvents } from 'utils';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { Logo, BgLogin } from 'assets/img';
import { Link } from 'react-router-dom';
import { UserIcon, LockIcon, PasswordActive, PasswordInactive } from 'assets/icons';
import { emit } from 'jetemit';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Login extends React.Component<Props> {

	state = {
		form: {
			username: '',
			password: ''
		},
		activePassword: false
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Iniciar Sesión',
				onBack: null
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await AuthService.login(this.state.form);
		await this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
		this.props.history.replace('/welcome');
	}

	disabled = () => {
		return this.state.form.username == '' || this.state.form.password == '';
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		return (
			<div id="login">
				<div className="row container-form">
					<div className="col-lg-7 container-bg" style={{ backgroundImage: `url(${ BgLogin })` }}></div>
					<form className="col-lg-5" onSubmit={ this.submit }>
						<div className="container-logo">
							<img src={ Logo } />
						</div>
						<div className="container-input">
							<label htmlFor="email">Usuario</label>
							<div className="input-group">
								<Input 
									name="username" 
									onChange={ this.change } />
							</div>
							<label htmlFor="password">Contraseña</label>
							<div className="input-group">
								<Input
									type={ this.state.activePassword ? 'text' : 'password' }
									name="password"
									onChange={ this.change } />
								<div className="input-group-prepend password-view" onClick={ () => {
									this.setState({
										activePassword: !this.state.activePassword
									});
								} }>
									<img src={ this.state.activePassword ? PasswordActive : PasswordInactive } />
								</div>
							</div>
							<div className="text-center">
								<Button type="submit" disabled={ this.disabled() }>
									Iniciar Sesión
								</Button>
							</div>
						</div>		

						<div className="recover">
							<Link to="/recover">
								¿Recuperar contraseña?
							</Link>
						</div>				
					</form>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Login);