import React from 'react';
import { Globals } from '../utils';
import Button from './button';
import Icon from './icon';
import { PaperclipIcon } from 'assets/icons';

class PaperClip extends React.Component<any> {

	private file: any;

	dataURItoBlob = (dataURI: any) => {
	    var byteString;
	    if (dataURI.split(',')[0].indexOf('base64') >= 0)
	        byteString = atob(dataURI.split(',')[1]);
	    else
	        byteString = unescape(dataURI.split(',')[1]);
	    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	    var ia = new Uint8Array(byteString.length);
	    for (var i = 0; i < byteString.length; i++) {
	        ia[i] = byteString.charCodeAt(i);
	    }
	    return new Blob([ia], {type:mimeString});
	}

	change = (e: any) => {
		var files = [...e.target.files] || [...e.dataTransfer.files];
		if (!files[0]) {
			return;
		}
		this.file.value = '';
		var format = files[0].name.split('.');
		var permitidos = this.props.formats || ['jpg','png','gif','jpeg'];
		if (permitidos.indexOf(format[format.length - 1].toLowerCase()) == -1) {
			Globals.showError('El formato del archivo no es válido');
			return false;
		}
		
		let tipo = format[format.length - 1].toLowerCase();
    	switch(tipo) {
			case 'jpg':
				tipo = 'image/jpeg';
				break;

			case 'png':
				tipo = 'image/png';
				break;

			case 'gif':
				tipo = 'image/gif';
				break;

			case 'jpeg':
				tipo = 'image/jpeg';
				break;

			case 'pdf':
				tipo = 'application/pdf';
				break;

			case 'doc':
				tipo = 'application/msword';
				break;

			case 'docx':
				tipo = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				break;

			case 'xls':
				tipo = 'application/vnd.ms-excel';
				break;

			case 'xlsx':
				tipo = 'application/vnd.ms-excel';
				break;

			default: 
				tipo = 'image/jpeg';
				break;
		}

		var reader = new FileReader();
		reader.readAsDataURL(files[0]);
    	reader.onload = file => {
    		if (tipo == 'application/pdf' || tipo == 'application/vnd.ms-excel' || tipo == 'application/msword' || tipo == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    			this.props.onChange({
			    	target: {
			    		name: this.props.name,
			    		value: this.dataURItoBlob(reader.result),
			    		dataURL: reader.result
			    	}			    	
			    });
    		}
    		else {
	    		var tempImg: any = new Image();
	            tempImg.src = reader.result;
				
				let self: any = this;

	        	tempImg.onload = function() {
				    var MAX_WIDTH = 1000;
				    var MAX_HEIGHT = 1000;
				    var tempW = tempImg.width;
				    var tempH = tempImg.height;

				    if (tempW > tempH) {
				        if (tempW > MAX_WIDTH) {
					        tempH *= MAX_WIDTH / tempW;
					        tempW = MAX_WIDTH;
				        }
				    } else {
				        if (tempH > MAX_HEIGHT) {
					        tempW *= MAX_HEIGHT / tempH;
					        tempH = MAX_HEIGHT;
				        }
				    }
				    var resizedCanvas = document.createElement('canvas');
				    resizedCanvas.width = tempW;
				    resizedCanvas.height = tempH;
				    var ctx: any = resizedCanvas.getContext("2d");
				    ctx.drawImage(this, 0, 0, tempW, tempH);
				    var dataURL = resizedCanvas.toDataURL(tipo);
				    self.props.onChange({
				    	target: {
				    		name: self.props.name,
				    		value: self.dataURItoBlob(dataURL),
				    		dataURL,
				    		original_name: files[0].name
				    	}			    	
				    });
				}	
    		}
        }
	}
		
	render() {
		return (
			<div className={ 'container-paper-clip ' + (this.props.className || '') }>
				<input className="input-file-paperclip" ref={ ref => this.file = ref } type="file" name={ this.props.name } onChange={ this.change } style={this.props.inputStyle} />
				<Button disabled={ this.props.disabled } color={ this.props.value ? 'green' : 'orange' } type="button" onClick={ () => this.file.click() }>
					<div className="container-icon-paperclip">
						{ !this.props.value && <img src={ this.props.icon || PaperclipIcon } /> }
						{ this.props.value && (
							<React.Fragment>
								{
									this.props.successImg ? (
										<img className="paper-clip-success-img" src={ this.props.successImg } />
									) : (
										<i className="fa fa-check"></i>
									)
								}
							</React.Fragment>
						) }
					</div>
					{
						this.props.children ? (
							<React.Fragment>
								{ this.props.children }
							</React.Fragment>
						) : (
							<React.Fragment>
								{ this.props.value ? (this.props.successText || 'Archivo Cargado') : (this.props.pendingText || 'Subir Archivo') }
							</React.Fragment>
						)
					}
				</Button>
			</div>	
		)
	}
}

export default PaperClip;

const ImagesAndDocuments = ['jpg','png','gif','jpeg','pdf','doc','xls','xlsx','docx'];

export {
	ImagesAndDocuments
}