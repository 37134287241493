import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon, ViewIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select, Modal, Tooltip } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import ModalView from '../checklist/modal-view';
import { ParseDays } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	search_name: '',
	level_id: ''
}

class Checklist extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Área',
			'Nombre',
			'Tarea asignada',
			'Responsable',
			'Frecuencia de Ejecución',
			'Acciones'
		],
		form: INITIAL_STATE,
		levels: [],
		visible_view: false,
		checklist: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Reporte de Checklist'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.checklist({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.checklist.rows,
			last_page: res.checklist.count,
			levels: res.levels
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			checklist: null
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			checklist: item
		});
	}
	
	render() {
		const { visible_view } = this.state;
		const checklist: any = this.state.checklist;

		return (
			<div id="reports-checklist">
				<Modal
		          className="modal-view-checklist"
		          visible={ visible_view }
		          title={ checklist?.name }
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalView
		          	checklist={ checklist }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search_name }
							name="search_name"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por tarea"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<Select
							name="level_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Área"
							value={ this.state.form.level_id }
							options={ this.state.levels.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<Table title="Reporte de Checklist" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.level?.name }</td>
								<td>
									{
										i.users.length == 0 && (
											<span>Sin asignar</span>
										)
									}
									{
										i.users.map((item: any) => {
											return (
												<p className="item-red">{ item?.user?.name + ' ' + item?.user?.lastname }</p>
											)
										})
									}
								</td>
								<td>{ i?.name }</td>
								<td>{ i.supervisor ? (i?.supervisor?.name + ' ' + i?.supervisor?.lastname) : '' }</td>
								<td>{ ParseDays(i?.days || {}) }</td>
								<td>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Checklist);