const getDay = (_day: string, min = false) => {
	let day = '';
	switch(_day) {
		case 'monday':
			if (!min)
				day = 'Lunes';
			else
				day = 'Lu';
		break;

		case 'tuesday':
			if (!min)
				day = 'Martes';
			else
				day = 'Ma';
		break;

		case 'wednesday':
			if (!min)
				day = 'Miércoles';
			else
				day = 'Mi';
		break;

		case 'thursday':
			if (!min)
				day = 'Jueves';
			else
				day = 'Ju';
		break;

		case 'friday':
			if (!min)
				day = 'Viernes';
			else
				day = 'Vi';
		break;

		case 'saturday':
			if (!min)
				day = 'Sabado';
			else
				day = 'Sa';
		break;

		case 'sunday':
			if (!min)
				day = 'Domingo';
			else
				day = 'Do';
		break;
	}
	return day;
}

export default getDay;

const ParseDays = (days: any) => {
	let text = '';
	if (days?.monday) {
		if (text != '') {
			text += ', ';
		}
		text += 'Lu';
	}
	if (days?.tuesday) {
		if (text != '') {
			text += ', ';
		}
		text += 'Ma';
	}
	if (days?.wednesday) {
		if (text != '') {
			text += ', ';
		}
		text += 'Mi';
	}
	if (days?.thursday) {
		if (text != '') {
			text += ', ';
		}
		text += 'Ju';
	}
	if (days?.friday) {
		if (text != '') {
			text += ', ';
		}
		text += 'Vi';
	}
	if (days?.saturday) {
		if (text != '') {
			text += ', ';
		}
		text += 'Sa';
	}
	if (days?.sunday) {
		if (text != '') {
			text += ', ';
		}
		text += 'Do';
	}
	return text;
}

export {
	ParseDays
}