import React from 'react';
import { Button } from 'components';

interface Props {
	onClose: () => void;
	storage: any;
}

class ModalViewStorage extends React.Component<Props> {

	render() {
		return (
			<div id="modal-view-storage">
				<h3>Unidades de Almacenamiento</h3>
				{
					this.props.storage?.containers.map((i: any) => {
						return (
							<div className="row row-items">
								<div className="col-12">
									<p className="name">{ i.name }</p>
								</div>
							</div>
						)
					})
				}
				{
					this.props.storage?.containers?.length == 0 && (
						<p className="name">No se han registrado Unidades de Almacenamiento</p>
					)
				}
				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalViewStorage;