import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from 'assets/icons';
import moment from 'moment';

registerLocale('es', es)
setDefaultLocale('es')

const CustomInput = (props: any) => {
	return (
		<div className={ `container-datepicker ${ props.color ? props.color : '' }` } 
			onClick={ props.onClick }>
			<img src={ props.calendarIcon || Calendar } />
			<p className={ !props.value && props._placeholder ? 'placeholder' : '' }>{ props.value || props._placeholder }</p>		
		</div>
	)	
}

const range = (max?: string, min?: string) => {
	var currentYear = (max && getYear(max)) || parseInt(moment(max).add(10,'years').format('YYYY')),
		years = [],
    	startYear = (min && getYear(min)) || parseInt(moment(currentYear,'YYYY').subtract(100,'years').format('YYYY'));
    while (startYear <= currentYear) {
        years.push(startYear++);
    }   
    return years.reverse();
}

const getYear = (date: any) => {
	return parseInt(moment(date).format('YYYY'));
}

const getMonth = (date: any) => {
	return parseInt(moment(date).format('M')) - 1;
}

const months = [
	"Enero",
	"Febrero",
	"Marzo",
	"Abril",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre"
];

const _DatePicker = (props: any) => {
	const years = range(props.maxDate,props.minDate);

	let customHeader = {}

	if (props.showYearDropdown) {
		customHeader = {
			renderCustomHeader: (props: any) => (
		        <div className="container-custom-header-datepicker">
		          <button type="button" onClick={ props.decreaseMonth } disabled={ props.prevMonthButtonDisabled }>
		            { "<" }
		          </button>
		          <select
		            value={ getYear(props.date) }
		            onChange={ ({ target: { value } }) => props.changeYear(parseInt(value)) }
		          >
		            { years.map(option => (
		              <option key={ option } value={ option }>
		                {option}
		              </option>
		            )) }
		          </select>

		          <select
		            value={ months[getMonth(props.date)] }
		            onChange={ ({ target: { value } }) =>
		              props.changeMonth(months.indexOf(value))
		            }
		          >
		            { months.map(option => (
		              <option key={ option } value={ option }>
		                { option }
		              </option>
		            )) }
		          </select>

		          <button type="button" onClick={ props.increaseMonth } disabled={ props.nextMonthButtonDisabled }>
		            { ">" }
		          </button>
		        </div>
		    )
		}
	}

	return (
		<div className="form-group">
			{ props.label && <label className="label-datepicker">{ props.label }</label> }
			<DatePicker
				{ ...customHeader }
				maxDate={ props.maxDate }
				minDate={ props.minDate }
			    selected={ props.value }
			    onChange={ props.onChange }
			    disabled={ props.disabled }
			    showTimeSelect={ props.showTimeSelect }
			    showTimeSelectOnly={ props.showTimeSelectOnly }
			    timeFormat={ props.timeFormat }
			    timeIntervals={ props.timeIntervals }
			    timeCaption={ props.timeCaption }
			    popperPlacement={ props.popperPlacement }
			    showMonthYearPicker={ props.showMonthYearPicker }
			    dateFormat={ props.dateFormat || 'dd/MM/yyyy' }
			    customInput={ <CustomInput { ...props } _placeholder={ props.placeholder } /> }
			/>
		</div>
	)
}

export default _DatePicker;