import React from 'react';
import { Constants } from 'utils';
import { Tooltip } from 'components';
import { EditIconRed } from 'assets/icons';

interface Props {
	onClose: () => void;
	category: any;
	onEdit: () => void;
}

class ModalViewCategory extends React.Component<Props> {

	render() {
		return (
			<div id="modal-view-category">
				<div className="row">
					<div className="col-md-6">
						<img className="image-category" src={ Constants.BASE_STORAGE + this.props.category?.image } />
					</div>
					<div className="col-md-6">
						<h3>{ this.props.category?.name }</h3>
						<p className="code"># { this.props.category?.code }</p>
						<p className="bold">Descripción</p>
						<p className="description">{ this.props.category?.description }</p>
						<div className="text-right">
							<Tooltip title="Editar">
								<a className="link-icon" href="#" onClick={ (e: any) => {
									e.preventDefault();
									this.props.onEdit();
								} }>
									<img src={ EditIconRed } />
								</a>
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalViewCategory;