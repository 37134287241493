import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { DishesService } from 'services';
import { ContainerRed, Button, Input, Select, DatePicker, Tooltip, SelectSearch, Pagination, Modal } from 'components';
import { Globals, Constants } from 'utils';
import moment from 'moment';
import { TrashIcon, ArrowBottom, ArrowUp, CloseIcon, ArrowUpBlack, ArrowBottomBlack } from 'assets/icons';
import ModalAddWeight from './modal-add-weight';

interface MatchParams {
	technique_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

const OPERATION = {
	YES: 1,
	NO: 0
}

const ITEMS = {
	CONDITIONED: 1,
	ACTIVITIES: 2,
	RECIPES: 3,
	WEIGHT: 4,
	DECREASE: 5,
	MARINATED: 6
}

const MARINATED = {
	YES: 1,
	NO: 0
}

class CreateDishes extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: {
			search: '',
			name: '',
			department_id: ''
		},
		items: [],
		departments: [],
		operations: [],
		recipes: [],
		conditioned: [],
		selected: null,
		item_selected: null,
		visible: false,
		extras: [],
		index_selected: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Plato o técnica',
				onBack: () => {
					this.props.history.push('/admin/production/dishes');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Plato o técnica / ${ this.props.match.params.technique_id ? 'Editar' : 'Crear' }`
			}
		});

		this.load();
		this.loadData();
	}

	loadData = async (withoutLoading: boolean = false) => {
		const res: any = await DishesService.admin.view({
			withoutLoading: true,
			technique_id: this.props.match.params.technique_id
		});
		this.setState({
			withoutLoading: true,
			departments: res.departments
		},() => {
			if (res.technique) {
				let extras: any = [];

				res.technique?.details.forEach((item: any) => {
					item?.subdetails?.forEach((i: any) => {
						if (i.conditioned_id == null) {
							i.subdetail_id = i.id;
							extras.push(i);
						}
					});
				});

				this.setState({
					form: {
						...this.state.form,
						department_id: res.technique?.department_id || '',
						name: res.technique?.name || '',
					},
					extras,
					items: res.technique?.details.map((item: any) => {
						if (item.recipe_id) {
							if (item?.recipe?.marinated == MARINATED.YES)
								item.type = ITEMS.MARINATED;
							else
								item.type = ITEMS.RECIPES;
						}

						if (item.operation_id) {
							switch(item.operation?.category_id) {
								case Constants.OPERATION_CATEGORIES.ACTIVITY:
									item.type = ITEMS.ACTIVITIES;
								break;

								case Constants.OPERATION_CATEGORIES.WEIGHT:
									item.type = ITEMS.WEIGHT;
								break;

								case Constants.OPERATION_CATEGORIES.DECREASE:
									item.type = ITEMS.DECREASE;
								break;
							}
						}

						if (item.conditioned_id) {
							item.type = ITEMS.CONDITIONED;
						}

						item.conditioned?.details?.map((i: any) => {
							const subdetail = item.subdetails?.find((_i: any) => _i.conditioned_id == i.conditioned_id && _i.operation_id == i.operation_id && _i.detail_id == item.id);
							i.percentage = subdetail?.percentage || null;
							i.time = subdetail?.time || null;
							i.temperature = subdetail?.temperature || null;
							i.quantity = subdetail?.quantity || null;
							i.subdetail_id = subdetail?.id;
						});

						return item;
					}) || []
				});
			}
			else if (this.props.match.params.technique_id) {
				this.props.history.push('/admin/production/dishes');
			}
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await DishesService.admin.getData({
			withoutLoading,
			...this.state.form,
		});
		this.setState({
			conditioned: res.conditioned,
			operations: res.operations,
			recipes: res.recipes
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.match.params.technique_id) {
			await DishesService.admin.update({
				technique_id: this.props.match.params.technique_id,
				...this.state.form,
				items: this.state.items.filter((i: any) => i != null).map((i: any, index: number) => {
					i.extras = this.state.extras.filter((_i: any) => _i.index_selected == index || (_i.detail_id != null && _i.detail_id == i.id));
					return i;
				}),
			});
			Globals.showSuccess("Se ha modificado el plato o técnica correctamente");
			this.props.history.push('/admin/production/dishes');
		}
		else {
			await DishesService.admin.create({
				...this.state.form,
				items: this.state.items.filter((i: any) => i != null).map((i: any, index: number) => {
					i.extras = this.state.extras.filter((_i: any) => _i.index_selected == index || (_i.detail_id != null && _i.detail_id == i.id));
					return i;
				}),
			});
			Globals.showSuccess("Se ha registrado el plato o técnica correctamente");
			this.props.history.push('/admin/production/dishes');
		}
	}

	reset = () => {
		this.setState({
			form: {
				...this.state.form,
				search: ''
			},
			page: 1
		},() => this.load(true));
	}

	add = (item: any, type: number) => {
		let _item: any = {
			type
		};

		switch(type) {
			case ITEMS.CONDITIONED:
				_item.conditioned_id = item.id;
				_item.conditioned = item;
			break;

			case ITEMS.ACTIVITIES:
				_item.operation_id = item.id;
				_item.operation = item;
			break;

			case ITEMS.RECIPES:
				_item.recipe_id = item.id;
				_item.recipe = item;
			break;

			case ITEMS.WEIGHT:
				_item.operation_id = item.id;
				_item.operation = item;
			break;

			case ITEMS.DECREASE:
				_item.operation_id = item.id;
				_item.operation = item;
			break;

			case ITEMS.MARINATED:
				_item.recipe_id = item.id;
				_item.recipe = item;
			break;
		}

		let items: any = [...this.state.items];
		items.push(_item);
		this.setState({
			items
		});
	}

	remove = (index: number) => {
		let items: any = [...this.state.items];
		delete items[index];
		this.setState({
			items
		});
	}

	onClose = () => {
		this.setState({
			visible: false
		});
	}
	
	render() {
		const { visible } = this.state;
		const extras: any = this.state.extras;

		return (
			<div id="create-dishes">
				<Modal
		          className="modal-add-weight"
		          visible={ visible }
		          title="Agregar peso o merma"
		          onClose={ () => this.onClose() }
		        >
		          <ModalAddWeight
		          	add={ (operation: any, type: number) => {
		          		this.setState({
		          			visible: false
		          		},() => {
		          			let extras: any = [...this.state.extras];
		          			extras.push({
		          				operation,
		          				index_selected: this.state.index_selected,
		          				percentage: '',
		          				time: '',
		          				temperature: '',
		          				quantity: ''
		          			});
		          			this.setState({
		          				extras,
		          				index_selected: null
		          			});
		          		});
		          	} }
		          	operations={ this.state.operations }
		            onClose={ () => this.onClose() } />
		      	</Modal>
				<div className="row row-container">
					<div className="col-md-8 col-white">
						<div style={{ padding: '20px' }}>
							<h3>Plato o técnica</h3>
							<div className="row">
								<div className="col-md-6">
									<Input
										className="input-table"
										value={ this.state.form.name }
										name="name"
										label="Nombre del plato o técnica"
										onChange={ (e: any) => this.change(e) } />
								</div>
								<div className="col-md-6">
									<Select
										name="department_id"
										onChange={ (e: any) => this.change(e) }
										label="Departamento"
										value={ this.state.form.department_id }
										options={ this.state.departments.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
								</div>
							</div>
						</div>
						{
							this.state.items.length > 0 && (
								<React.Fragment>
									<table style={{ width: '100%' }}>
										<thead>
											<tr>
												<th style={{ width: '30%' }}>Operaciones</th>
												<th style={{ width: '15%' }}>Porcentaje</th>
												<th style={{ width: '15%' }}>Tiempo</th>
												<th style={{ width: '15%' }}>Kilogramos</th>
												<th style={{ width: '15%' }}>Temperatura</th>
												<th style={{ width: '10%' }}></th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.items.map((item: any,index: number) => {
													if (item) {
														return (
															<React.Fragment>
																<tr className="tr-item">
																	<td style={{ width: '30%' }}>
																		<div style={{ display: 'flex' }}>
																			<img src={ CloseIcon } className="close-icon" onClick={ () => this.remove(index) } />
																			<p>{ item?.operation?.name || item?.conditioned?.name || item?.recipe?.name }</p>
																		</div>
																	</td>
																	<td style={{ width: '15%' }}>
																		{
																			(item.type == ITEMS.RECIPES || item.operation?.percentage == OPERATION.YES) && (
																				<Input
																					className="input-item"
																					type="decimal"
																					value={ item.percentage }
																					onChange={ (e: any) => {
																						let items: any = [...this.state.items];
																						items[index].percentage = e.target.value;
																						this.setState({
																							items
																						});
																					} }
																				/>
																			)
																		}
																		{
																			item.type == ITEMS.MARINATED && (
																				<p className="item-text">{ item?.recipe?.percentage }</p>
																			)
																		}
																	</td>
																	<td style={{ width: '15%' }}>
																		{
																			item.operation?.time == OPERATION.YES && (
																				<Input
																					className="input-item"
																					type="decimal"
																					value={ item.time }
																					onChange={ (e: any) => {
																						let items: any = [...this.state.items];
																						items[index].time = e.target.value;
																						this.setState({
																							items
																						});
																					} }
																				/>
																			)
																		}
																		{
																			item.type == ITEMS.MARINATED && (
																				<p className="item-text">{ item?.recipe?.time }</p>
																			)
																		}
																	</td>
																	<td style={{ width: '15%' }}>
																		{
																			item.operation?.quantity == OPERATION.YES && (
																				<Input
																					className="input-item"
																					type="decimal"
																					value={ item.quantity }
																					onChange={ (e: any) => {
																						let items: any = [...this.state.items];
																						items[index].quantity = e.target.value;
																						this.setState({
																							items
																						});
																					} }
																				/>
																			)
																		}
																		{
																			item.type == ITEMS.MARINATED && (
																				<p className="item-text">{ item?.recipe?.quantity }</p>
																			)
																		}
																	</td>
																	<td style={{ width: '15%' }}>
																		{
																			item.operation?.temperature == OPERATION.YES && (
																				<Input
																					className="input-item"
																					type="decimal"
																					value={ item.temperature }
																					onChange={ (e: any) => {
																						let items: any = [...this.state.items];
																						items[index].temperature = e.target.value;
																						this.setState({
																							items
																						});
																					} }
																				/>
																			)
																		}
																		{
																			item.type == ITEMS.MARINATED && (
																				<p className="item-text">{ item?.recipe?.temperature }</p>
																			)
																		}
																	</td>
																	<td style={{ width: '10%' }}>
																		<img src={ this.state.item_selected == index ? ArrowUpBlack : ArrowBottomBlack } className="arrow-item" onClick={ () => {
																			this.setState({
																				item_selected: this.state.item_selected == index ? null : index
																			});
																		} } />
																	</td>
																</tr>
																{
																	this.state.item_selected == index && (
																		<React.Fragment>
																			{
																				item?.recipe?.details?.map((item: any) => (
																					<tr className="tr-item">
																						<td style={{ width: '30%' }}>
																							<p className="container-subitem">{ item?.product?.name }</p>
																						</td>
																						<td style={{ width: '15%' }}>
																							<p className="subitem-text">{ item?.percentage }</p>
																						</td>
																					</tr>
																				))
																			}
																			{
																				item?.conditioned?.details?.map((i: any, _index: number) => (
																					<tr className="tr-item">
																						<td style={{ width: '30%' }}>
																							<p className="container-subitem">{ i?.operation?.name }</p>
																						</td>
																						<td style={{ width: '15%' }}>
																							{
																								i?.operation?.percentage == OPERATION.YES && (
																									<Input
																										className="input-item"
																										type="decimal"
																										value={ item?.conditioned?.details[_index].percentage }
																										onChange={ (e: any) => {
																											let items: any = [...this.state.items];
																											items[index].conditioned.details[_index].percentage = e.target.value;
																											this.setState({
																												items
																											});
																										} }
																									/>
																								)
																							}
																						</td>
																						<td style={{ width: '15%' }}>
																							{
																								i?.operation?.time == OPERATION.YES && (
																									<Input
																										className="input-item"
																										type="decimal"
																										value={ item?.conditioned?.details[_index].time }
																										onChange={ (e: any) => {
																											let items: any = [...this.state.items];
																											items[index].conditioned.details[_index].time = e.target.value;
																											this.setState({
																												items
																											});
																										} }
																									/>
																								)
																							}
																						</td>
																						<td style={{ width: '15%' }}>
																							{
																								i?.operation?.quantity == OPERATION.YES && (
																									<Input
																										className="input-item"
																										type="decimal"
																										value={ item?.conditioned?.details[_index].quantity }
																										onChange={ (e: any) => {
																											let items: any = [...this.state.items];
																											items[index].conditioned.details[_index].quantity = e.target.value;
																											this.setState({
																												items
																											});
																										} }
																									/>
																								)
																							}
																						</td>
																						<td style={{ width: '15%' }}>
																							{
																								i?.operation?.temperature == OPERATION.YES && (
																									<Input
																										className="input-item"
																										type="decimal"
																										value={ item?.conditioned?.details[_index].temperature }
																										onChange={ (e: any) => {
																											let items: any = [...this.state.items];
																											items[index].conditioned.details[_index].temperature = e.target.value;
																											this.setState({
																												items
																											});
																										} }
																									/>
																								)
																							}
																						</td>
																					</tr>
																				))
																			}
																			{
																				extras.map((i: any, _index: number) => {
																					if ( i.index_selected == index || (i.detail_id != null && i.detail_id == item.id)) {
																						return (
																							<tr className="tr-item">
																								<td style={{ width: '30%' }}>
																									<div className="container-subitem" style={{ display: 'flex' }}>
																										<img src={ CloseIcon } className="close-icon" onClick={ () => {
																											let extras: any = [...this.state.extras];
																											extras.splice(_index);
																											this.setState({
																												extras
																											});
																										} } />
																										<p>{ i?.operation?.name }</p>
																									</div>
																								</td>
																								<td style={{ width: '15%' }}>
																									{
																										i?.operation?.percentage == OPERATION.YES && (
																											<Input
																												className="input-item"
																												type="decimal"
																												value={ extras[_index].percentage }
																												onChange={ (e: any) => {
																													let extras: any = [...this.state.extras];
																													extras[_index].percentage = e.target.value;
																													this.setState({
																														extras
																													});
																												} }
																											/>
																										)
																									}
																								</td>
																								<td style={{ width: '15%' }}>
																									{
																										i?.operation?.time == OPERATION.YES && (
																											<Input
																												className="input-item"
																												type="decimal"
																												value={ extras[_index].time }
																												onChange={ (e: any) => {
																													let extras: any = [...this.state.extras];
																													extras[_index].time = e.target.value;
																													this.setState({
																														extras
																													});
																												} }
																											/>
																										)
																									}
																								</td>
																								<td style={{ width: '15%' }}>
																									{
																										i?.operation?.quantity == OPERATION.YES && (
																											<Input
																												className="input-item"
																												type="decimal"
																												value={ extras[_index].quantity }
																												onChange={ (e: any) => {
																													let extras: any = [...this.state.extras];
																													extras[_index].quantity = e.target.value;
																													this.setState({
																														extras
																													});
																												} }
																											/>
																										)
																									}
																								</td>
																								<td style={{ width: '15%' }}>
																									{
																										i?.operation?.temperature == OPERATION.YES && (
																											<Input
																												className="input-item"
																												type="decimal"
																												value={ extras[_index].temperature }
																												onChange={ (e: any) => {
																													let extras: any = [...this.state.extras];
																													extras[_index].temperature = e.target.value;
																													this.setState({
																														extras
																													});
																												} }
																											/>
																										)
																									}
																								</td>
																							</tr>
																						)
																					}
																				})
																			}
																			<tr className="tr-item">
																				<td colSpan={ 6 }>
																					<p className="add-weight" onClick={ () => {
																						this.setState({
																							visible: true,
																							index_selected: index
																						});
																					} }>Agregar peso o merma</p>
																				</td>
																			</tr>
																		</React.Fragment>
																	)
																}
															</React.Fragment>
														)
													}
												})
											}
										</tbody>
									</table>
									{
										this.state.items.filter((i: any) => i != null).length > 0 && (
											<div className="text-center">
												<Button type="submit" onClick={ this.submit }>
													Guardar
												</Button>
											</div>
										)
									}
								</React.Fragment>
							)
						}
						{
							this.state.items.filter((i: any) => i != null).length == 0 && (
								<p className="no-items">No se han agregado registros</p>
							)
						}
					</div>
					<div className="col-md-4 col-black">
						<div style={{ padding: '20px' }}>
							<Input
								searchRight
								value={ this.state.form.search }
								name="search"
								placeholder="Búsqueda por nombre"
								className="input-table"
								onSubmit={ () => this.load() }
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
							{
								this.state.form.search != '' && (
									<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
								)
							}
						</div>
						<div className="product-container">
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.CONDITIONED ? null : ITEMS.CONDITIONED
								});
							} }>
								<p>Acondicionado</p>
								<img src={ this.state.selected == ITEMS.CONDITIONED ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.CONDITIONED && (
									<React.Fragment>
										<div className="container-message-white">
											<p className="message-white">Las operaciones que lo conformaran seran designadas por el administrador en función del tipo de materia prima (fuente de la proteína) a procesar.</p>
											<p className="message-white">El resultado de ella será la obtención del peso limpio, requerido para iniciar el proceso de producción. (Opcional)</p>
										</div>
										{
											this.state.conditioned.map((item: any) => {
												return (
													<p className="item-product" onClick={ () => this.add(item,ITEMS.CONDITIONED) }>{ item.name }</p>
												)
											})
										}
									</React.Fragment>
								)
							}
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.ACTIVITIES ? null : ITEMS.ACTIVITIES
								});
							} }>
								<p>Actividades</p>
								<img src={ this.state.selected == ITEMS.ACTIVITIES ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.ACTIVITIES && this.state.operations.filter((i: any) => i.category_id == Constants.OPERATION_CATEGORIES.ACTIVITY).map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item,ITEMS.ACTIVITIES) }>{ item.name }</p>
									)
								})
							}
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.RECIPES ? null : ITEMS.RECIPES
								});
							} }>
								<p>Recetas</p>
								<img src={ this.state.selected == ITEMS.RECIPES ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.RECIPES && this.state.recipes.filter((i: any) => i.marinated == MARINATED.NO).map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item,ITEMS.RECIPES) }>{ item.name }</p>
									)
								})
							}
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.WEIGHT ? null : ITEMS.WEIGHT
								});
							} }>
								<p>Peso</p>
								<img src={ this.state.selected == ITEMS.WEIGHT ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.WEIGHT && this.state.operations.filter((i: any) => i.category_id == Constants.OPERATION_CATEGORIES.WEIGHT).map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item,ITEMS.WEIGHT) }>{ item.name }</p>
									)
								})
							}
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.DECREASE ? null : ITEMS.DECREASE
								});
							} }>
								<p>Merma</p>
								<img src={ this.state.selected == ITEMS.DECREASE ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.DECREASE && this.state.operations.filter((i: any) => i.category_id == Constants.OPERATION_CATEGORIES.DECREASE).map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item,ITEMS.DECREASE) }>{ item.name }</p>
									)
								})
							}
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.MARINATED ? null : ITEMS.MARINATED
								});
							} }>
								<p>Marinado</p>
								<img src={ this.state.selected == ITEMS.MARINATED ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.MARINATED && this.state.recipes.filter((i: any) => i.marinated == MARINATED.YES).map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item,ITEMS.MARINATED) }>{ item.name }</p>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(CreateDishes);