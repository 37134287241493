import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CreateButton, Input, Select, DatePicker, Table, Pagination, Tooltip, Modal } from 'components';
import moment from 'moment';
import { ViewIcon, TrashIcon, DownloadTableIcon, CommentsIcon, CommentsRedIcon, EditIcon } from 'assets/icons';
import { OrderService } from 'services';
import { Globals, Print, Constants } from 'utils';
import ModalViewOrder from './modal-view';
import ModalViewObservation from './modal-observation';
import ModalDelete from './modal-delete';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	until: '',
	since: ''
}

class Orders extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Fecha',
			'Nº de Orden',
			'Proveedor',
			'Estatus',
			'Acción'
		],
		order: null,
		visible_view: false,
		visible_observation: false,
		visible_delete: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Ordenes de Compra',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Orden de Compra'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await OrderService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.orders.rows,
			last_page: res.orders.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.props.history.push('/admin/orders/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			order: item
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.props.history.push('/admin/orders/edit/' + item.id);
	}

	observations = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_observation: true,
			order: item
		});
	}

	download = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await OrderService.admin.export({
			order_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_delete: true,
			order: item
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			order: null
		});
	}

	onCloseObservation = () => {
		this.setState({
			visible_observation: false,
			order: null
		});
	}

	onCloseDelete = () => {
		this.setState({
			visible_delete: false,
			order: null
		},() => this.load(true));
	}
	
	render() {
		const { visible_view, visible_observation, visible_delete } = this.state;
		const order: any = this.state.order;

		return (
			<div id="orders">
				<Modal
		          className="modal-view-order"
		          visible={ visible_view }
		          title={
	          		<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-order">
		          				<h3>Orden de Compra</h3>
		          				<p>#{ order?.number }</p>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onCloseView();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalViewOrder
		          	order={ this.state.order }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-observation"
		          visible={ visible_observation }
		          title={
	          		<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-observation">
		          				<h3>Observaciones</h3>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onCloseObservation();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onCloseObservation() }
		        >
		          <ModalViewObservation
		          	observations={ order?.delete_comments || order?.edit_comments }
		            onClose={ () => this.onCloseObservation() } />
		      	</Modal>

		      	<Modal
		          className="modal-delete-order"
		          visible={ visible_delete }
		          title=""
		          onClose={ () => this.onCloseDelete() }
		        >
		          <ModalDelete
		          	order={ this.state.order }
		            onClose={ () => this.onCloseDelete() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-6">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre o número de orden"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Desde"
							showYearDropdown={ true }
							maxDate={ moment(this.state.form.until || moment()).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										since: text
									}						
								},this.load);
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Hasta"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							minDate={ this.state.form.since && moment(this.state.form.since).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										until: text
									}						
								},this.load);
							} }
							value={ this.state.form.until }
						/>
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-6"></div>
					<div className="col-md-6 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Orden de compras registradas" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index } className={ i.deleted_at ? 'deleted-item' : '' }>
								<td>{ moment(i.date).format('DD/MM/YYYY') }</td>
								<td className="code">#{ i.number }</td>
								<td>{ i.provider?.name }</td>
								<td>
									<div className={ `status-container ${ i.deleted_at != null ? 'deleted' : '' } ${ i.status == Constants.ORDERS_STATUS.PENDING ? 'active' : i.status == Constants.ORDERS_STATUS.INCOMPLETE ? 'incomplete' : 'inactive' }` }>
										<p>{ i.status_text }</p>
									</div>
								</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									{
										!i.deleted_at && i.status == Constants.ORDERS_STATUS.PENDING && i.assigned_lot == Constants.ASSIGNED_LOT.NO && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.download(e,i) }>
											<img src={ DownloadTableIcon } />
										</a>
									</Tooltip>
									{
										!i.deleted_at && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ TrashIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										(i.edit_comments || i.delete_comments) && (
											<Tooltip title="Observaciones">
												<a className="link-icon" href="#" onClick={ (e: any) => this.observations(e,i) }>
													<img src={ i.deleted_at ? CommentsRedIcon : CommentsIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Orders);