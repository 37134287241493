import { Api } from 'utils';

class OperationService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/operations/get',params),
		create: async (params?: any) => Api.createResponse('admin/operations/create',params),
		update: async (params?: any) => Api.createResponse('admin/operations/update',params),
		delete: async (params?: any) => Api.createResponse('admin/operations/delete',params),
		view: async (params?: any) => Api.createResponse('admin/operations/view',params),
	}

}

export default OperationService;