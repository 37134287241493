import React from 'react';
import { ReportService } from 'services';
import { Pagination, Table, DatePicker, Select } from 'components';
import moment from 'moment';
import { Constants } from 'utils';

interface Props {
	onClose: () => void;
	recipe: any;
}

class ModalViewRecipe extends React.Component<Props> {

	state: any = {
		recipe: null,
		header: [
			'Producto',
			'Porcentaje'
		]
	}

	componentDidMount() {
		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.viewRecipe({
			withoutLoading,
			recipe_id: this.props.recipe?.id
		});
		this.setState({
			recipe: res.recipe
		});
	}

	render() {
		return (
			<div id="modal-view-recipe">
				<Table title={ this.props.recipe?.name } data={ this.state.recipe?.details?.length } header={ this.state.header }>
					{ this.state.recipe?.details?.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.product?.name }</td>
								<td>{ i.percentage }</td>
							</tr>
						)
					}) }
				</Table>
			</div>
		)
	}
}

export default ModalViewRecipe;