import { Api } from 'utils';

class ReportService {
	
	static admin = {
		controlBar: async (params?: any) => Api.createResponse('admin/reports/control-bar',params),
		cristalInventory: async (params?: any) => Api.createResponse('admin/reports/cristal-inventory',params),
		productRequisition: async (params?: any) => Api.createResponse('admin/reports/product-requisition',params),
		viewRequisition: async (params?: any) => Api.createResponse('admin/reports/view-requisition',params),
		hostErrors: async (params?: any) => Api.createResponse('admin/reports/host-errors',params),
		controlPayments: async (params?: any) => Api.createResponse('admin/reports/host-control-payments',params),
		controlOut: async (params?: any) => Api.createResponse('admin/reports/host-control-out',params),
		delivery: async (params?: any) => Api.createResponse('admin/reports/host-delivery',params),
		addClients: async (params?: any) => Api.createResponse('admin/reports/host-add-clients',params),
		getBoards: async (params?: any) => Api.createResponse('admin/reports/get-boards',params),
		checklist: async (params?: any) => Api.createResponse('admin/reports/checklist',params),
		portioning: async (params?: any) => Api.createResponse('admin/reports/portioning',params),
		loss: async (params?: any) => Api.createResponse('admin/reports/loss',params),
		operations: async (params?: any) => Api.createResponse('admin/reports/operations',params),
		works: async (params?: any) => Api.createResponse('admin/reports/works',params),
		decrease: async (params?: any) => Api.createResponse('admin/reports/decrease',params),
		defrost: async (params?: any) => Api.createResponse('admin/reports/defrost',params),
		location: async (params?: any) => Api.createResponse('admin/reports/location',params),
		reception: async (params?: any) => Api.createResponse('admin/reports/reception',params),
		viewWorks: async (params?: any) => Api.createResponse('admin/reports/view-works',params),
		recipes: async (params?: any) => Api.createResponse('admin/reports/recipes',params),
		viewRecipe: async (params?: any) => Api.createResponse('admin/reports/view-recipe',params),
		compliance: async (params?: any) => Api.createResponse('admin/reports/compliance',params),
		complianceDetails: async (params?: any) => Api.createResponse('admin/reports/compliance-details',params),

		// Location
		exportDecrease: async (params?: any) => Api.createResponse('admin/reports/decrease-export',params),
		exportDefrost: async (params?: any) => Api.createResponse('admin/reports/defrost-export',params),
		exportCristal: async (params?: any) => Api.createResponse('admin/reports/cristal-export',params),
		exportControlBar: async (params?: any) => Api.createResponse('admin/reports/control-bar-export',params),
		exportLocation: async (params?: any) => Api.createResponse('admin/reports/location-export',params),
	}

}

export default ReportService;