import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import { Globals } from 'utils';
 
interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	since: '',
	until: ''
}

class Reception extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Fecha',
			'Nº Orden',
			'Proveedor',
			'Producto',
			'Presentación',
			'Cantidad',
			'Faltante',
			'Observaciones'
		],
		form: INITIAL_STATE,
		total: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Recepción y Faltante'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.reception({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.reception.rows,
			last_page: res.reception.count,
			total: res.total || 0
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		const { total } = this.state;

		return (
			<div id="reports-reception">
				<div className="row">
					<div className="col-md-6">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por Nº Orden, producto o proveedor"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Desde"
							showYearDropdown={ true }
							maxDate={ moment(this.state.form.until || moment()).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										since: text
									}						
								},this.load);
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Hasta"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							minDate={ this.state.form.since && moment(this.state.form.since).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										until: text
									}						
								},this.load);
							} }
							value={ this.state.form.until }
						/>
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="text-right">
					<div className="container-total">
						<p><strong>Cantidad de Incidencias:</strong> { total }</p>
					</div>
				</div>

				<Table title="Recepción y Faltante" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index } className={ i?.remaining_weight > 0 ? 'danger' : '' }>
								<td>{ moment(i?.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i?.detail?.purchase_order?.number }</td>
								<td>{ i?.detail?.purchase_order?.provider?.name }</td>
								<td>{ i?.detail?.product?.name }</td>
								<td>{ i?.detail?.presentation?.name }</td>
								<td>{ Globals.formatMiles(i?.weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
								<td>{ Globals.formatMiles(i?.remaining_weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
								<td>{ i?.description }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Reception);