import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CreateButton, Input, Table, Pagination, Tooltip, Modal, Select } from 'components';
import { TrashIcon, ViewIcon, EditIcon } from 'assets/icons';
import { CategoryService } from 'services';
import { Globals, Constants } from 'utils';
import ModalCreateCategory from './modal-create';
import ModalViewCategory from './modal-view';
import { WhiteImage } from 'assets/img';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	type_id: ''
}

class Categories extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'',
			'Categoría',
			'Código',
			'Tipo',
			'Descripción',
			'Acción'
		],
		category: null,
		visible: false,
		types: [],
		visible_view: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Categorías',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Categorías'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await CategoryService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.categories.rows,
			last_page: res.categories.count,
			types: res.types
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.setState({
			visible: true
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			category: item
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			category: item
		});
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la categoría?',async () => {
			await CategoryService.admin.delete({
				category_id: item.id
			});
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			category: null
		},() => {
			this.load(true);
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			category: null
		});
	}
	
	render() {
		const { visible, visible_view } = this.state;

		return (
			<div id="categories">
				<Modal
		          className="modal-create-category"
		          visible={ visible }
		          title={ this.state.category ? 'Editar' : 'Crear' }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreateCategory
		          	category={ this.state.category }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-category"
		          visible={ visible_view }
		          title=""
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalViewCategory
		          	onEdit={ () => {
		          		this.setState({
		          			visible: true,
		          			visible_view: false
		          		});
		          	} }
		          	category={ this.state.category }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-7">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Select
							name="type_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Tipo"
							value={ this.state.form.type_id }
							options={ this.state.types.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-6"></div>
					<div className="col-md-6 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Categorías Registradas" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>
									<img src={ i.image ? (Constants.BASE_STORAGE + i.image) : WhiteImage } className="image-category" />
								</td>
								<td className="item-category">{ i.name }</td>
								<td className="item-category">{ i.code }</td>
								<td className="item-category">{ i.type?.name }</td>
								<td className="item-category">{ i.description }</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<div className="item-icons">
										<Tooltip title="Ver">
											<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
												<img src={ ViewIcon } />
											</a>
										</Tooltip>
										<Tooltip title="Editar">
											<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
												<img src={ EditIcon } />
											</a>
										</Tooltip>
										<Tooltip title="Eliminar">
											<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
												<img src={ TrashIcon } />
											</a>
										</Tooltip>
									</div>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Categories);