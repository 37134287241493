import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input } from 'components';
import { ReportService } from 'services';
import moment from 'moment';
import { Constants } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class Loss extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Fecha',
			'Producto fuente',
			'Peso Bruto (Kg)',
			'Peso Limpio (Kg)',
			'Merma (Kg)',
			'Nº Porciones',
			'Porciones sobrantes'
		],
		form: INITIAL_STATE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Reporte de Perdida'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.loss({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.work_order_details.rows,
			last_page: res.work_order_details.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="reports-loss">
				<div className="row">
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<Table title="Reporte de Perdida" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						const weight = i?.order_weights?.map((i: any) => i.weight).reduce((a: number, b: number) => a + b,0) || 0;
						const leftover = weight - i?.destiny_weight;
						let sum_decrease = 0;

						i?.portioning?.filter((i: any) => i.operation_id).forEach((item: any) => {
							if (item?.operation && item?.operation?.category_id == Constants.OPERATION_CATEGORIES.DECREASE && item?.value) {
								sum_decrease += item?.value;
							}

							item?.details?.forEach((_item: any) => {
								if (_item?.operation && _item?.operation?.category_id == Constants.OPERATION_CATEGORIES.DECREASE && _item?.value) {
									sum_decrease += _item?.value;
								}
							});
						});

						return (
							<tr key={ index }>
								<td>{ moment(i?.date).format('DD-MM-YYYY HH:mm') }</td>
								<td>{ i?.origin_product?.name }</td>
								<td>{ i?.origin_weight }</td>
								<td>{ i?.clean_weight }</td>
								<td>{ sum_decrease }</td>
								<td>{ weight }</td>
								<td>{ leftover >= 0 ? leftover : '-' }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Loss);