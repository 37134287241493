import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CreateButton, Input, Select, Table, Pagination, Tooltip, Modal, DatePicker } from 'components';
import { TrashIcon, EditIcon } from 'assets/icons';
import { ConditionedService } from 'services';
import { Globals, Constants, Colors } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	department_id: '',
	search: ''
}

class Conditioned extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Nombre',
			'Código',
			'Departamento',
			'Acción'
		],
		departments: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Acondicionado',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Acondicionado'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ConditionedService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.conditioned.rows,
			last_page: res.conditioned.count,
			departments: res.departments
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.props.history.push('/admin/production/conditioned/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.props.history.push('/admin/production/conditioned/edit/' + item.id);
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el registro?',async () => {
			await ConditionedService.admin.delete({
				conditioned_id: item.id
			});
			this.load(true);
		});
	}
	
	render() {
		return (
			<div id="conditioned">
				<div className="row">
					<div className="col-md-8">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<Select
							name="department_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Departamento"
							value={ this.state.form.department_id }
							options={ this.state.departments.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-6"></div>
					<div className="col-md-6 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Acondicionados registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td className="name">{ i.name }</td>
								<td>{ i.number }</td>
								<td>{ i.department?.name }</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Conditioned);