import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Select, ContainerRed, Button, Input, DatePicker, PaperClip } from 'components';
import { ProductService } from 'services';
import { HashIcon, EditCircleRed, AddWhiteIcon, TrashIcon } from 'assets/icons';
import { Constants, Globals } from 'utils';
import { WhiteImage } from 'assets/img';

interface MatchParams {
	product_id: string
}

const STEPS = {
	PRODUCT: 1,
	PRESENTATION: 2
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class CreateProduct extends React.Component<Props> {

	state = {
		form: {
			name: '',
			description: '',
			code: '',
			unit_id: '',
			category_id: '',
			minimum_stock: '',
			image: '',
			type_id: '',
			conversion: 1
		},
		categories: [],
		types: [],
		units: [],
		step: STEPS.PRODUCT,
		presentations: [{
			name: '',
			code: '',
			unit_id: '',
			image: '',
			conversion: 1
		}],
		product: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Productos',
				onBack: () => {
					this.props.history.push('/admin/products');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Productos / ${ this.props.match.params.product_id ? 'Editar' : 'Crear' } Producto`
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ProductService.admin.getData({
			product_id: this.props.match.params.product_id
		});
		this.setState({
			categories: res.categories,
			units: res.units,
			types: res.types
		},() => {
			if (res.product) {
				this.setState({
					form: {
						...this.state.form,
						name: res.product?.name || '',
						description: res.product?.description || '',
						code: res.product?.code || '',
						unit_id: res.product?.unit_id || '',
						category_id: res.product?.category_id || '',
						minimum_stock: res.product?.minimum_stock || '',
						type_id: res.product?.type_id || '',
						conversion: res.product?.conversion || 1
					},
					presentations: res.product?.presentations?.map((i: any) => {
						return {
							id: i.id,
							name: i.name,
							code: i.code,
							image: '',
							unit_id: i.unit_id,
							uploaded_image: i.image,
							conversion: i.conversion || 1
						}
					}) || [],
					product: res.product
				});
			}
			else if (this.props.match.params.product_id) {
				this.props.history.push('/admin/products');
			}
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	add = () => {
		let presentations = [...this.state.presentations];
		presentations.push({
			name: '',
			code: '',
			unit_id: '',
			image: '',
			conversion: 1
		});
		this.setState({
			presentations
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.match.params.product_id) {
			await ProductService.admin.update({
				product_id: this.props.match.params.product_id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				image: this.state.form.image?.value || '',
				presentations: JSON.stringify(this.state.presentations.map((i: any) => {
					return {
						id: i.id,
						name: i.name,
						code: i.code,
						unit_id: i.unit_id,
						conversion: i.conversion
					}
				})),
				photos: this.state.presentations.map((i: any) => i.image?.value)
			});
			Globals.showSuccess("Se ha modificado el producto correctamente");
			this.props.history.push('/admin/products');
		}
		else {
			if (!this.state.form.image) {
				Globals.showError("Debe ingresar una imagen del producto");
				return false;
			}

			let message: any = null;

			this.state.presentations.forEach((item: any) => {
				if (!item.name && !message) {
					message = 'Debe ingresar el nombre de todas las presentaciones';
				}

				if (!item.code && !message) {
					message = 'Debe ingresar el código de todas las presentaciones';
				}

				if (!item.unit_id && !message) {
					message = 'Debe ingresar la unidad de medida de todas las presentaciones';
				}

				if (!item.image && !message) {
					message = 'Debe ingresar la imagen de todas las presentaciones';
				}

				if (!item.conversion && !message) {
					message = 'Debe ingresar la conversión de todas las presentaciones';
				}
			});

			if (message) {
				Globals.showError(message);
				return false;
			}

			await ProductService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				image: this.state.form.image?.value || '',
				presentations: JSON.stringify(this.state.presentations.map((i: any) => {
					return {
						id: i.id,
						name: i.name,
						code: i.code,
						unit_id: i.unit_id,
						conversion: i.conversion
					}
				})),
				photos: this.state.presentations.map((i: any) => i.image?.value)
			});
			Globals.showSuccess("Se ha creado el producto correctamente");
			this.props.history.push('/admin/products');
		}
	}
	
	remove = (index: number) => {
		let presentations: any = [...this.state.presentations];
		presentations.splice(index,1);
		this.setState({
			presentations
		});
	}

	changeItem = (e: any,index: number) => {
		let presentations: any = [...this.state.presentations];
		presentations[index][e.target.name] = e.target.value;
		this.setState({
			presentations
		});
	}

	render() {
		const { step, presentations } = this.state;

		return (
			<div id="create-product">
				<ContainerRed title={ `${ this.props.match.params.product_id ? 'Editar' : 'Crear' } Producto` }>	
					<div className="row row-tabs">
						<div className="col-md-6">
							<div className={ `tab ${ step == STEPS.PRODUCT ? 'active' : '' }` } onClick={ () => {
								this.setState({
									step: STEPS.PRODUCT
								})
							} }>
								<p>Datos</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className={ `tab ${ step == STEPS.PRESENTATION ? 'active' : '' }` } onClick={ () => {
								this.setState({
									step: STEPS.PRESENTATION
								})
							} }>
								<p>Presentación</p>
							</div>
						</div>
					</div>
					<form onSubmit={ this.submit }>
						{
							step == STEPS.PRODUCT && (
								<div className="row">
									<div className="col-md-12 text-center">
										<PaperClip
											value={ this.state.form.image }
											onChange={ (e: any) => {
												this.setState({
													form: {
														...this.state.form,
														image: e.target
													}
												})
											} }>
											{/*
											// @ts-ignore */}
											<img className={ `selected-image ${ this.state.form.image || this.state.product?.image ? '' : 'inactive' }` } src={ this.state.form.image?.dataURL || (this.state.product?.image ? (Constants.BASE_STORAGE + this.state.product?.image) : WhiteImage) } />
											<img src={ EditCircleRed } className="circle-red" />
										</PaperClip>
									</div>
									<div className="col-md-6">
										<Input
											value={ this.state.form.name }
											name="name"
											label="Nombre"
											onChange={ this.change } />
									</div>
									<div className="col-md-6">
										<label htmlFor="code">Código</label>
										<div className="input-group">
											<div className="input-group-prepend">
												<img src={ HashIcon } />
											</div>
											<Input
												type="number"
												maxLength={ 8 }
												value={ this.state.form.code }
												name="code"
												onChange={ this.change } />
										</div>
									</div>
									<div className="col-md-6">
										<Select
											label="Tipo"
											name="type_id"
											onChange={ (e: any) => this.change(e,() => {
												this.setState({
													form: {
														...this.state.form,
														category_id: ''
													}
												});
											}) }
											value={ this.state.form.type_id }
											options={ this.state.types.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) } />
									</div>
									<div className="col-md-6">
										{
											this.state.form.type_id && (
												<Select
													label="Categoría"
													name="category_id"
													onChange={ this.change }
													value={ this.state.form.category_id }
													options={ this.state.categories.filter((i: any) => i.type_id == this.state.form.type_id).map((i: any) => {
														return {
															value: i.id,
															label: i.name
														}
													}) } />
											)
										}
									</div>
									<div className="col-md-6">
										<Select
											label="Unidad de medida"
											name="unit_id"
											onChange={ this.change }
											value={ this.state.form.unit_id }
											options={ this.state.units.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) } />
									</div>
									<div className="col-md-6">
										<Input
											type="number"
											value={ this.state.form.minimum_stock }
											name="minimum_stock"
											label="Stock Mínimo"
											onChange={ this.change } />
									</div>
									<div className="col-md-6">
										<Input
											value={ this.state.form.description }
											name="description"
											label="Descripción"
											onChange={ this.change } />
									</div>
									<div className="col-md-6">
										<Input
											type="decimal"
											value={ this.state.form.conversion }
											name="conversion"
											label="Conversión a Kg"
											onChange={ this.change } />
									</div>
									<div className="col-12 text-center">
										<Button type="button" className="btn-normal" onClick={ () => {
											this.setState({
												step: STEPS.PRESENTATION
											});
										} }>
											Siguiente
										</Button>
									</div>
								</div>
							)
						}
						{
							step == STEPS.PRESENTATION && (
								<div className="row row-presentation">
									<div className="col-12 text-right">
										<Button type="button" className="add-button" onClick={ () => this.add() }>
											<img src={ AddWhiteIcon } />
										</Button>
									</div>
									{
										presentations.map((i: any, index: number) => {
											return (
												<div className="col-md-12 row item-presentation">
													<div className="col-5">
														<PaperClip
															value={ i.image }
															onChange={ (e: any) => {
																let presentations: any = [...this.state.presentations];
																presentations[index]['image'] = e.target;
																this.setState({
																	presentations
																});
															} }>
															{/*
															// @ts-ignore */}
															<img className={ `selected-image ${ i.image || i.uploaded_image ? '' : 'inactive' }` } src={ i.image?.dataURL || (i.uploaded_image ? (Constants.BASE_STORAGE + i.uploaded_image) : WhiteImage) } />
															<img src={ EditCircleRed } className="circle-red" />
														</PaperClip>
													</div>
													<div className="col-6">
														<Input
															value={ i.name }
															name="name"
															label="Nombre"
															onChange={ (e: any) => this.changeItem(e,index) } />
														<label htmlFor="code">Código</label>
														<div className="input-group">
															<div className="input-group-prepend">
																<img src={ HashIcon } />
															</div>
															<Input
																maxLength={ 8 }
																value={ i.code }
																name="code"
																onChange={ (e: any) => this.changeItem(e,index) } />
														</div>
														<div style={{ marginTop: '20px' }}>
															<Select
																label="Unidad de medida"
																name="unit_id"
																onChange={ (e: any) => this.changeItem(e,index) }
																value={ i.unit_id }
																options={ this.state.units.map((i: any) => {
																	return {
																		value: i.id,
																		label: i.name
																	}
																}) } />
														</div>
														<div style={{ marginTop: '20px' }}>
															<Input
																type="decimal"
																value={ i.conversion }
																name="conversion"
																label="Conversión a Kg"
																onChange={ (e: any) => this.changeItem(e,index) } />
														</div>
													</div>
													<div className="col-1">
														<Button type="button" className="remove-button" onClick={ () => this.remove(index) }>
															<img src={ TrashIcon } />
														</Button>
													</div>
												</div>
											)
										})
									}
									<div className="col-12 text-center">
										<Button type="submit" className="btn-submit">
											Guardar
										</Button>
									</div>
								</div>
							)
						}
					</form>
				</ContainerRed>
			</div>
		)
	}
}

export default connect()(CreateProduct);