import { Api } from 'utils';

class ProfilesService {
	
	static admin = {
		getLevels: async (params?: any) => Api.createResponse('admin/profiles/get-levels',params),
		setPermissions: async (params?: any) => Api.createResponse('admin/profiles/set-permissions',params),
	}

}

export default ProfilesService;