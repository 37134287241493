import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select, DownloadButton } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import { Globals, Print, Constants } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const TABS = {
	RECEPTION: 1,
	DEFROST: 2
}

const INITIAL_STATE = {
	search: '',
	date: '',
	level_id: ''
}

class Descrease extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Fecha',
			'Producto',
			'Nº Orden',
			'Proveedor',
			'Peso al recibir',
			'Peso Limpio',
			'Merma',
			'Observaciones'
		],
		page_defrost: 1,
		last_page_defrost: 1,
		data_defrost: [],
		header_defrost: [
			'Fecha',
			'Producto',
			'Requisición',
			'Peso Congelado',
			'Peso Descongelado',
			'Merma',
			'Hora Inicio',
			'Hora Fin'
		],
		form: INITIAL_STATE,
		tab: TABS.RECEPTION,
		levels: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Merma Consolidado'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		if (this.state.tab == TABS.RECEPTION) {
			const res: any = await ReportService.admin.decrease({
				withoutLoading,
				page: this.state.page,
				...this.state.form,
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
			});
			this.setState({
				data: res.reception.rows,
				last_page: res.reception.count,
				levels: res.levels
			});
		}
		else {
			const res: any = await ReportService.admin.defrost({
				withoutLoading,
				page: this.state.page_defrost,
				...this.state.form,
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
			});
			this.setState({
				data_defrost: res.defrost.rows,
				last_page_defrost: res.defrost.count,
				levels: res.levels
			});
		}
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1,
			page_defrost: 1
		},() => this.load(true));
	}

	download = async () => {
		const res: any = await ReportService.admin.exportDecrease({
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	downloadDefrost = async () => {
		const res: any = await ReportService.admin.exportDefrost({
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			page_defrost: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	setTab = (tab: number) => {
		this.setState({
			tab,
			page: 1,
			page_defrost: 1,
			form: INITIAL_STATE
		},() => this.load());
	}
	
	render() {
		return (
			<div id="reports-decrease">
				<div className="row">
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder={ this.state.tab == TABS.RECEPTION ? "Producto, proveedor o Nº de orden" : "Producto o requisición" }
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<DatePicker
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},this.load);
							} }
							value={ this.state.form.date }
						/>
					</div>
					{
						this.state.tab == TABS.DEFROST && (
							<div className="col-md-4">
								<Select
									name="level_id"
									onChange={ (e: any) => this.change(e,() => {
										this.load(true);
									}) }
									placeholder="Área"
									value={ this.state.form.level_id }
									options={ this.state.levels.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
							</div>
						)
					}
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="tabs-container">
					<div style={{ flex: .5 }}>
						<div className={ `tab ${ this.state.tab == TABS.RECEPTION ? 'active' : '' }` } onClick={ () => this.setTab(TABS.RECEPTION) }>
							Recepción de Mercancía
						</div>
					</div>
					<div style={{ flex: .5 }}>
						<div className={ `tab ${ this.state.tab == TABS.DEFROST ? 'active' : '' }` } onClick={ () => this.setTab(TABS.DEFROST) }>
							Descongelado
						</div>
					</div>
				</div>

				{
					this.state.tab == TABS.RECEPTION && (
						<React.Fragment>
							<div className="text-right" style={{ marginTop: '20px' }}>
								<DownloadButton onClick={ () => this.download() } />
							</div>

							<Table title="Reporte de Recepción de Mercancía" data={ this.state.data.length } header={ this.state.header }>
								{ this.state.data.map((i: any,index: number) => {
									const weight = i.weight || i?.detail?.weights?.map((item: any) => item.weight).reduce((a: number, b: number) => a + b,0);
									const clean_weight = i.clean_weight || i?.detail?.weights?.map((item: any) => item.weight).reduce((a: number, b: number) => a + b,0);

									return (
										<tr key={ index }>
											<td>{ moment(i?.created_at).format('DD/MM/YYYY') }</td>
											<td>{ i?.detail?.product?.name }</td>
											<td>{ i?.detail?.purchase_order?.number }</td>
											<td>{ i?.detail?.purchase_order?.provider?.name }</td>
											<td>{ Globals.formatMiles(weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
											<td>{ Globals.formatMiles(clean_weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
											<td>{ Globals.formatMiles(weight - clean_weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
											<td>{ i.description }</td>
										</tr>
									)
								}) }
							</Table>

							<Pagination 
								pages={ this.state.last_page } 
								active={ this.state.page }
								onChange={ async (page: number) => {
									await this.setState({
										page: page
									});
									this.load();
								} } />
						</React.Fragment>
					)
				}

				{
					this.state.tab == TABS.DEFROST && (
						<React.Fragment>
							<div className="text-right" style={{ marginTop: '20px' }}>
								<DownloadButton onClick={ () => this.downloadDefrost() } />
							</div>

							<Table title="Reporte de Descongelado" data={ this.state.data_defrost.length } header={ this.state.header_defrost }>
								{ this.state.data_defrost.map((i: any,index: number) => {
									return (
										<tr key={ index }>
											<td>{ moment(i?.created_at).format('DD/MM/YYYY') }</td>
											<td>{ i?.detail?.product?.name }</td>
											<td>{ i?.detail?.requisition?.number }</td>
											<td>{ Globals.formatMiles(i.weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
											<td>{ Globals.formatMiles(i.defrost_weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
											<td>{ Globals.formatMiles(i.weight - i.defrost_weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
											<td>{ i?.init_defrost ? moment(i?.init_defrost,'HH:mm:ss').format('hh:mm A') : '' }</td>
											<td>{ i?.finish_defrost ? moment(i?.finish_defrost,'HH:mm:ss').format('hh:mm A') : '' }</td>
										</tr>
									)
								}) }
							</Table>

							<Pagination 
								pages={ this.state.last_page_defrost } 
								active={ this.state.page_defrost }
								onChange={ async (page: number) => {
									await this.setState({
										page_defrost: page
									});
									this.load();
								} } />
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default connect()(Descrease);