import React from 'react';
import { CheckGreenIcon, AddIcon } from 'assets/icons';
import { Table, Tooltip, Modal } from 'components';
import { TrashIcon, ViewIcon, DownloadTableIcon } from 'assets/icons';
import moment from 'moment';
import { OrderService } from 'services';
import { Print, Constants } from 'utils';
import ModalViewOrder from 'screens/admin/orders/modal-view';
import ModalDelete from 'screens/admin/orders/modal-delete';

interface Props {
	orders: any;
	load: (withoutLoading: boolean) => void;
	count: number;
	onCreate: () => void;
	onViewMore: () => void;
}

class Orders extends React.Component<Props> {

	state = {
		header: [
			'Fecha',
			'Nº Orden',
			'Proveedor',
			'Acción'
		],
		order: null,
		visible_view: false,
		visible_delete: false
	}

	componentDidMount() {
		console.log(this.props.orders)
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			order: item
		});
	}

	download = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await OrderService.admin.export({
			order_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_delete: true,
			order: item
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			order: null
		});
	}

	onCloseDelete = () => {
		this.setState({
			visible_delete: false,
			order: null
		},() => this.props.load(true));
	}
	
	render() {
		const { visible_view, visible_delete } = this.state;
		const order: any = this.state.order;

		return (
			<div id="home-orders">
				<Modal
		          className="modal-view-order"
		          visible={ visible_view }
		          title={
	          		<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-order">
		          				<h3>Orden de Compra</h3>
		          				<p>#{ order?.number }</p>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onCloseView();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalViewOrder
		          	order={ this.state.order }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

		      	<Modal
		          className="modal-delete-order"
		          visible={ visible_delete }
		          title=""
		          onClose={ () => this.onCloseDelete() }
		        >
		          <ModalDelete
		          	order={ this.state.order }
		            onClose={ () => this.onCloseDelete() } />
		      	</Modal>

				<div className="title-home">
					<div style={{ flex: .5 }}>
						<h3>Ordenes de compra</h3>
						<div className="count-container">
							<img src={ CheckGreenIcon } />
							<p>{ this.props.count } Registradas</p>
						</div>
					</div>
					<div style={{ flex: .5 }} className="text-right">
						<div className="add-item" onClick={ () => {
							this.props.onCreate();
						} }>
							<img src={ AddIcon } />
							<p>Crear</p>
						</div>
					</div>
				</div>
				<Table title="" data={ this.props.orders.length } header={ this.state.header }>
					{ this.props.orders.map((i: any,index: number) => {
						return (
							<tr key={ index } className={ i.deleted_at ? 'deleted-item' : '' }>
								<td>{ moment(i.date).format('DD/MM/YYYY') }</td>
								<td className="code">#{ i.number }</td>
								<td>{ i.provider?.name }</td>
								<td>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.download(e,i) }>
											<img src={ DownloadTableIcon } />
										</a>
									</Tooltip>
									{
										!i.deleted_at && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ TrashIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>
				{
					this.props.count > this.props.orders.length && (
						<div className="text-center">
							<a href="#" onClick={ (e: any) => {
								e.preventDefault();
								this.props.onViewMore();
							} } className="view-more">
								Ver Más
							</a>
						</div>
					)
				}
			</div>
		)
	}
}

export default Orders