import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { ContainerRed, Button, Input, Select, DatePicker, Tooltip, SelectSearch, Pagination, Table, Modal } from 'components';
import { Globals, Constants, Print } from 'utils';
import moment from 'moment';
import { TrashIcon, CalendarWhite, EditIcon } from 'assets/icons';
import { PlanService } from 'services';
import ModalGenerateRequisition from './modal-generate-requisition';

interface MatchParams {
	plan_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

const TYPES = {
	BAG: 1,
	BOX: 2
}

const EXTRA = {
	YES: 1,
	NO: 0
}

const REQUISITION = {
	YES: 1,
	NO: 0
}

const DEPARTMENT_RESTAURANT = {
	CHINESE: {
		ID: Constants.LEVELS.CHINESE,
		DEPARTMENT: Constants.DEPARTMENT.CHINESE
	},
	JAPANESE: {
		ID: Constants.LEVELS.JAPANESE,
		DEPARTMENT: Constants.DEPARTMENT.JAPANESE
	},
	BAR: {
		ID: Constants.LEVELS.BAR,
		DEPARTMENT: Constants.DEPARTMENT.BAR
	}
};

class CreatePlan extends React.Component<Props> {

	timer: any = undefined;

	state = {
		items: [],
		name: '',
		department_id: '',
		form: {
			id: null,
			package_id: '',
			package_type_id: TYPES.BAG,
			destiny_product_id: '',
			destiny_presentation_id: '',
			origin_product_id: '',
			origin_presentation_id: '',
			percentage: '',
			consumption: '',
			date: '',
			destiny_weight: '',
			origin_weight: '',
			quantity_per_package: '',
			package_quantity: 0,
			extra: EXTRA.NO,
			existence: 0,
			technique_id: '',
			generate_requisition: REQUISITION.NO,
			date_requisition: ''
		},
		departments: [],
		products: [],
		techniques: [],
		visible_requisition: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Plan de producción',
				onBack: () => {
					this.props.history.push('/admin/production/plans');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Plan de producción / ${ this.props.match.params.plan_id ? 'Editar' : 'Crear' } plan`
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await PlanService.admin.getData({
			plan_id: this.props.match.params.plan_id
		});
		this.setState({
			departments: res.departments,
			products: res.products,
			techniques: res.techniques
		},() => {
			if (res.plan) {
				this.setState({
					name: res.plan?.name || '',
					department_id: res.plan?.department_id || '',
					items: res.plan?.details?.map((item: any) => {
						return {
							id: item?.id,
							package_id: item?.package_id || '',
							package_type_id: item?.package_type_id || '',
							destiny_product_id: item?.destiny_product_id || '',
							destiny_presentation_id: item?.destiny_presentation_id || '',
							origin_product_id: item?.origin_product_id || '',
							origin_presentation_id: item?.origin_presentation_id || '',
							percentage: item?.percentage != null ? item?.percentage : '',
							consumption: item?.consumption || '',
							date: item?.date ? moment(item?.date).toDate() : '',
							destiny_weight: item?.destiny_weight || '',
							origin_weight: item?.origin_weight || '',
							quantity_per_package: item?.quantity_per_package || '',
							package_quantity: item?.package_quantity || '',
							extra: item?.percentage != null ? EXTRA.YES : EXTRA.NO,
							existence: item?.existence != null ? item?.existence : '',
							technique_id: item?.technique_id || '',
							generate_requisition: item?.generate_requisition || REQUISITION.NO,
							date_requisition: item?.date_requisition ? moment(item?.date_requisition).toDate() : '',
						}
					}) || []
				});
			}
			else if (this.props.match.params.plan_id) {
				this.props.history.push('/admin/production/plans');
			}
		});
	}

	getExistence = async () => {
		const res: any = await PlanService.admin.getExistence({
			product_id: this.state.form.destiny_product_id,
			presentation_id: this.state.form.destiny_presentation_id || null
		});
		this.setState({
			form: {
				...this.state.form,
				existence: res.existence
			}
		});
	}

	reset = () => {
		this.setState({
			form: {
				...this.state.form,
				search: ''
			},
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	add = async () => {
		const {
			package_id,
			destiny_product_id,
			origin_product_id,
			percentage,
			consumption,
			date,
			destiny_weight,
			origin_weight,
			quantity_per_package,
			package_quantity,
			extra,
			technique_id,
			generate_requisition,
			date_requisition
		} = this.state.form;

		if (!destiny_product_id) {
			Globals.showError("Debe ingresar el producto final");
			return false;
		}

		if (!origin_product_id) {
			Globals.showError("Debe ingresar el producto fuente");
			return false;
		}

		if (!package_id) {
			Globals.showError("Debe ingresar el tipo de empaque");
			return false;
		}

		if (!origin_weight) {
			Globals.showError("Debe ingresar los Kg a necesitar");
			return false;
		}

		if (!date) {
			Globals.showError("Debe ingresar la fecha");
			return false;
		}

		if (!destiny_weight) {
			Globals.showError("Debe ingresar el campo producción porciones");
			return false;
		}

		if (!consumption) {
			Globals.showError("Debe ingresar el consumo");
			return false;
		}

		if (extra == EXTRA.YES && !percentage) {
			Globals.showError("Debe ingresar el campo porcentaje");
			return false;
		}

		if (!quantity_per_package) {
			Globals.showError("Debe ingresar las porciones en empaque");
			return false;
		}

		if (!technique_id) {
			Globals.showError("Debe ingresar la técnica de producción");
			return false;
		}

		if (!generate_requisition) {
			Globals.showError("Debe generar la requisición para continuar");
			return false;
		}

		if (!date_requisition) {
			Globals.showError("Debe ingresar la fecha de requisición");
			return false;
		}

		let items: any = [...this.state.items];
		if (this.state.form.id) {
			const index = this.state.items.findIndex((i: any) => i.id == this.state.form.id);
			items[index] = { ...this.state.form };
		}
		else {
			items.push({
				...this.state.form
			});
		}
		this.setState({
			items,
			form: {
				id: null,
				package_id: '',
				package_type_id: TYPES.BAG,
				destiny_product_id: '',
				destiny_presentation_id: '',
				origin_product_id: '',
				origin_presentation_id: '',
				percentage: '',
				consumption: '',
				date: '',
				destiny_weight: '',
				origin_weight: '',
				quantity_per_package: '',
				package_quantity: 0,
				extra: EXTRA.NO,
				generate_requisition: REQUISITION.NO,
				existence: 0,
				technique_id: '',
				date_requisition: ''
			}
		});
	}

	delete = (e: any, index: number) => {
		e.preventDefault();
		let items: any = [...this.state.items];
		items.splice(index,1);
		this.setState({
			items
		});
	}

	viewPreview = async () => {
		const res: any = await PlanService.admin.preview({
			items: this.state.items.map((i: any) => {
				const destiny_product: any = this.state.products.find((_i: any) => _i.id == i?.destiny_product_id);
				const presentation: any = destiny_product?.presentations?.find((_i: any) => _i.id == i?.destiny_presentation_id);
				const origin_product: any = this.state.products.find((_i: any) => _i.id == i?.origin_product_id);
				const technique: any = this.state.techniques.find((_i: any) => _i.id == i?.technique_id);

				return {
					name: destiny_product?.name,
					presentation: presentation?.name || '',
					consumption: i?.consumption,
					existence: i?.existence,
					destiny_weight: i?.destiny_weight,
					origin_product: origin_product?.name || '',
					origin_weight: i?.origin_weight,
					date: moment(i.date).format('YYYY-MM-DD HH:mm'),
					technique: technique?.name || ''
				}
			})
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	submit = async () => {
		const setRestaurant = Object.values(DEPARTMENT_RESTAURANT).find(item => item.DEPARTMENT === Number(this.state.department_id));
		if (this.props.match.params.plan_id) {
			await PlanService.admin.edit({
				items: this.state.items.map((item: any) => {
					item.date = moment(item.date).format('YYYY-MM-DD HH:mm');
					item.date_requisition = item.date_requisition ? moment(item.date_requisition).format('YYYY-MM-DD HH:mm') : null;
					return item;
				}),
				name: this.state.name,
				department_id: this.state.department_id,
				plan_id: this.props.match.params.plan_id,
				user_id: this.props.user?.id,
				level_id: setRestaurant?.ID
			});
			this.props.history.replace('/admin/production/plans/');
		}
		else {
			await PlanService.admin.create({
				items: this.state.items.map((item: any) => {
					item.date = moment(item.date).format('YYYY-MM-DD HH:mm');
					item.date_requisition = item.date_requisition ? moment(item.date_requisition).format('YYYY-MM-DD HH:mm') : null;
					return item;
				}),
				name: this.state.name,
				department_id: this.state.department_id,
				user_id: this.props.user?.id,
				level_id: setRestaurant?.ID
			});
			this.props.history.replace('/admin/production/plans/');
		}
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			form: {
				...this.state.form,
				id: item?.id,
				package_id: item?.package_id || '',
				package_type_id: item?.package_type_id || '',
				destiny_product_id: item?.destiny_product_id || '',
				destiny_presentation_id: item?.destiny_presentation_id || '',
				origin_product_id: item?.origin_product_id || '',
				origin_presentation_id: item?.origin_presentation_id || '',
				percentage: item?.percentage != null ? item?.percentage : '',
				consumption: item?.consumption || '',
				date: item?.date ? moment(item?.date).toDate() : '',
				destiny_weight: item?.destiny_weight || '',
				origin_weight: item?.origin_weight || '',
				quantity_per_package: item?.quantity_per_package || '',
				package_quantity: item?.package_quantity || '',
				extra: item?.percentage ? EXTRA.YES : EXTRA.NO,
				generate_requisition: item?.generate_requisition || REQUISITION.NO,
				existence: item?.existence != null ? item?.existence : '',
				technique_id: item?.technique_id || '',
				date_requisition: item?.date_requisition ? moment(item?.date_requisition).toDate() : '',
			}
		},() => console.log(this.state.form));
	}

	calculate() {
		const { percentage, consumption, extra, existence, quantity_per_package } = this.state.form;
		const consumption_total = parseFloat(consumption || '0') + (extra == EXTRA.YES ? (percentage ? ((parseFloat(percentage || '0') * parseFloat(consumption || '0')) / 100) : 0) : 0);
		let destiny_weight = consumption_total - existence;

		if (destiny_weight < 0) {
			destiny_weight = 0;
		}

		let package_quantity = this.state.form.package_quantity;

		if (quantity_per_package)
			package_quantity = quantity_per_package ? Math.ceil((destiny_weight) / parseFloat(quantity_per_package)) : 0;

		this.setState({
			form: {
				...this.state.form,
				destiny_weight,
				package_quantity
			}
		});
	}

	onClose = (form: any = null) => {
		this.setState({
			visible_requisition: false,
		},() => {
			if (form) {
				this.setState({
					form: {
						...this.state.form,
						...form
					}
				});
			}
		});
	}
	
	render() {
		const destiny_product: any = this.state.products.find((i: any) => i.id == this.state.form.destiny_product_id);
		const origin_product: any = this.state.products.find((i: any) => i.id == this.state.form.origin_product_id);
		const package_product: any = this.state.products.find((i: any) => i.id == this.state.form.package_id);
		const { percentage, consumption, extra } = this.state.form;
		const { visible_requisition } = this.state;
		const consumption_total = parseFloat(consumption || '0') + (extra == EXTRA.YES ? (percentage ? ((parseFloat(percentage || '0') * parseFloat(consumption || '0')) / 100) : 0) : 0);

		return (
			<div id="create-plans">
				<Modal
		          className="modal-generate-requisition"
		          visible={ visible_requisition }
		          title={
		          	<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-order">
		          				<h3>Generar requisición</h3>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onClose();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ (form: any) => this.onClose(form) }
		        >
		          <ModalGenerateRequisition
		          	date_requisition={ this.state.form.date_requisition }
		            onClose={ (form: any) => this.onClose(form) } />
		      	</Modal>

				<div className="container-form">
					<div className="row">
						<div className="col-md-8">
							<Input
								className="input-top"
								value={ this.state.name }
								name="name"
								placeholder="Nombre del plan"
								onChange={ (e: any) => {
									this.setState({
										name: e.target.value
									});
								} } />
						</div>
						<div className="col-md-4">
							<Select
								className="input-top"
								name="department_id"
								onChange={ (e: any) => {
									this.setState({
										department_id: e.target.value
									});
								} }
								placeholder="Departamento"
								value={ this.state.department_id }
								options={ this.state.departments.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
					</div>
				</div>
				<div className="container-form">
					<div className="row">
						<div className="col-md-4">
							<SelectSearch
								label="Producto final"
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											destiny_product_id: e.value,
											destiny_presentation_id: ''
										}
									},() => this.getExistence());
								} }
								color="gray"
								searchRight
								value={ destiny_product ? {
									value: destiny_product.id,
									label: destiny_product.name
								} : null }
								options={ this.state.products.filter((i: any) => i.type_id == Constants.PRODUCT_TYPES.PRODUCT).map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						{
							destiny_product?.presentations?.length > 0 && (
								<div className="col-md-4">
									<Select
										name="destiny_presentation_id"
										onChange={ (e: any) => {
											this.setState({
												form: {
													...this.state.form,
													destiny_presentation_id: e.target.value
												}
											},() => this.getExistence());
										} }
										label="Presentación (Final)"
										value={ this.state.form.destiny_presentation_id }
										options={ destiny_product?.presentations?.map((i: any) => {
											return {
												value: i.id,
												label: i.name
											}
										}) } />
								</div>
							)
						}
						<div className="col-md-4">
							<div className="row">
								<div className={ this.state.form.extra == EXTRA.YES ? 'col-6' : 'col-12' }>
									<Input
										value={ this.state.form.consumption }
										name="consumption"
										label="Consumo"
										type="number"
										onChange={ (e: any) => this.change(e,() => this.calculate()) } />
								</div>
								{
									this.state.form.extra == EXTRA.YES && (
										<div className="col-6">
											<Input
												value={ this.state.form.percentage }
												name="percentage"
												label="Porcentaje"
												type="percentage"
												onChange={ (e: any) => this.change(e,() => this.calculate()) } />
										</div>
									)
								}
							</div>
							<div className="item-check" onClick={ () => {
								this.setState({
									form: {
										...this.state.form,
										extra: this.state.form.extra == EXTRA.YES ? EXTRA.NO : EXTRA.YES
									}
								});
							} }>
								<div className={ `circle ${ this.state.form.extra == EXTRA.YES ? 'active' : '' }` }></div>
								<p>Consumo adicional</p>
							</div>
						</div>
						{
							this.state.form.extra == EXTRA.YES && (
								<div className="col-md-4">
									<Input
										disabled={ true }
										value={ consumption_total }
										label="Consumo total" />
								</div>
							)
						}
						<div className="col-md-4">
							<Input
								disabled={ true }
								value={ this.state.form.existence }
								name="existence"
								label="Existencia" />
						</div>
						<div className="col-md-4">
							<Input
								value={ this.state.form.destiny_weight }
								label="Producción porciones"
								disabled={ true } />
						</div>
						<div className="col-md-4">
							<SelectSearch
								label="Producto fuente"
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											origin_product_id: e.value,
											origin_presentation_id: ''
										}
									});
								} }
								color="gray"
								searchRight
								value={ origin_product ? {
									value: origin_product.id,
									label: origin_product.name
								} : null }
								options={ this.state.products.filter((i: any) => i.type_id == Constants.PRODUCT_TYPES.PRODUCT).map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						{
							origin_product?.presentations?.length > 0 && (
								<div className="col-md-4">
									<Select
										name="origin_presentation_id"
										onChange={ (e: any) => this.change(e) }
										label="Presentación (fuente)"
										value={ this.state.form.origin_presentation_id }
										options={ origin_product?.presentations?.map((i: any) => {
											return {
												value: i.id,
												label: i.name
											}
										}) } />
								</div>
							)
						}
						<div className="col-md-4">
							<Input
								value={ this.state.form.origin_weight }
								name="origin_weight"
								label="Kg a necesitar"
								type="decimal"
								onChange={ (e: any) => this.change(e) } />
							<div className="item-check" style={{
								marginBottom: this.state.form.generate_requisition == REQUISITION.YES ? '5px' : '20px'
							}} onClick={ () => {
								this.setState({
									form: {
										...this.state.form,
										generate_requisition: this.state.form.generate_requisition == REQUISITION.YES ? REQUISITION.NO : REQUISITION.YES
									}
								},() => {
									if (this.state.form.generate_requisition == REQUISITION.YES) {
										this.setState({
											visible_requisition: true
										});
									}
								});
							} }>
								<div className={ `circle ${ this.state.form.generate_requisition == REQUISITION.YES ? 'active' : '' }` }></div>
								<p>Generar requisición</p>
							</div>
							{
								this.state.form.generate_requisition == REQUISITION.YES && (
									<p className="view-requisition" onClick={ () => {
										this.setState({
											visible_requisition: true
										});
									} }>Editar requisición</p>
								)
							}
						</div>
						<div className="col-md-4">
							<Select
								name="technique_id"
								onChange={ (e: any) => this.change(e) }
								label="Técnica de producción"
								value={ this.state.form.technique_id }
								options={ this.state.techniques.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-4">
							<DatePicker
								calendarIcon={ CalendarWhite }
								label="Fecha de Entrega y Hora de Inicio de Producción"
								placeholder="DD/MM/YYYY HH:mm"
								showTimeSelect={ true }
								showYearDropdown={ true }
								minDate={ moment().toDate() }
								dateFormat="dd/MM/yyyy HH:mm"
								onChange={ (text: string) => {
									this.setState({
										form: {
											...this.state.form,
											date: text
										}						
									});
								} }
								value={ this.state.form.date }
							/>
						</div>
						<div className="col-md-4">
							<SelectSearch
								label="Tipo de empaque"
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											package_id: e.value
										}
									});
								} }
								color="gray"
								searchRight
								value={ package_product ? {
									value: package_product.id,
									label: package_product.name
								} : null }
								options={ this.state.products.filter((i: any) => i.type_id == Constants.PRODUCT_TYPES.PACKAGE).map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-4">
							<Input
								value={ this.state.form.quantity_per_package }
								name="quantity_per_package"
								label="Porciones en empaque"
								type="number"
								onChange={ (e: any) => this.change(e,() => this.calculate()) } />
						</div>
						<div className="col-md-4">
							<Input
								value={ this.state.form.package_quantity }
								label="Cantidad de empaques"
								disabled={ true } />
						</div>
					</div>

					<div className="text-center">
						<Button type="submit" onClick={ this.add } className="btn-submit">
							Cargar
						</Button>
					</div>
				</div>
				<div className="table-bottom">
					{
						this.state.items.length > 0 && (
							<Button className="view-preview" onClick={ () => this.viewPreview() }>
								Vista Previa
							</Button>
						)
					}
					<Table title="Plan de producción" data={ this.state.items.length } header={ [
						'Producto',
						'Presentación',
						'Producción porciones',
						'Kg a procesar',
						'Fecha',
						'Acción'
					] }>
						{ this.state.items.map((i: any,index: number) => {
							const product: any = this.state.products.find((_i: any) => _i.id == i?.destiny_product_id);
							const presentation: any = product?.presentations?.find((_i: any) => _i.id == i?.destiny_presentation_id);

							return (
								<tr key={ index }>
									<td className="name">{ product?.name }</td>
									<td>{ presentation?.name }</td>
									<td>{ i?.destiny_weight }</td>
									<td>{ i?.origin_weight }</td>
									<td>{ moment(i?.date).format('DD-MM-YYYY HH:mm') }</td>
									<td>
										<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
										<Tooltip title="Eliminar">
											<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,index) }>
												<img src={ TrashIcon } />
											</a>
										</Tooltip>
									</td>
								</tr>
							)
						}) }
					</Table>
				</div>

				{
					this.state.items.length > 0 && (
						<div className="text-center">
							<Button type="submit" onClick={ this.submit } className="btn-submit">
								Guardar
							</Button>
						</div>
					)
				}
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(CreatePlan);