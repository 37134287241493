import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Select, Button } from 'components';
import { DesignService } from 'services';
import { Constants, Colors, Globals } from 'utils';
import Draggable from 'react-draggable';
import { ColorIcon, RotationIcon, ShapeIcon, DeleteIcon } from 'assets/icons';

const DEFAULT_COLOR = '#75757d';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class StorageDesign extends React.Component<Props> {

	state = {
		departments: [],
		warehouses: [],
		containers: [],
		colors: [],
		shapes: [],
		form: {
			department_id: '',
			warehouse_id: ''
		},
		items: [],
		selected_container: null,
		selected_color: null,
		selected_form: null,
		selected: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Almacén',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Almacén / Ubicación física'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await DesignService.admin.get();
		this.setState({
			departments: res.departments || [],
			colors: res.colors || [],
			shapes: res.shapes || []
		});
	}

	loadWarehouses = async() => {
		await this.setState({
			form: {
				...this.state.form,
				warehouse_id: ''
			},
			items: []
		});
		const res: any = await DesignService.admin.getWarehouses({
			department_id: this.state.form.department_id
		});
		this.setState({
			warehouses: res.warehouses || [],
		});
	}

	loadContainers = async() => {
		await this.setState({
			items: []
		});
		const res: any = await DesignService.admin.getContainers({
			warehouse_id: this.state.form.warehouse_id
		});
		this.setState({
			containers: res.containers || [],
			items: res.design.filter((i: any) => res.containers.map((i: any) => i.id).indexOf(i.container_id) != -1).map((item: any) => {
				const color: any = this.state.colors.find((i: any) => i.id == item.color_id);

				return {
					container_id: item.container_id,
					top: item.top,
					left: item.left,
					rotation: item.rotation,
					color: color?.value,
					shape_id: item.shape_id
				}
			})
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	add = (shape: any,item: any) => {
		if (this.state.items.find((i: any) => i.container_id == item.id)) {
			Globals.showError("La unidad de almacenamiento ya se encuentra en el diagrama");
			return false;
		}
		let items: any = [...this.state.items];
		items.push({
			container_id: item.id,
			top: 0,
			left: 0,
			rotation: 0,
			color: null,
			shape_id: shape.id
		});
		this.setState({
			items
		});
	}

	getShapeClass = (shape_id: number) => {
		let value = '';

		switch(shape_id) {
			case Constants.SHAPES.CUADRADO:
				value = 'cuadrado';
			break;

			case Constants.SHAPES.RECTANGULO:
				value = 'rectangulo';
			break;

			case Constants.SHAPES.REDONDEADO:
				value = 'redondeado';
			break;

			case Constants.SHAPES.OVALO:
				value = 'ovalo';
			break;
		}

		return value;
	}

	getShapeHeight = (shape_id: number) => {
		let value = '';

		switch(shape_id) {
			case Constants.SHAPES.CUADRADO:
				value = '120px';
			break;

			case Constants.SHAPES.RECTANGULO:
				value = '50px';
			break;

			case Constants.SHAPES.REDONDEADO:
				value = '120px';
			break;

			case Constants.SHAPES.OVALO:
				value = '120px';
			break;
		}

		return value;
	}

	getShapeWidth = (shape_id: number) => {
		let value = '';

		switch(shape_id) {
			case Constants.SHAPES.CUADRADO:
				value = '120px';
			break;

			case Constants.SHAPES.RECTANGULO:
				value = '120px';
			break;

			case Constants.SHAPES.REDONDEADO:
				value = '120px';
			break;

			case Constants.SHAPES.OVALO:
				value = '100px';
			break;
		}

		return value;
	}

	shape = (item: any,index: number) => {
		this.setState({
			selected_form: this.state.selected_form == index ? null : index,
			selected_color: null
		});
	}

	rotate = (item: any,index: number) => {
		let items: any = [...this.state.items];
		items[index].rotation = items[index].rotation + 90;
		if (items[index].rotation > 270) {
			items[index].rotation = 0;
		} 
		this.setState({
			items,
			selected_color: null,
			selected_form: null
		});
	}

	color = (item: any,index: number) => {
		this.setState({
			selected_color: this.state.selected_color == index ? null : index,
			selected_form: null
		});
	}

	selectColor = (index: number, color: string) => {
		let items: any = [...this.state.items];
		items[index].color = color;
		this.setState({
			items,
			selected_color: null,
			selected_form: null
		});
	}

	changeForm = (shape_id: number, index: number) => {
		let items: any = [...this.state.items];
		items[index].shape_id = shape_id;
		this.setState({
			items,
			selected_form: null,
			selected_color: null
		});
	}

	delete = (item: any,index: number) => {
		let items = [...this.state.items];
		items.splice(index,1);
		this.setState({
			items,
			selected_color: null,
			selected_form: null,
			selected: null
		});
	}

	save = async () => {
		await DesignService.admin.save({
			items: this.state.items.map((item: any) => {
				const color: any = this.state.colors.find((i: any) => i.value == item.color);
				item.color_id = color?.id;
				return item;
			}),
			warehouse_id: this.state.form.warehouse_id
		});
		Globals.showSuccess("Se ha guardado correctamente");
	}
	
	render() {
		return (
			<React.Fragment>
				<div id="storage-design-message">
					<p>Lo sentimos, el módulo de diseño no está disponible en un dispositivo móvil</p>
				</div>

				<div id="storage-design">
					<div className="container-black">
						<div className="row row-selects">
							<div className="col-lg-4">
								<Select
									name="department_id"
									onChange={ (e: any) => this.change(e,() => {
										this.loadWarehouses();
									}) }
									placeholder="Departamento"
									value={ this.state.form.department_id }
									options={ this.state.departments.map((i: any) => {
										return {
											value: i.id,
											label: i.name
										}
									}) } />
							</div>
							<div className="col-lg-4">
								{
									this.state.form.department_id && (
										<Select
											name="warehouse_id"
											onChange={ (e: any) => this.change(e,() => {
												this.loadContainers();
											}) }
											placeholder="Almacén"
											value={ this.state.form.warehouse_id }
											options={ this.state.warehouses.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) } />
									)
								}
							</div>
							<div className="col-lg-4">
								{
									this.state.form.warehouse_id && (
										<Button className="btn-submit" onClick={ this.save }>
											Guardar
										</Button>
									)
								}
							</div>
						</div>
						<div className="containers-scroll">
							{
								this.state.form.warehouse_id && this.state.containers.map((item: any) => {
									const _item: any = this.state.items.find((i: any) => i.container_id == item.id);
									let className = '';

									if (_item) {
										className = this.getShapeClass(_item.shape_id);
									}

									return (
										<button className={ `item-scroll ${ this.state.selected_container == item.id ? 'active' : '' }`} 
											onClick={ () => {
												this.setState({
													selected_container: this.state.selected_container == item.id ? null : item.id
												});
											} }
											onBlur={ () => {
												this.setState({
													selected_container: null
												});
											} }>
											<div className="container-text">
												<div className={ `container-shape-selected ${ _item ? 'active' : '' }` }>
													{
														_item && (
															<div 
																className={ className }
																style={{
																	backgroundColor: _item?.color
																}}
															></div>
														)
													}
												</div>
												<p>{ item.name }</p>
											</div>
											{
												this.state.selected_container == item.id && (
													<div className="container-shapes">
														<div className="row">
															{
																this.state.shapes.map((shape: any) => {
																	const className = this.getShapeClass(shape.id);

																	return (
																		<div className="col-6">
																			<div className="container-shape" onClick={ () => this.add(shape,item) }>
																				<div className={ className }></div>
																			</div>
																		</div>
																	)
																})
															}
														</div>
													</div>
												)
											}
										</button>
									)
								})
							}
						</div>
						<div className="container-design">
							{
								this.state.items.map((item: any, index: number) => {
									const className = this.getShapeClass(item.shape_id);
									const width = this.getShapeWidth(item.shape_id);
									const height = this.getShapeHeight(item.shape_id);
									const container: any = this.state.containers.find((i: any) => i.id == item.container_id);

									return (
										<Draggable bounds="parent" defaultPosition={{x: item.left, y: item.top}} position={{x: item.left, y: item.top}} onStop={ (event,data) => {
											item.top = data.y;
											item.left = data.x;
										} }>
											<button style={{
												position: 'absolute',
												zIndex: this.state.selected == index ? 999 : 0
											}} className="focusable-container" onFocus={ () => {
												this.setState({
													selected: index
												});
											} } onBlur={ () => {
												let items = [...this.state.items];
												items.map((i: any,_index: number) => {
													i.active = false;
													return i;
												});
												this.setState({
													selected: null,
													items
												});
											} }>
												<div className="container-rotation">
													<div className={ `container-item ${ className }` }
														style={{
															width: (item.rotation == 90 || item.rotation == 270) ? height : width,
															height: (item.rotation == 90 || item.rotation == 270) ? width : height,
															backgroundColor: item.color || DEFAULT_COLOR,
															borderColor: this.state.selected == index ? Colors.green : (item.color || DEFAULT_COLOR),
															// transform: `rotate(${ item.rotation }deg)`
														}}
														onClick={ () => {
															let items = [...this.state.items];
															items.map((i: any,_index: number) => {
																i.active = false;
																if (index == _index) {
																	i.active = true;
																}
																return i;
															});
															this.setState({
																items
															});
														} }
													>
														<p style={{ transform: `rotate(${ item.rotation }deg)` }}>{ container?.name }</p>
													</div>
													{
														item.active && (
															<div className="icons-container">
																<div className="icon-item" onClick={ () => this.shape(item,index) }>
																	<img src={ ShapeIcon } />
																</div>
																<div className="icon-item" onClick={ () => this.color(item,index) }>
																	<img src={ ColorIcon } />
																</div>
																<div className="icon-item" onClick={ () => this.rotate(item,index) }>
																	<img src={ RotationIcon } />
																</div>
																<div className="icon-item last-item" onClick={ () => this.delete(item,index) }>
																	<img src={ DeleteIcon } />
																</div>

																{
																	this.state.selected_color == index && (
																		<div className="colors-container">
																			<div className="row">
																				{
																					this.state.colors.map((i: any) => {
																						return (
																							<div className="col-3">
																								<div className="item-color" 
																									onClick={ () => this.selectColor(index,i.value) }
																									style={{
																										backgroundColor: i.value
																									}}
																								></div>
																							</div>
																						)
																					})
																				}
																			</div>
																		</div>
																	)
																}
																{
																	this.state.selected_form == index && (
																		<div className="container-shapes">
																			<div className="row">
																				{
																					this.state.shapes.map((item: any) => {
																						const className = this.getShapeClass(item.id);

																						return (
																							<div className="col-6">
																								<div className="container-shape" onClick={ () => this.changeForm(item.id,index) }>
																									<div className={ className }></div>
																								</div>
																							</div>
																						)
																					})
																				}
																			</div>
																		</div>
																	)
																}
															</div>
														)
													}
												</div>
											</button>
										</Draggable>
									)
								})
							}
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default connect()(StorageDesign);