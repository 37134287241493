import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  useHistory 
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";
import { RootState } from "reducers";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Public
import Home from 'screens/home';

// Auth
import Login from "screens/auth/login/login";
import Recover from "screens/auth/login/recover";
import Reset from "screens/auth/login/reset";
import Profile from "screens/profile";
import Password from "screens/password";

// Admin Home
import Admin from 'screens/admin/home';
import AdminHome from 'screens/admin/home/home';

// Admin Orders
import AdminOrders from 'screens/admin/orders/orders';
import AdminCreateOrder from 'screens/admin/orders/create';

// Admin Products
import AdminProducts from 'screens/admin/products/products';
import AdminCreateProduct from 'screens/admin/products/create';

// Admin Providers
import AdminProviders from 'screens/admin/providers/providers';
import AdminCreateProviders from 'screens/admin/providers/create';

// Admin Reports
import AdminReports from 'screens/admin/reports/reports';
import AdminReportsControlBar from 'screens/admin/reports/control-bar';
import AdminReportsProductRequisitions from 'screens/admin/reports/product-requisitions';
import AdminReportsCristalInventory from 'screens/admin/reports/cristal-inventory';

// Admin Storage
import AdminStorage from 'screens/admin/storage/storage';
import AdminStorageWarehouse from 'screens/admin/storage/warehouse';
import AdminStorageDesign from 'screens/admin/storage/design';

// Admin Users
import AdminUsers from 'screens/admin/users/users';
import AdminCreateUser from 'screens/admin/users/create';

// Admin Works
import AdminWorks from 'screens/admin/works/works';
import AdminCreateWork from 'screens/admin/works/create';

// Admin Master
import AdminMaster from 'screens/admin/master/master';

// Admin Departments
import AdminDeparments from 'screens/admin/master/departments/departments';

// Admin Brands
import AdminBrands from 'screens/admin/master/brands/brands';

// Admin Categories
import AdminCategories from 'screens/admin/master/categories/categories';

// Admin Checklist
import AdminChecklist from 'screens/admin/checklist/checklist';
import AdminCreateChecklist from 'screens/admin/checklist/create';

// Admin Host
import AdminHost from 'screens/admin/host/host';
import AdminHostBoards from 'screens/admin/host/boards';
import AdminHostAreas from 'screens/admin/host/areas';

// Admin Reporte Delivery
import AdminReportDelivery from 'screens/admin/reports/delivery';

// Admin Reporte Errores
import AdminReportErrors from 'screens/admin/reports/host-errors';

// Admin Reporte Control Salida
import AdminReportControlOut from 'screens/admin/reports/control-out';

// Admin Reporte Ingreso Clientes
import AdminReportAddClients from 'screens/admin/reports/add-clients';

// Admin Reporte Control de Cobro
import AdminReportControlPayments from 'screens/admin/reports/control-payments';

// Admin Reporte Recepción y faltante
import AdminReportReception from 'screens/admin/reports/reception';

// Admin Reporte Tareas Asignadas
import AdminReportWorks from 'screens/admin/reports/works';

// Admin Reporte Checklist
import AdminReportChecklist from 'screens/admin/reports/checklist';

// Admin Reporte Decrease
import AdminReportDecrease from 'screens/admin/reports/decrease';

// Admin Location
import AdminReportLocation from 'screens/admin/reports/location';

// Admin Reporte de Recetas
import AdminReportRecipes from 'screens/admin/reports/recipes';

// Admin Reporte de Porcionado
import AdminReportPortioning from 'screens/admin/reports/portioning';

// Admin Reporte de Perdida
import AdminReportLoss from 'screens/admin/reports/loss';

// Admin Reporte de Diario de Operaciones
import AdminReportOperations from 'screens/admin/reports/operations';

// Admin Reporte de Cumplimiento
import AdminReportCompliance from 'screens/admin/reports/compliance';

// Admin Production
import AdminProduction from 'screens/admin/production/production';
import AdminProductionConditioned from 'screens/admin/production/conditioned/conditioned';
import AdminProductionCreateConditioned from 'screens/admin/production/conditioned/create';
import AdminProductionPlans from 'screens/admin/production/plans/plans';
import AdminProductionCreatePlan from 'screens/admin/production/plans/create';
import AdminProductionRecipes from 'screens/admin/production/recipes/recipes';
import AdminProductionCreateRecipe from 'screens/admin/production/recipes/create';
import AdminProductionCreateOperation from 'screens/admin/production/operations/create';
import AdminProductionOperations from 'screens/admin/production/operations/operations';
import AdminProductionDishes from 'screens/admin/production/dishes/dishes';
import AdminProductionCreateDishes from 'screens/admin/production/dishes/create';

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  return (
    <React.Fragment>
      <Switch>
        {/* Public */}
        <Route exact path="/" component={ Home } />

        {/* Auth */}
        <Route exact noAuth path="/login" component={ Login } />
        <Route exact auth path="/profile" component={ Profile } />
        <Route exact auth path="/password" component={ Password } />
        <Route exact noAuth path="/recover" component={ Recover } />
        <Route exact noAuth path="/recover/:code" component={ Reset } />

        {/* Admin */}

        {/* Home */}
        <Route exact admin path="/admin" component={ Admin } />  
        <Route exact admin path="/admin/home" component={ AdminHome } />  

        {/* Master */}
        <Route exact admin path="/admin/master" component={ AdminMaster } />  

        {/* Brands */}
        <Route exact admin path="/admin/master/brands" component={ AdminBrands } />  

        {/* Departments */}
        <Route exact admin path="/admin/master/departments" component={ AdminDeparments } />  

        {/* Categories */}
        <Route exact admin path="/admin/master/categories" component={ AdminCategories } />  

        {/* Orders */}
        <Route exact admin path="/admin/orders" component={ AdminOrders } /> 
        <Route exact admin path="/admin/orders/create" component={ AdminCreateOrder } /> 
        <Route exact admin path="/admin/orders/edit/:order_id" component={ AdminCreateOrder } /> 

        {/* Products */}
        <Route exact admin path="/admin/products" component={ AdminProducts } />  
        <Route exact admin path="/admin/products/create" component={ AdminCreateProduct } /> 
        <Route exact admin path="/admin/products/edit/:product_id" component={ AdminCreateProduct } />   

        {/* Providers */}
        <Route exact admin path="/admin/providers" component={ AdminProviders } /> 
        <Route exact admin path="/admin/providers/create" component={ AdminCreateProviders } />   
        <Route exact admin path="/admin/providers/edit/:provider_id" component={ AdminCreateProviders } />   

        {/* Reports */}
        <Route exact admin path="/admin/reports" component={ AdminReports } /> 
        <Route exact admin path="/admin/reports/control-bar" component={ AdminReportsControlBar } />
        <Route exact admin path="/admin/reports/product-requisitions" component={ AdminReportsProductRequisitions } /> 
        <Route exact admin path="/admin/reports/cristal-inventory" component={ AdminReportsCristalInventory } /> 
        <Route exact admin path="/admin/reports/reception" component={ AdminReportReception } /> 
        <Route exact admin path="/admin/reports/host-control-out" component={ AdminReportControlOut } /> 
        <Route exact admin path="/admin/reports/host-control-payments" component={ AdminReportControlPayments } /> 
        <Route exact admin path="/admin/reports/host-add-clients" component={ AdminReportAddClients } /> 
        <Route exact admin path="/admin/reports/host-errors" component={ AdminReportErrors } /> 
        <Route exact admin path="/admin/reports/host-delivery" component={ AdminReportDelivery } /> 
        <Route exact admin path="/admin/reports/works" component={ AdminReportWorks } /> 
        <Route exact admin path="/admin/reports/checklist" component={ AdminReportChecklist } /> 
        <Route exact admin path="/admin/reports/decrease" component={ AdminReportDecrease } /> 
        <Route exact admin path="/admin/reports/location" component={ AdminReportLocation } />
        <Route exact admin path="/admin/reports/recipes" component={ AdminReportRecipes } /> 
        <Route exact admin path="/admin/reports/portioning" component={ AdminReportPortioning } /> 
        <Route exact admin path="/admin/reports/loss" component={ AdminReportLoss } /> 
        <Route exact admin path="/admin/reports/operations" component={ AdminReportOperations } /> 
        <Route exact admin path="/admin/reports/compliance" component={ AdminReportCompliance } /> 

        {/* Storage */}
        <Route exact admin path="/admin/storage" component={ AdminStorage } />
        <Route exact admin path="/admin/storage/design" component={ AdminStorageDesign } />
        <Route exact admin path="/admin/storage/warehouse/create" component={ AdminStorageWarehouse } />
        <Route exact admin path="/admin/storage/warehouse/edit/:warehouse_id" component={ AdminStorageWarehouse } />   

        {/* Users */}
        <Route exact admin path="/admin/users" component={ AdminUsers } />
        <Route exact admin path="/admin/users/create" component={ AdminCreateUser } /> 
        <Route exact admin path="/admin/users/edit/:user_id" component={ AdminCreateUser } />  

        {/* Works */}
        <Route exact admin path="/admin/works" component={ AdminWorks } /> 
        <Route exact admin path="/admin/works/create" component={ AdminCreateWork } />

        {/* Checklist */}
        <Route exact admin path="/admin/checklist" component={ AdminChecklist } /> 
        <Route exact admin path="/admin/checklist/create" component={ AdminCreateChecklist } />
        <Route exact admin path="/admin/checklist/edit/:checklist_id" component={ AdminCreateChecklist } />

        {/* Host */}
        <Route exact admin path="/admin/host" component={ AdminHost } />
        <Route exact admin path="/admin/host/boards" component={ AdminHostBoards } />
        <Route exact admin path="/admin/host/areas" component={ AdminHostAreas } /> 

        {/* Production */}
        <Route exact admin path="/admin/production" component={ AdminProduction } />
        <Route exact admin path="/admin/production/conditioned" component={ AdminProductionConditioned } />
        <Route exact admin path="/admin/production/dishes" component={ AdminProductionDishes } />
        <Route exact admin path="/admin/production/operations" component={ AdminProductionOperations } />
        <Route exact admin path="/admin/production/plans" component={ AdminProductionPlans } />
        <Route exact admin path="/admin/production/plans/create" component={ AdminProductionCreatePlan } />
        <Route exact admin path="/admin/production/recipes" component={ AdminProductionRecipes } />
        <Route exact admin path="/admin/production/recipes/create" component={ AdminProductionCreateRecipe } />
        <Route exact admin path="/admin/production/recipes/edit/:recipe_id" component={ AdminProductionCreateRecipe } />
        <Route exact admin path="/admin/production/operations/create" component={ AdminProductionCreateOperation } />
        <Route exact admin path="/admin/production/operations/edit/:operation_id" component={ AdminProductionCreateOperation } />
        <Route exact admin path="/admin/production/dishes/create" component={ AdminProductionCreateDishes } />
        <Route exact admin path="/admin/production/dishes/edit/:technique_id" component={ AdminProductionCreateDishes } />
        <Route exact admin path="/admin/production/conditioned/create" component={ AdminProductionCreateConditioned } />
        <Route exact admin path="/admin/production/conditioned/edit/:conditioned_id" component={ AdminProductionCreateConditioned } />

        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
