import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Globals, Constants, Permissions } from 'utils';
import Header from './header';
import $ from 'jquery';
import { LogoWhite as Logo } from 'assets/img';
import { Menu as MenuIcons, ArrowUp, ArrowBottom } from 'assets/icons';

interface MenuItem {
	name: string;
	path: string;
	submenu?: MenuItem[];
	icon?: string;
	permission?: string;
	onClick?: () => void;
	iconActive?: string;
	code: string;
	isLogout?: boolean;
}

class Menu extends React.Component<any> {

	state: any = {
		leftOpen: true,
		openResponsive: false,
		items: [
			{ name: 'Inicio', path: '/admin/home', icon: MenuIcons.MenuHome, iconActive: MenuIcons.MenuHomeWhite },
			{ name: 'Usuarios', path: '/admin/users', icon: MenuIcons.MenuUsers, iconActive: MenuIcons.MenuUsersWhite },
			{ name: 'Proveedores', path: '/admin/providers', icon: MenuIcons.MenuProviders, iconActive: MenuIcons.MenuProvidersWhite },
			{ name: 'Almacén', path: '/admin/storage', icon: MenuIcons.MenuStorage, iconActive: MenuIcons.MenuStorageWhite, submenu: [
				{ name: 'Gestión de Almacén', icon: MenuIcons.MenuStorageWhite, path: '/admin/storage' },
				{ name: 'Ubicación física', icon: MenuIcons.MenuDesignWhite, path: '/admin/storage/design' },
			] },
			{ name: 'Maestro', path: '/admin/master', icon: MenuIcons.MenuMaster, iconActive: MenuIcons.MenuMasterWhite, submenu: [
				{ name: 'Departamentos', icon: MenuIcons.MenuDepartmentsWhite, path: '/admin/master/departments' },
				{ name: 'Categoría', icon: MenuIcons.MenuCategoriesWhite, path: '/admin/master/categories' },
				{ name: 'Marcas', icon: MenuIcons.MenuBrandsWhite, path: '/admin/master/brands' },
			] },
			{ name: 'Producto', path: '/admin/products', icon: MenuIcons.MenuProducts, iconActive: MenuIcons.MenuProductsWhite },
			{ name: 'Producción', path: '/admin/production', icon: MenuIcons.MenuProduction, iconActive: MenuIcons.MenuProductionWhite, submenu: [
				{ name: 'Operaciones', icon: MenuIcons.MenuProductionWhite, path: '/admin/production/operations' },
				{ name: 'Acondicionado', icon: MenuIcons.MenuConditionedWhite, path: '/admin/production/conditioned' },
				{ name: 'Recetas', icon: MenuIcons.MenuRecipesWhite, path: '/admin/production/recipes' },
				{ name: 'Plato o técnica', icon: MenuIcons.MenuDishesWhite, path: '/admin/production/dishes' },
				{ name: 'Plan de producción', icon: MenuIcons.MenuPlansWhite, path: '/admin/production/plans' },
			] },
			{ name: 'Ordenes de Compra', path: '/admin/orders', icon: MenuIcons.MenuOrders, iconActive: MenuIcons.MenuOrdersWhite },
			{ name: 'Asignación de Tareas', path: '/admin/works', icon: MenuIcons.MenuWorks, iconActive: MenuIcons.MenuWorksWhite },
			{ name: 'Reportes', path: '/admin/reports', icon: MenuIcons.MenuReports, iconActive: MenuIcons.MenuReportsWhite },
			{ name: 'Checklist', path: '/admin/checklist', icon: MenuIcons.MenuChecklist, iconActive: MenuIcons.MenuChecklistWhite },
			{ name: 'Host', path: '/admin/host', icon: MenuIcons.MenuHost, iconActive: MenuIcons.MenuHostWhite, submenu: [
				{ name: 'Mesas', icon: MenuIcons.MenuBoardWhite, path: '/admin/host/boards' },
				{ name: 'Áreas', icon: MenuIcons.MenuAreaWhite, path: '/admin/host/areas' },
			] },
		],
		items_client: [

		]
	}

	toggleSidebar = () => {
	    let key: any = `leftOpen`;
	    // @ts-ignore
	    this.setState({ [key]: !this.state[key] });
	}

	checkActive = (value: string, current: string) => {
		return current.includes(value);
	}

	changeMenu = (item: MenuItem) => {
		if (item.submenu && !this.state.leftOpen) {
			this.toggleSidebar();
		}
	}

	checkPermission = (item: any) => {
		const permissions = this.props.user?.permissions.map((i: any) => i.code);
		if (Array.isArray(item.permission)) {
			let _continue = false;
			item.permission.forEach((i: any) => {
				if (permissions.indexOf(i) != -1) {
					_continue = true;
				}
			});
			return _continue;
		}
		else {
			return permissions.indexOf(item.permission) != -1;
		}		
	}

	closeNavbar = () => {
		// @ts-ignore
		$('#collapsibleNavbar').collapse('hide');
	}

	toggleResponsive = () => {
		this.setState({
	  		openResponsive: !this.state.openResponsive
	  	});
	}

	closeResponsive = () => {
		this.setState({
	  		openResponsive: !this.state.openResponsive
	  	});
	}

	render() {
		let leftOpen = !this.props.isAuth && this.state.leftOpen ? "open" : "closed";
		let items = [];

		if (this.props.user) {
			const isClient = this.props.user?.level_id != Constants.LEVELS.ADMIN || (this.props.user?.level_id == Constants.LEVELS.MODERATOR && this.props.user?.user_id);

			if (isClient) {
				items = this.state.items_client;
			}
			else {
				items = this.state.items;
			}
		}

		return (
			<React.Fragment>
				{
					items.length > 0 && (
						<nav className="navbar navbar-expand-md bg-dark navbar-dark fixed-top justify-content-start">
						  <button className="navbar-toggler" type="button" onClick={ this.toggleResponsive }>
						    <i className="fa fa-bars" />
						  </button>
					    </nav>
					)
				}

				<div id="left" className={ leftOpen }>
					<div className={`sidebar ${ leftOpen + ' ' + (this.state.openResponsive ? 'open-responsive' : '') }`}>
						<div className={ `shadow-close ${ this.state.openResponsive ? 'open' : '' }` } onClick={ this.closeResponsive }></div>
						<div className="container-menu">
							<div className="menu">
								<div className={ `container-logo ${ leftOpen }` }>
									<Link to="/profile">
										<img src={ Logo } />
									</Link>
								</div>

								<div className="bg-orange"></div>

								<div className="container-scroll">
									<ul className={ leftOpen }>
										{ items.map((i: MenuItem,index: number) => {
											return (
												<React.Fragment key={ index }>
													{
														(!i.permission || this.checkPermission(i)) && (
															<li className="nav-item">
																<Link to={ i.path } className={ `${ i.submenu && i.submenu.length > 0 ? 'contain-submenu' : '' }` } onClick={ (e: any) => {
																	if (i.onClick) {
																		e.preventDefault();
																		i.onClick();
																	}
																	else {
																		if (i.submenu && i.submenu.length > 0 && this.checkActive(i.path,this.props.history.location.pathname) && this.props.open_menu) {
																			this.props.dispatch({
																				type: 'SET_OPEN_MENU',
																				payload: false
																			});
																			e.preventDefault();
																		}
																		else {
																			this.props.dispatch({
																				type: 'SET_OPEN_MENU',
																				payload: true
																			});
																			this.changeMenu(i);
																			this.closeResponsive();
																		}
																	}																	
																} }>
																	<div className={ `container-icon-menu ${ this.checkActive(i.path,this.props.history.location.pathname) ? 'active' : '' }` }>
																		{
																			i.icon && (
																				<img src={ this.checkActive(i.path,this.props.history.location.pathname) ? i.iconActive : i.icon } />
																			)
																		}
																	</div>												
																	<span className={ leftOpen }>
																		{ i.name }
																		{
																			i.submenu && i.submenu.length > 0 && (
																				<React.Fragment>
																					{
																						this.checkActive(i.path,this.props.history.location.pathname) ? (
																							<img className="arrow-drop" src={ this.props.open_menu ? ArrowUp : ArrowBottom } />
																						) : (
																							<img className="arrow-drop" src={ ArrowBottom } />
																						)
																					}
																				</React.Fragment>
																			)
																		}
																	</span>
																</Link>
																{
																	this.props.open_menu && (
																		<div className={ `${ i.submenu && i.submenu.length > 0 && this.checkActive(i.path,this.props.history.location.pathname) ? 'container-submenu' : '' }` }>
																			{
																				leftOpen != 'closed' && this.checkActive(i.path,this.props.history.location.pathname) && i.submenu && i.submenu.map((item: MenuItem,index: number) => {
																					if (!item.permission || this.checkPermission(item)) {
																						return (
																							<li className={ 'nav-item item-submenu ' + (this.props.history.location.pathname == item.path ? 'active' : '') }>
																								<Link to={ item.path } className="submenu">
																									{
																										item.icon && (
																											<img src={ item.icon } />
																										)
																									}
																									{ item.name }
																								</Link>
																							</li>
																						)
																					}
																				})
																			}
																		</div>
																	)
																}
															</li>
														)
													}												
												</React.Fragment>
											)
										}) }
									</ul>
								</div>
							</div>
						</div>
						<div className={ `container-router ${ leftOpen }` }>
							<Header className={ leftOpen + ' ' + (items.length > 0 && 'header-responsive') } />
							<div className={ `container-padding-router ${ items.length > 0 && 'container-responsive' }` }>{ this.props.children }</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
		open_menu: state.open_menu
	}
})(Menu);