import { Api } from 'utils';

class ConditionedService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/conditioned/get',params),
		create: async (params?: any) => Api.createResponse('admin/conditioned/create',params),
		update: async (params?: any) => Api.createResponse('admin/conditioned/update',params),
		delete: async (params?: any) => Api.createResponse('admin/conditioned/delete',params),
		view: async (params?: any) => Api.createResponse('admin/conditioned/view',params),
		getData: async (params?: any) => Api.createResponse('admin/conditioned/get-data',params),
	}

}

export default ConditionedService;