import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import { Globals, Constants } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	board_id: '',
	date: '',
	search: ''
}

class ControlPayments extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Fecha',
			'Mesa',
			'Host',
			'Nº Orden',
			'Monto de Factura',
			'Servicio',
			'Fiscal',
			'Total',
			'Tipo de Pago',
			'Propina',
			'Propina Adicional',
			'Observaciones'
		],
		form: INITIAL_STATE,
		boards: [],
		currencies: [],
		total: 0,
		subtotal: 0,
		total_tip: 0,
		total_extra_tip: 0,
		total_bs: 0,
		subtotal_bs: 0,
		total_tip_bs: 0,
		total_extra_tip_bs: 0,
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Reporte Control de Cobro'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReportService.admin.controlPayments({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.payments.rows,
			last_page: res.payments.count,
			boards: res.boards,
			currencies: res.currencies,
			total: res.total || 0,
			total_tip: res.total_tip || 0,
			total_extra_tip: res.total_extra_tip || 0,
			subtotal: res.subtotal || 0,
			total_bs: res.total_bs || 0,
			total_tip_bs: res.total_tip_bs || 0,
			total_extra_tip_bs: res.total_extra_tip_bs || 0,
			subtotal_bs: res.subtotal_bs || 0
		},() => callback());
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		const { 
			total,
			subtotal, 
			total_tip, 
			total_extra_tip,
			total_bs,
			subtotal_bs, 
			total_tip_bs, 
			total_extra_tip_bs 
		} = this.state;

		return (
			<div id="reports-control-payments">
				<div className="row">
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por host"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<DatePicker
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},this.load);
							} }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-4">
						<Select
							name="board_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Mesa"
							value={ this.state.form.board_id }
							options={ this.state.boards.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="text-right">
					<div className="container-total">
						<p><strong>Sub-Total:</strong> { Globals.formatMiles(subtotal,true,Constants.CURRENCIES.DOLAR) } | { Globals.formatMiles(subtotal_bs,true,Constants.CURRENCIES.BOLIVAR) }</p>
						<p><strong>Total:</strong> { Globals.formatMiles(total,true,Constants.CURRENCIES.DOLAR) } | { Globals.formatMiles(total_bs,true,Constants.CURRENCIES.BOLIVAR) }</p>
						<p><strong>Total Propinas:</strong> { Globals.formatMiles(total_tip,true,Constants.CURRENCIES.DOLAR) } | { Globals.formatMiles(total_tip_bs,true,Constants.CURRENCIES.BOLIVAR) }</p>
						<p><strong>Total Propinas (Adicionales):</strong> { Globals.formatMiles(total_extra_tip,true,Constants.CURRENCIES.DOLAR) } | { Globals.formatMiles(total_extra_tip_bs,true,Constants.CURRENCIES.BOLIVAR) }</p>
					</div>
				</div>

				<Table title="Reporte Control de Cobro" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						const currency: any = this.state.currencies.find((item: any) => item.id == i.currency_id);
						const tip_currency: any = this.state.currencies.find((item: any) => item.id == i.tip_currency_id);

						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY hh:mm A') }</td>
								<td>{ i.board?.name }</td>
								<td>{ i.user ? (i.user?.name + ' ' + i.user?.lastname) : '' }</td>
								<td>{ i.order_number }</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ Globals.formatMiles(i.amount,true,currency?.symbol) }</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ Globals.formatMiles(i.service,true,currency?.symbol) }</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ Globals.formatMiles(i.iva,true,currency?.symbol) }</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ Globals.formatMiles(i.total,true,currency?.symbol) }</td>
								<td>{ i.payment_type?.name }</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ Globals.formatMiles(i.tip,true,tip_currency?.symbol) }</td>
								<td style={{ whiteSpace: 'nowrap' }}>{ Globals.formatMiles(i.extra_tip,true,tip_currency?.symbol) }</td>
								<td>{ i.comments }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(ControlPayments);