import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select, DownloadButton } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import { Constants, Print } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	phase_id: '',
	date: ''
}

class ControlBar extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Producto',
			'Categoría',
			'Inventario físico',
			'Stock Mínimo',
			'Tipo de Inventario',
			'Fecha',
			'Responsable',
			'Observaciones'	
		],
		form: INITIAL_STATE,
		phases: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Inventario (Control de Barra)'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.controlBar({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.changes.rows,
			last_page: res.changes.count,
			phases: res.phases
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	download = async () => {
		const res: any = await ReportService.admin.exportControlBar({
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		Print(Constants.BASE_STORAGE + res.url);
	}
	
	render() {
		return (
			<div id="reports-control-bar">
				<div className="row">
					<div className="col-md-6">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por trabajador"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},this.load);
							} }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-3">
						<Select
							name="phase_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Tipo de Inventario"
							value={ this.state.form.phase_id }
							options={ this.state.phases.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="text-right" style={{ marginTop: '20px' }}>
					<DownloadButton onClick={ () => this.download() } />
				</div>

				<Table title="Inventario (Control de Barra)" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.inventory?.product?.name }</td>
								<td>{ i?.inventory?.product?.category?.name }</td>
								<td>{ i?.inventory?.quantity }</td>
								<td>{ i?.inventory?.product?.minimum_stock }</td>
								<td>{ i?.phase?.name }</td>
								<td>{ moment(i.created_at).format('DD-MM-YYYY') }</td>
								<td>{ i?.user ? (i?.user?.name + ' ' + i?.user?.lastname) : '' }</td>
								<td>{ i?.comment?.comments || '-' }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(ControlBar);