import React from 'react';
import { Button } from 'components';
import { ViewUserImage } from 'assets/img';
import { MailIcon } from 'assets/icons';

interface Props {
	onClose: () => void;
	user: any;
}

class ModalViewUser extends React.Component<Props> {

	render() {
		const { user } = this.props;

		return (
			<div id="modal-view-user">
				<div className="row">
					<div className="col-md-6">
						<div className="email-container">
							<img src={ MailIcon } className="email-icon" />
							<p>{ user?.email }</p>
						</div>
						{
							user?.department && (
								<React.Fragment>
									<p className="bold">Departamento</p>
									<p>{ user?.department?.name }</p>
								</React.Fragment>
							)
						}
						<p className="bold">Usuario</p>
						<p>{ user?.username }</p>
					</div>
					<div className="col-md-6">
						<img src={ ViewUserImage } className="view-user-image" />
					</div>
				</div>
				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalViewUser;