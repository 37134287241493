import React from 'react';
import { Button } from 'components';
import { GetDay } from 'utils';

interface Props {
	onClose: (days: any) => void;
	days: any
}

class ModalDays extends React.Component<Props> {

	state = {
		days: this.props.days
	}

	render() {
		const days_inactive = Object.keys(this.state.days).filter((key: string) => !this.state.days[key]).length;

		return (
			<div id="modal-checklist-days">
				<div className="item-day" onClick={ () => {
					let days = {...this.state.days};
					if (days_inactive == 0) {
						Object.keys(days).forEach((key: string) => {
							days[key] = false;
						});
					} 
					else {
						Object.keys(days).forEach((key: string) => {
							days[key] = true;
						});
					}
					this.setState({
						days
					});
				} }>
					<div style={{ flex: .5 }}>
						<p>Todos</p>
					</div>
					<div style={{ flex: .5 }} className="text-right">
						<div className={ days_inactive == 0 ? 'item-active' : 'item-inactive' }></div>
					</div>
				</div>
				{
					Object.keys(this.state.days).map((key: string) => {
						return (
							<div className="item-day" onClick={ () => {
								let days = {...this.state.days};
								days[key] = !days[key];
								this.setState({
									days
								});
							} }>
								<div style={{ flex: .5 }}>
									<p>{ GetDay(key) }</p>
								</div>
								<div style={{ flex: .5 }} className="text-right">
									<div className={ this.state.days[key] ? 'item-active' : 'item-inactive' }></div>
								</div>
							</div>
						)
					})
				}
				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose(this.state.days) }>
						Guardar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalDays;