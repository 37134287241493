import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { ContainerRed, Input, Button, Select } from 'components';
import { WarehouseService } from 'services';
import { Globals } from 'utils';

interface MatchParams {
	warehouse_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class StorageWarehouse extends React.Component<Props> {

	state = {
		form: {
			name: '',
			department_id: ''
		},
		departments: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Almacén',
				onBack: () => {
					this.props.history.push('/admin/storage');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Almacén / ${ this.props.match.params.warehouse_id ? 'Editar' : 'Crear' } Almacén`
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await WarehouseService.admin.view({
			warehouse_id: this.props.match.params.warehouse_id
		});
		this.setState({
			departments: res.departments || []
		},() => {
			if (this.props.match.params.warehouse_id) {
				this.setState({
					form: {
						name: res.warehouse?.name || '',
						department_id: res.warehouse?.department_id || ''
					}
				});
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.match.params.warehouse_id) {
			await WarehouseService.admin.update({
				...this.state.form,
				warehouse_id: this.props.match.params.warehouse_id
			});
			Globals.showSuccess("Se ha modificado el almacén correctamente");
			this.props.history.push('/admin/storage');
		}
		else {
			const res: any = await WarehouseService.admin.create({
				...this.state.form
			});
			Globals.showSuccess("Se ha guardado el almacén correctamente");
			this.props.history.push({
				pathname: '/admin/storage',
				state: {
					warehouse_id: res.warehouse?.id
				}
			});
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="storage-warehouse">
				<ContainerRed title={ `${ this.props.match.params.warehouse_id ? 'Editar' : 'Crear' } Almacén` }>
					<form className="row" onSubmit={ this.submit }>
						<div className="col-md-8 offset-md-2">
							<Input 
								value={ this.state.form.name }
								name="name" 
								label="Nombre"
								onChange={ this.change } />
						</div>
						<div className="col-md-8 offset-md-2">
							<Select
								label="Departamento"
								name="department_id"
								onChange={ this.change }
								value={ this.state.form.department_id }
								options={ this.state.departments.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-12 text-center">
							<Button type="submit">
								Guardar
							</Button>
						</div>
						<div className="col-12 text-center">
							<Button type="button" onClick={ () => {
								this.props.history.push('/admin/storage');
							} }>
								Cancelar
							</Button>
						</div>
					</form>
				</ContainerRed>
			</div>
		)
	}
}

export default connect()(StorageWarehouse);