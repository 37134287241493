import { Api } from 'utils';

class DepartmentService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/departments/get',params),
		create: async (params?: any) => Api.createResponse('admin/departments/create',params),
		update: async (params?: any) => Api.createResponse('admin/departments/update',params),
		delete: async (params?: any) => Api.createResponse('admin/departments/delete',params),
	}

}

export default DepartmentService;