import { Api } from 'utils';

class ProfileService {
	
	static get = async (params?: any) => Api.createResponse('profile/get',params);
	static update = async (params?: any) => Api.createResponse('profile/save',params);
	static password = async (params?: any) => Api.createResponse('profile/password',params);

}

export default ProfileService;