import { Api } from 'utils';

class HomeService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/home/get',params),
	}

}

export default HomeService;