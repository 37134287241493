import React from 'react';

interface Props {
	name: string
}

const Icon = (props: Props) => (
	<i className={ `fa fa-${ props.name }` }></i>
)

export default Icon;