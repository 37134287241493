import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Select, ContainerRed, Button, Input } from 'components';
import { SelectImage } from 'assets/img';
import { HashIcon, AddWhiteIcon, TrashIcon } from 'assets/icons';
import { Constants, Globals } from 'utils';
import { WarehouseService } from 'services';

interface Props {
	onClose: () => void;
	storage: any;
	warehouse_id: number | null;
}

class CreateStorage extends React.Component<Props> {

	state = {
		form: {
			type: Constants.WAREHOUSE_TYPES.CAVA,
			name: '',
			description: '',
			code: '',
			warehouse_id: ''
		},
		levels: [{
			code: '',
			level: ''
		}],
		warehouses: []
	}

	componentDidMount() {
		if (this.props.storage)
			this.load();

		this.loadWarehouses();
	}

	loadWarehouses = async () => {
		const res: any = await WarehouseService.admin.getWarehouses({
			withoutLoading: true
		});
		this.setState({
			warehouses: res.warehouses
		},() => {
			if (this.props.warehouse_id) {
				this.setState({
					form: {
						...this.state.form,
						warehouse_id: this.props.warehouse_id
					}
				});
			}
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await WarehouseService.admin.getContainer({
			warehouse_id: this.props.storage?.id
		});
		if (res.warehouse) {
			this.setState({
				form: {
					type: res.warehouse?.type,
					name: res.warehouse?.name,
					description: res.warehouse?.description,
					code: res.warehouse?.code,
					warehouse_id: res.warehouse?.warehouse_id
				},
				levels: res.warehouse.levels.map((i: any) => {
					return {
						id: i.id,
						code: i.code,
						level: i.level
					}
				})
			});
		}
		else {
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	changeItem = (e: any,index: number) => {
		let levels: any = [...this.state.levels];
		levels[index][e.target.name] = e.target.value;
		this.setState({
			levels
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.storage?.id) {
			await WarehouseService.admin.updateContainer({
				levels: this.state.levels,
				...this.state.form,
				container_id: this.props.storage?.id
			});
			Globals.showSuccess("Se ha modificado el almacén correctamente");
			this.props.onClose();
		}
		else {
			await WarehouseService.admin.createContainer({
				levels: this.state.levels,
				...this.state.form
			});
			Globals.showSuccess("Se ha guardado el almacén correctamente");
			this.props.onClose();
		}
	}

	add = () => {
		let levels: any = [...this.state.levels];
		levels.push({
			code: '',
			level: ''
		});
		this.setState({
			levels
		});
	}

	remove = (index: number) => {
		let levels: any = [...this.state.levels];
		levels.splice(index,1);
		this.setState({
			levels
		});
	}
	
	render() {
		return (
			<div id="create-storage">
				<ContainerRed titleContainer={ 
					<div className="container-title-red">
						<p>{ this.props.storage ? 'Editar' : 'Crear' } Unidad de Almacenamiento</p>
						<a href="#" onClick={ (e: any) => {
							e.preventDefault();
							this.props.onClose();
						} }>
							<i className="fa fa-close" />
						</a>
					</div>
				}>
					{/* <div className="row">
						<div className="col-md-8 offset-md-2">
							<Select
								name="type"
								onChange={ this.change }
								placeholder="Seleccionar"
								value={ this.state.form.type }
								options={ Constants.WAREHOUSE_TYPES_SELECT.map((i: any) => {
									return {
										value: i.value,
										label: i.label
									}
								}) } />
						</div>
					</div> */}
					{
						!this.state.form.type && (
							<div className="text-center">
								<img src={ SelectImage } className="select-image" />
							</div>
						)
					}
					{
						this.state.form.type && (
							<form className="row" onSubmit={ this.submit }>
								<div className="col-md-6">
									<Input 
										value={ this.state.form.name }
										name="name" 
										label="Nombre"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input 
										value={ this.state.form.description }
										name="description" 
										label="Descripción"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<label htmlFor="code">Código</label>
									<div className="input-group">
										<div className="input-group-prepend">
											<img src={ HashIcon } />
										</div>
										<Input
											maxLength={ 8 }
											value={ this.state.form.code }
											name="code"
											onChange={ this.change } />
									</div>
								</div>
								<div className="col-md-6">
									<Select
										label="Ubicación"
										name="warehouse_id"
										onChange={ this.change }
										placeholder="Seleccionar"
										value={ this.state.form.warehouse_id }
										options={ this.state.warehouses.map((i: any) => {
											return {
												value: i.id,
												label: i.name
											}
										}) } />
								</div>
								<div className="col-12">
									<hr />
								</div>
								<div className="col-12">
									<div className="container-title-level">
										<h3>Niveles</h3>
										<Button type="button" className="add-button" onClick={ () => this.add() }>
											<img src={ AddWhiteIcon } />
										</Button>
									</div>
								</div>
								{
									this.state.levels.map((item: any,index: number) => {
										return (
											<React.Fragment>
												<div className="col-md-3">
													<label htmlFor="code">Código</label>
													<div className="input-group">
														<div className="input-group-prepend">
															<img src={ HashIcon } />
														</div>
														<Input
															value={ item.code }
															maxLength={ 8 }
															name="code"
															onChange={ (e: any) => this.changeItem(e,index) } />
													</div>
												</div>
												<div className="col-md-7">
													<Input 
														value={ item.level }
														name="level" 
														type="number"
														label="Nivel"
														onChange={ (e: any) => this.changeItem(e,index) } />
												</div>
												<div className="col-md-2">
													<Button type="button" className="remove-button" onClick={ () => this.remove(index) }>
														<img src={ TrashIcon } />
													</Button>
												</div>
											</React.Fragment>
										)
									})
								}
								
								<div className="col-12 text-center">
									<Button type="submit">
										Guardar
									</Button>
								</div>
							</form>
						)
					}

					<div className="col-12 text-center">
						<Button type="button" className="btn-cancel" onClick={ () => {
							this.props.onClose();
						} }>
							Cancelar
						</Button>
					</div>
				</ContainerRed>
			</div>
		)
	}
}

export default connect()(CreateStorage);