import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon, ViewIcon } from 'assets/icons';
import { Table, Pagination, Modal, Tooltip, Input, Select, DatePicker } from 'components';
import ModalView from './modal-view-product-requisition';
import { ReportService } from 'services';
import moment from 'moment';
import { Constants } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	status: '',
	date: '',
	urgent: ''
}

const STATUS: any = [
	{ value: Constants.STATUS_REQUISITION.ACTIVE, label: 'Pendientes' },
	{ value: Constants.STATUS_REQUISITION.FINISH, label: 'Culminadas' },
	{ value: Constants.STATUS_REQUISITION.CANCEL, label: 'Canceladas' },
];

const URGENT_OPTIONS: any = [
	{ value: Constants.URGENT.NO, label: 'Programadas' },
	{ value: Constants.URGENT.YES, label: 'Urgentes' },
];

class ProductRequisitions extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Código',
			'Responsable',
			'Área',
			'Requisición',
			'Estatus',
			'Fecha',
			'Acción'
		],
		form: INITIAL_STATE,
		visible: false,
		requisition: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Requisición de Productos'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.productRequisition({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.requisitions.rows,
			last_page: res.requisitions.count
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			requisition: null
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			requisition: item
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		const { visible } = this.state;
		const requisition: any = this.state.requisition;

		return (
			<div id="reports-product-requisitions">
				<Modal
		          className="modal-view-product-requisition"
		          visible={ visible }
		          title={
		          	<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-order">
		          				<h3>Pedro Perez</h3>
		          				<p>#00001</p>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onClose();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onClose() }
		        >
		          <ModalView
		          	requisition={ this.state.requisition }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<div className="row">
					<div className="col-md-6">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por número, departamento o responsable"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},this.load);
							} }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-3">
						<Select
							name="urgent"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Tipo de requisición"
							value={ this.state.form.urgent }
							options={ URGENT_OPTIONS } />
					</div>
					<div className="col-md-3">
						<Select
							name="status"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Estatus"
							value={ this.state.form.status }
							options={ STATUS } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<Table title="Requisición de Productos" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.number }</td>
								<td>{ i.user ? (i?.user?.name + ' ' + i?.user?.lastname) : '' }</td>
								<td>{ i.level?.name }</td>
								<td>{ i.type_text }</td>
								<td>
									<div className={ `status-container ${ i.status == Constants.STATUS_REQUISITION.ACTIVE ? 'active' : i.status == Constants.STATUS_REQUISITION.FINISH ? 'finish' : 'cancel' }` }>
										<p>{ i.status_text }</p>
									</div>
								</td>
								<td>{ moment(i?.created_at).format('DD-MM-YYYY') }</td>
								<td>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(ProductRequisitions);