import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { RecipeService } from 'services';
import { ContainerRed, Button, Input, Select, DatePicker, Tooltip, SelectSearch, Pagination, Checkbox } from 'components';
import { Globals, Constants } from 'utils';
import moment from 'moment';
import { TrashIcon } from 'assets/icons';

const { MARINATED } = Constants;

interface MatchParams {
	recipe_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class CreateRecipe extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		form: {
			department_id: '',
			name: '',
			search: '',
			percentage: '',
			time: '',
			temperature: '',
			quantity: '',
			marinated: MARINATED.NO
		},
		departments: [],
		items: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recetas',
				onBack: () => {
					this.props.history.push('/admin/production/recipes');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Recetas / ${ this.props.match.params.recipe_id ? 'Editar' : 'Crear' } receta`
			}
		});

		this.loadData();
		this.load();
	}

	loadData = async () => {
		const res: any = await RecipeService.admin.view({
			withoutLoading: true,
			recipe_id: this.props.match.params.recipe_id
		});
		this.setState({
			departments: res.departments
		},() => {
			if (res.recipe) {
				this.setState({
					form: {
						...this.state.form,
						department_id: res.recipe?.department_id || '',
						name: res.recipe?.name || '',
						percentage: res.recipe?.percentage || '',
						time: res.recipe?.time || '',
						temperature: res.recipe?.temperature || '',
						quantity: res.recipe?.quantity || '',
						marinated: res.recipe?.marinated || MARINATED.NO
					},
					items: res.recipe?.details.map((item: any) => {
						return {
							id: item.id,
							product_id: item.product_id || '',
							percentage: item.percentage || '',
							product_name: item.product?.name || ''
						}
					})
				});
			}
			else if (this.props.match.params.recipe_id) {
				this.props.history.push('/admin/production/recipes');
			}
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await RecipeService.admin.getProducts({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.products.rows,
			last_page: res.products.count
		});
	}

	reset = () => {
		this.setState({
			form: {
				...this.state.form,
				search: ''
			},
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.state.items.filter((i: any) => !i.percentage).length > 0) {
			Globals.showError("Debe ingresar el porcentaje de todos los ingredientes");
			return false;
		}
		if (this.props.match.params.recipe_id) {
			await RecipeService.admin.update({
				recipe_id: this.props.match.params.recipe_id,
				...this.state.form,
				items: this.state.items,
			});
			Globals.showSuccess("Se ha modificado la receta correctamente");
			this.props.history.push('/admin/production/recipes');
		}
		else {
			await RecipeService.admin.create({
				...this.state.form,
				items: this.state.items,
			});
			Globals.showSuccess("Se ha registrado la receta correctamente");
			this.props.history.push('/admin/production/recipes');
		}
	}

	add = (item: any) => {
		let items: any = [...this.state.items];
		items.push({
			product_id: item.id,
			percentage: '',
			product_name: item.name
		});
		this.setState({
			items,
			form: {
				...this.state.form
			}
		});
	}

	delete = (e: any, index: number) => {
		e.preventDefault();
		let items: any = [...this.state.items];
		items.splice(index,1);
		this.setState({
			items
		});
	}
	
	render() {
		return (
			<div id="create-recipe">
				<div className="row row-container">
					<div className="col-md-8 col-white">
						<div style={{ padding: '20px' }}>
							<h3>Receta</h3>
							<div className="row">
								<div className="col-md-6">
									<Input
										className="input-table"
										value={ this.state.form.name }
										name="name"
										label="Nombre"
										onChange={ (e: any) => this.change(e) } />
								</div>
								<div className="col-md-6">
									<Select
										name="department_id"
										onChange={ (e: any) => this.change(e) }
										label="Departamento"
										value={ this.state.form.department_id }
										options={ this.state.departments.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
								</div>
								<div className="col-12">
									<Checkbox
										label="Marinado"
										onChange={ () => {
											this.setState({
												form: {
													...this.state.form,
													marinated: this.state.form.marinated == MARINATED.YES ? MARINATED.NO : MARINATED.YES
												}
											});
										} }
										checked={ this.state.form.marinated == MARINATED.YES }
										inline={ true } />
								</div>
								<div className="col-md-3">
									<div className="input-container">
										<p>Porcentaje:</p>
										<Input
											type="percentage"
											value={ this.state.form.percentage }
											name="percentage"
											onChange={ (e: any) => this.change(e) } />
									</div>
								</div>
								<div className="col-md-3">
									<div className="input-container">
										<p>Tiempo:</p>
										<Input
											type="integer"
											value={ this.state.form.time }
											name="time"
											onChange={ (e: any) => this.change(e) } />
									</div>
								</div>
								<div className="col-md-3">
									<div className="input-container">
										<p>Kilogramos:</p>
										<Input
											type="decimal"
											value={ this.state.form.quantity }
											name="quantity"
											onChange={ (e: any) => this.change(e) } />
									</div>
								</div>
								<div className="col-md-3">
									<div className="input-container">
										<p>Temperatura:</p>
										<Input
											type="decimal"
											value={ this.state.form.temperature }
											name="temperature"
											onChange={ (e: any) => this.change(e) } />
									</div>
								</div>
							</div>
						</div>
						{
							this.state.items.length > 0 && (
								<React.Fragment>
									<table style={{ width: '100%' }}>
										<thead>
											<tr>
												<th style={{ width: '33%' }}>Ingrediente</th>
												<th style={{ width: '33%' }}>Porcentaje</th>
												<th style={{ width: '33%' }}></th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.items.map((item: any,index: number) => {
													return (
														<tr>
															<td style={{ width: '33%' }}>{ item.product_name }</td>
															<td style={{ width: '33%' }} className="product-percentage">
																<Input
																	type="percentage"
																	value={ item.percentage }
																	name="percentage"
																	onChange={ (e: any) => {
																		let items: any = [...this.state.items];
																		items[index].percentage = e.target.value;
																		this.setState({
																			items
																		});
																	} } />
															</td>
															<td style={{ width: '33%' }}>
																<Tooltip title="Eliminar">
																	<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,index) }>
																		<img src={ TrashIcon } />
																	</a>
																</Tooltip>
															</td>
														</tr>
													)
												})
											}
										</tbody>
									</table>
									<div className="text-center">
										<Button type="submit" onClick={ this.submit }>
											Guardar
										</Button>
									</div>
								</React.Fragment>
							)
						}
						{
							this.state.items.length == 0 && (
								<p className="no-items">No se han agregado ingredientes</p>
							)
						}
					</div>
					<div className="col-md-4 col-black">
						<div style={{ padding: '20px' }}>
							<Input
								searchRight
								value={ this.state.form.search }
								name="search"
								placeholder="Búsqueda por nombre"
								className="input-table"
								onSubmit={ () => this.load() }
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
							{
								this.state.form.search != '' && (
									<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
								)
							}
						</div>
						<div className="product-container">
							{
								this.state.data.map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item) }>{ item.name }</p>
									)
								})
							}
						</div>
						<Pagination 
							pages={ this.state.last_page } 
							active={ this.state.page }
							onChange={ async (page: number) => {
								await this.setState({
									page: page
								});
								this.load();
							} } />
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(CreateRecipe);