import React from 'react';
import { Button } from 'components';

interface Props {
	title?: string;
	children?: React.ReactNode;
	titleContainer?: React.ReactNode;
}

const ContainerRed = (props: Props) => {
	return (
		<div className="container-red-component">
			<div className="title-red">
				{
					props.titleContainer ? (
						<React.Fragment>
							{ props.titleContainer } 
						</React.Fragment>
					) : (
						<p>{ props.title }</p>
					)
				}
			</div>
			<div className="container-white">
				{ props.children }
			</div>
		</div>
	)
}

export default ContainerRed;