import { Api } from 'utils';

class PlanService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/plans/get',params),
		preview: async (params?: any) => Api.createResponse('admin/plans/preview',params),
		create: async (params?: any) => Api.createResponse('admin/plans/create',params),
		edit: async (params?: any) => Api.createResponse('admin/plans/edit',params),
		getData: async (params?: any) => Api.createResponse('admin/plans/get-data',params),
		getPlan: async (params?: any) => Api.createResponse('admin/plans/get-plan',params),
		getExistence: async (params?: any) => Api.createResponse('admin/plans/get-existence',params),
		delete: async (params?: any) => Api.createResponse('admin/plans/delete',params),
	}

}

export default PlanService;