import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CreateButton, Input, Select, Table, Pagination, Tooltip, Modal } from 'components';
import { TrashIcon, ViewIcon, EditIconWhite, EditIcon, AddCircleRed } from 'assets/icons';
import { Globals, Constants } from 'utils';
import { WarehouseService } from 'services';
import ModalCreateStorage from './create';
import ModalViewStorage from './modal-view';

interface LocationProps {
	warehouse_id: number | undefined;
}

interface Props extends RootState, RouteComponentProps<{},{},LocationProps> {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	type: ''
}

class Storage extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Departamento',
			'Almacén',
			'Estatus',
			'U. Almacenamiento',
			'Acción'
		],
		storage: null,
		visible_edit: false,
		storage_id: null,
		visible: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Almacén',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Almacén'
			}
		});

		if (this.props.location.state?.warehouse_id) {
			this.setState({
				visible_edit: true,
				storage_id: this.props.location.state?.warehouse_id
			});
		}

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await WarehouseService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.warehouses.rows,
			last_page: res.warehouses.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.setState({
			visible_edit: true
		});
	}

	createCustom = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_edit: true,
			storage_id: item?.id
		});
	}

	editContainer = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_edit: true,
			storage: item
		});
	}

	createStorage = () => {
		this.props.history.push('/admin/storage/warehouse/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			storage: item
		});
	}

	onCloseEdit = () => {
		this.setState({
			visible_edit: false,
			storage: null
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.props.history.push('/admin/storage/warehouse/edit/' + item.id);
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm(`¿Desea eliminar el almacén?`,async () => {
			await WarehouseService.admin.delete({
				warehouse_id: item.id
			});
			this.load(true);
		});
	}

	changeStatus = (item: any) => {
		Globals.confirm(`¿Desea ${ item.status == Constants.STATUS.ACTIVE ? 'desactivar' : 'activar' } el almacén?`,async () => {
			await WarehouseService.admin.changeStatus({
				warehouse_id: item.id
			});
			this.load(true);
		});
	}

	deleteContainer = (e: any,item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la unidad de almacenamiento?',async () => {
			await WarehouseService.admin.deleteContainer({
				container_id: item.id
			});
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			storage: null
		});
	}
	
	render() {
		const { visible_edit, visible } = this.state;

		return (
			<div id="storage">
				<Modal
		          className="modal-create-storage"
		          visible={ visible_edit }
		          title={ this.state.storage ? 'Editar' : 'Crear' }
		          onClose={ () => this.onCloseEdit() }
		        >
		          <ModalCreateStorage
		          	warehouse_id={ this.state.storage_id }
		          	storage={ this.state.storage }
		            onClose={ () => this.onCloseEdit() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-storage"
		          visible={ visible }
		          title={
	          		<div className="row row-modal-title">
      					<div className="col-8">
      						{/*
      						// @ts-ignore */}
	          				<h3>{ this.state.storage?.name }</h3>
	          			</div>
	          			<div className="col-4 text-right">
	          				<Tooltip title="Editar">
								<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,this.state.storage) }>
									<img src={ EditIconWhite } />
								</a>
							</Tooltip>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onClose() }
		        >
		          <ModalViewStorage
		          	storage={ this.state.storage }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-7">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-search-right"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-12 text-right">
						<CreateButton title="Crear almacén" onClick={ () => this.createStorage() } />
						<CreateButton title="Crear unidad de almacenamiento" onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Almacenes Registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.department?.name }</td>
								<td>{ i.name }</td>
								<td>
									<div className={ `change-status ${ i.status == Constants.STATUS.ACTIVE ? 'active' : 'inactive' }` } onClick={ () => this.changeStatus(i) }>
										<p>{ i.status == Constants.STATUS.ACTIVE ? 'Activo' : 'Inactivo' }</p>
									</div>
								</td>
								<td>
									{
										i.containers?.map((i: any) => {
											return (
												<div className="row" style={{ maxWidth: '200px' }}>
													<div className="col-7">
														<a href="#" className="item-container" onClick={ (e: any) => this.editContainer(e,i) }>{ i.name }</a>
													</div>
													<div className="col-3">
														<a href="#" onClick={ (e: any) => this.deleteContainer(e,i) }>
															<img className="icon-delete-container" src={ TrashIcon } />
														</a>
													</div>													
												</div>
												
											)
										})
									}
								</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Crear unidad de almacenamiento">
										<a className="link-icon" href="#" onClick={ (e: any) => this.createCustom(e,i) }>
											<img src={ AddCircleRed } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Storage);