export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		STORER: 2,
		HOST: 4,
		PRODUCTION: 6,
		BAR: 7,
		MODERATOR: 8,
		CHINESE: 9,
		JAPANESE: 10
	},
	DEPARTMENT: {
		BAR: 1,
		CHINESE: 2,
		JAPANESE: 3
	},
	SUBLEVELS: {
		EMPLOYEE: 1,
		SUPERVISOR: 2
	},
	WAREHOUSE_TYPES: {
		ESTANTE: 1,
		CAVA: 2,
		NEVERA: 3
	},
	WAREHOUSE_TYPES_SELECT: [
		{ value: 1, label: 'Estante' },
		{ value: 2, label: 'Cava' },
		{ value: 3, label: 'Nevera' }
	],
	STATUS: {
		ACTIVE: 1,
		INACTIVE: 0
	},
	STATUS_SELECT: [
		{ value: 1, label: 'Activo' },
		{ value: 0, label: 'Inactivo' },
	],
	DOCUMENT_TYPES: {
	    V: 1,
	    J: 2,
	    E: 3,
	    G: 4
	},
	ALLOW_EDIT: {
		YES: 1,
		NO: 0
	},
	ORDERS_STATUS: {
		PENDING: 1,
		FINISH: 0,
		INCOMPLETE: 2
	},
	WORKS_STATUS: {
		PENDING: 0,
		FINISH: 1
	},
	SHAPES: {
		CUADRADO: 1,
		RECTANGULO: 2,
		REDONDEADO: 3,
		OVALO: 4
	},
	ASSIGNED_LOT: {
		YES: 1,
		NO: 0
	},
	STATUS_REQUISITION: {
		FINISH: 0,
		ACTIVE: 1,
		CANCEL: 2
	},
	URGENT: {
		YES: 1,
		NO: 0
	},
	CANCEL_BY_CRON: {
		YES: 1,
		NO: 0
	},
	WORK_REPORT_STATUS: {
		PENDING: 1,
		FINISH: 2,
		CANCELLED: 3
	},
	OPERATION_CATEGORIES: {
	  ACTIVITY: 1,
	  WEIGHT: 2,
	  DECREASE: 3,
	  SWINE: 4
    },
    CURRENCIES: {
    	BOLIVAR: 'Bs.',
    	DOLAR: '$'
    },
    PRODUCT_TYPES: {
    	PRODUCT: 1,
    	CRISTAL: 2,
    	UTILITIES: 3,
    	PACKAGE: 4
    },
    MARINATED: {
    	YES: 1,
    	NO: 0
    }
}