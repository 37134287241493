import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import Orders from './orders';
import Products from './products';
import Works from './works';
import { HomeService } from 'services';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Home extends React.Component<Props> {

	state = {
		orders: [],
		products: [],
		works: [],
		count_orders: 0,
		count_works: 0,
		count_products: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Inicio',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await HomeService.admin.get({
			withoutLoading
		});
		this.setState({
			orders: res.orders,
			works: res.works,
			products: res.products,
			count_orders: res.count_orders,
			count_works: res.count_works,
			count_products: res.count_products
		});
	}
	
	render() {
		return (
			<div id="home">
				<div className="row">
					<div className="col-md-3 item-report-container">
						<div className="item-report" onClick={ () => {
							this.props.history.push('/admin/reports/works');
						} }>
							<div className="image-container">
								<img src={ HomeIcon } />
							</div>
							<p>Tareas Asignadas</p>
						</div>
					</div>
					<div className="col-md-3 item-report-container">
						<div className="item-report" onClick={ () => {
							this.props.history.push('/admin/reports/product-requisitions');
						} }>
							<div className="image-container">
								<img src={ HomeIcon } />
							</div>
							<p>Requisición de Productos</p>
						</div>
					</div>
					<div className="col-md-3 item-report-container">
						<div className="item-report" onClick={ () => {
							this.props.history.push('/admin/reports/cristal-inventory');
						} }>
							<div className="image-container">
								<img src={ HomeIcon } />
							</div>
							<p>Inventario Cristalería</p>
						</div>
					</div>
					<div className="col-md-3 item-report-container">
						<div className="item-report" onClick={ () => {
							this.props.history.push('/admin/reports/checklist');
						} }>
							<div className="image-container">
								<img src={ HomeIcon } />
							</div>
							<p>Reporte de Checklist</p>
						</div>
					</div>
					<div className="col-md-12">
						<Orders
							orders={ this.state.orders }
							count={ this.state.count_orders }
							onViewMore={ () => {
								this.props.history.push('/admin/orders');
							} }
							onCreate={ () => {
								this.props.history.push('/admin/orders/create');
							} }
							load={ () => {
								this.load(true);
							} }
						/>
					</div>
					<div className="col-md-8">
						<Works
							works={ this.state.works }
							count={ this.state.count_works }
							onViewMore={ () => {
								this.props.history.push('/admin/works');
							} }
							onCreate={ () => {
								this.props.history.push('/admin/works/create');
							} }
							load={ () => {
								this.load(true);
							} }
						/>
					</div>
					<div className="col-md-4">
						<Products
							products={ this.state.products }
							count={ this.state.count_products }
							onViewMore={ () => {
								this.props.history.push('/admin/products');
							} }
							onCreate={ () => {
								this.props.history.push('/admin/products/create');
							} }
							load={ () => {
								this.load(true);
							} }
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(Home);