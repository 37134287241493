import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CreateButton, Input, Select, Table, Pagination, Tooltip, Modal, DatePicker } from 'components';
import { TrashIcon, ViewIcon } from 'assets/icons';
import { WorkService } from 'services';
import { Globals, Constants } from 'utils';
import ModalViewWork from './modal-view';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	status: '',
	since: '',
	until: ''
}

const STATUS: any = [
	{ value: 0, label: 'Pendientes' },
	{ value: 1, label: 'Culminadas' }
];

class Works extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Responsable',
			'Estatus',
			'Fecha',
			'Ejecución',
			'Duración',
			'Acción'
		],
		visible: false,
		work: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Operaciones y Tareas',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Operaciones y Tareas'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await WorkService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.works.rows,
			last_page: res.works.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.props.history.push('/admin/works/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			work: item
		});
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la tarea?',async () => {
			await WorkService.admin.delete({
				work_id: item.id
			});
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			work: null
		});
	}
	
	render() {
		const { visible } = this.state;
		const work: any = this.state.work;

		return (
			<div id="works">
				<Modal
		          className="modal-view-work"
		          visible={ visible }
		          title={ work?.user?.name + ' ' + work?.user?.lastname } 
		          onClose={ () => this.onClose() }
		        >
		          <ModalViewWork
		          	work={ work }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-8">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<Select
							name="status"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Todas"
							value={ this.state.form.status }
							options={ STATUS } />
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Desde"
							showYearDropdown={ true }
							maxDate={ moment(this.state.form.until || moment()).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										since: text
									}						
								},this.load);
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Hasta"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							minDate={ this.state.form.since && moment(this.state.form.since).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										until: text
									}						
								},this.load);
							} }
							value={ this.state.form.until }
						/>
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-6"></div>
					<div className="col-md-6 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Tareas Asignadas" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.user?.name + ' ' + i?.user?.lastname }</td>
								<td>
									<div className={ `status-container ${ i.status == Constants.WORKS_STATUS.PENDING ? 'active' : i.status == Constants.WORKS_STATUS.FINISH ? 'finish' : '' }` }>
										<p>{ i.status_text }</p>
									</div>
								</td>
								<td>{ moment(i?.date).format('DD-MM-YYYY') }</td>
								<td>{ i?.init_date && i?.finish_date ? (moment(i?.init_date).format('hh:mm A') + ' - ' + moment(i?.finish_date).format('hh:mm A')) : '-' }</td>
								<td>{ i?.init_date && i?.finish_date ? (moment(i?.finish_date).diff(moment(i?.init_date),'minutes') + ' Min') : '' }</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Works);