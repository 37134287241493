import React from 'react';

interface Props {
	label?: string,
	name: string,
	color?: string,
	options: Item[],
	placeholder?: string,
	onChange?: (e: any) => void,
	value: any,
	disabled?: boolean,
	className?: string,
}

interface Item {
	value: string,
	label: string
}

const Select = (props: Props) => ( 
	<div className="form-group">
		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
		<select { ...props } className={ `form-control ${ props.color ? props.color : '' } ${ props.className ? props.className : '' }` }>
			<option value="" disabled={ !props.placeholder }>{ props.placeholder || 'Seleccione' }</option>
			{ props.options.map((i: Item,index: number) => {
				return (
					<option 
						key={ index } 
						value={ i.value }>
						{ i.label }
					</option>
				)				
			}) }
		</select>
	</div>
)

export default Select;