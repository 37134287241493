import React from 'react';
import { Button, DatePicker } from 'components';
import { CalendarWhite } from 'assets/icons';
import moment from 'moment';
import { Globals } from 'utils';

interface Props {
	onClose: (form?: any) => void;
	date_requisition: any;
}

class ModalGenerateRequisition extends React.Component<Props> {

	state = {
		form: {
			date_requisition: this.props.date_requisition
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = () => {
		if (!this.state.form.date_requisition) {
			Globals.showError("Debe ingresar la fecha de entrega");
			return false;
		}

		this.props.onClose(this.state.form);
	}

	render() {
		return (
			<div id="modal-generate-requisition">
				<DatePicker
					calendarIcon={ CalendarWhite }
					label="Fecha y hora de entrega"
					placeholder="DD/MM/YYYY HH:mm"
					showTimeSelect={ true }
					showYearDropdown={ true }
					minDate={ moment().toDate() }
					dateFormat="dd/MM/yyyy HH:mm"
					onChange={ (text: string) => {
						this.setState({
							form: {
								...this.state.form,
								date_requisition: text
							}						
						});
					} }
					value={ this.state.form.date_requisition }
				/>
				<div className="text-center">
					<Button type="button" className="btn-submit" onClick={ () => this.submit() }>
						Guardar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalGenerateRequisition;