import React from 'react';
import { Button } from 'components';
import { ChecklistService } from 'services';
import { CalendarRed, TimeRedIcon } from 'assets/icons';
import moment from 'moment';
import { ParseDays } from 'utils';

interface Props {
	onClose: () => void;
	checklist: any;
}

class ModalView extends React.Component<Props> {

	state = {
		checklist: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ChecklistService.admin.view({
			checklist_id: this.props.checklist?.id
		});
		this.setState({
			checklist: res.checklist
		});
	}

	render() {
		const checklist: any = this.state.checklist;

		return (
			<div id="modal-view-checklist">
				{ checklist?.level && <p className="title"><strong>Área:</strong> { checklist?.level?.name }</p> }
				{ checklist?.supervisor && <p className="title"><strong>Supervisor:</strong> { checklist?.supervisor?.name + ' ' + checklist?.supervisor?.lastname }</p> }
				{
					checklist && (
						<div className="date">
							<img src={ CalendarRed } />
							<p>{ ParseDays(checklist?.days || {}) }</p>
						</div>
					)
				}
				{
					checklist && (
						<div className="date-container">
							<div style={{ flex: .5 }}>
								<div className="date">
									<img src={ TimeRedIcon } />
									<p>{ moment(checklist?.since,'HH:mm:ss').format('hh:mm A') }</p>
								</div>
							</div>
							<div style={{ flex: .5 }}>
								<div className="date">
									<img src={ TimeRedIcon } />
									<p>{ moment(checklist?.until,'HH:mm:ss').format('hh:mm A') }</p>
								</div>
							</div>
						</div>
					)
				}
				<hr />
				{
					checklist?.details?.map((item: any) => {
						return (
							<p className="item">{ item.name }</p>
						)
					})
				}
				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalView;