import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import randomColor from 'randomcolor';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Reports extends React.Component<Props> {

	state = {
		items: [
			{ name: 'Inventario (Control de Barra)', route: '/admin/reports/control-bar', icon: HomeIcon },
			{ name: 'Requisición de Productos', route: '/admin/reports/product-requisitions', icon: HomeIcon },
			{ name: 'Inventario Cristalería', route: '/admin/reports/cristal-inventory', icon: HomeIcon },
			{ name: 'Reporte Control de Cobro', route: '/admin/reports/host-control-payments', icon: HomeIcon },
			{ name: 'Reporte Ingreso Clientes', route: '/admin/reports/host-add-clients', icon: HomeIcon },
			{ name: 'Reporte Control Salida', route: '/admin/reports/host-control-out', icon: HomeIcon },
			{ name: 'Reporte Delivery', route: '/admin/reports/host-delivery', icon: HomeIcon },
			{ name: 'Reporte de Errores', route: '/admin/reports/host-errors', icon: HomeIcon },
			{ name: 'Tareas Asignadas', route: '/admin/reports/works', icon: HomeIcon },
			{ name: 'Reporte de Checklist', route: '/admin/reports/checklist', icon: HomeIcon },
			{ name: 'Recepción y Faltante', route: '/admin/reports/reception', icon: HomeIcon },
			{ name: 'Merma Consolidado', route: '/admin/reports/decrease', icon: HomeIcon },
			{ name: 'Inventario y Ubicación', route: '/admin/reports/location', icon: HomeIcon },
			{ name: 'Reporte de Recetas', route: '/admin/reports/recipes', icon: HomeIcon },
			{ name: 'Reporte diario de operaciones', route: '/admin/reports/operations', icon: HomeIcon },
			{ name: 'Reporte de Perdida', route: '/admin/reports/loss', icon: HomeIcon },
			{ name: 'Reporte de Porcionado', route: '/admin/reports/portioning', icon: HomeIcon },
			{ name: 'Reporte de Cumplimiento', route: '/admin/reports/compliance', icon: HomeIcon },
		]
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes'
			}
		});
	}
	
	render() {
		return (
			<div id="reports">
				<div className="row">
					{
						this.state.items.map((item: any) => {
							return (
								<div className="col-lg-3 item-report-container">
									<div className="item-report" onClick={ () => {
										this.props.history.push(item.route);
									} }>
										<div className="image-container" style={{
											backgroundColor: randomColor({
												luminosity: 'dark'
											})
										}}>
											<img src={ item.icon } />
										</div>
										<p>{ item.name }</p>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
}

export default connect()(Reports);