import React from 'react';

interface Props {
	rows: number,
	label?: string,
	placeholder?: string,
	name: string,
	value: string,
	onChange?: (e: any) => void,
	color?: string,
	disabled?: boolean,
}

const Textarea = (props: Props) => (
	<div className="form-group">
		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
		<textarea 
			{ ...props }
			rows={ props.rows ? props.rows : 4 }
			className={ `form-control ${ props.color ? 'input-'+props.color : 'input-white' }` }
			name={ props.name }>
		</textarea>
	</div>
)

export default Textarea;