import React from 'react';
import { AddIcon } from 'assets/icons';

interface Props {
	onClick: () => void;
	title?: string;
}

const CreateButton = (props: Props) => {
	return (
		<a href="#" className="add-button-component" onClick={ (e: any) => {
			e.preventDefault();
			if (props.onClick)
				props.onClick();
		} }>
			<div>
				<img src={ AddIcon } />
				<p>{ props.title || 'Crear' }</p>
			</div>
		</a>
	)
}

export default CreateButton;