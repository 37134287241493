import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon, ViewIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select, Modal, Tooltip } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import ModalViewRecipe from './modal-view-recipe';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	department_id: ''
}

class Recipes extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Nombre',
			'Código',
			'Departamento',
			'Porcentaje',
			'Tiempo',
			'Temperatura',
			'Kilogramos',
			'Acción'
		],
		form: INITIAL_STATE,
		departments: [],
		visible: false,
		recipe: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Reporte de Recetas'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.recipes({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.recipes.rows,
			last_page: res.recipes.count,
			departments: res.departments
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	onClose = () => {
		this.setState({
			visible: false,
			recipe: null
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			recipe: item
		});
	}
	
	render() {
		const { visible } = this.state;
		const recipe: any = this.state.recipe;

		return (
			<div id="reports-delivery">
				<Modal
		          className="modal-view-recipe"
		          visible={ visible }
		          title={
	          		<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-order">
		          				<h3>{ recipe?.name }</h3>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onClose();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onClose() }
		        >
		          <ModalViewRecipe
		          	recipe={ recipe }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-6">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre o código"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<Select
							name="department_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Departamento"
							value={ this.state.form.department_id }
							options={ this.state.departments.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<Table title="Recetas" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.name }</td>
								<td>{ i?.number }</td>
								<td>{ i?.department?.name }</td>
								<td>{ i?.percentage }</td>
								<td>{ i?.time }</td>
								<td>{ i?.temperature }</td>
								<td>{ i?.quantity }</td>
								<td>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Recipes);