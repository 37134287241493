import React from 'react';
import { Textarea, Button } from 'components';
import { ConfirmImage } from 'assets/img';
import { OrderService } from 'services';

interface Props {
	onClose: () => void;
	order: any;
}

class ModalDelete extends React.Component<Props> {

	state = {
		form: {
			comments: ''
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	send = async () => {
		await OrderService.admin.delete({
			order_id: this.props.order?.id,
			delete_comments: this.state.form.comments
		});
		this.props.onClose();
	}

	render() {
		return (
			<div id="modal-delete-order">
				<div className="text-right">
					<a href="#" className="close-modal-view" onClick={ (e: any) => {
	  					e.preventDefault();
	  					this.props.onClose();
	  				} }>
	  					<i className="fa fa-close" />
	  				</a>
				</div>
				<div className="text-center">
					<img src={ ConfirmImage } className="confirm-image" />
				</div>
				<p>¿Desea eliminar la orden?</p>
				<Textarea
					rows={ 3 }
					placeholder="Observación"
					name="comments"
					onChange={ this.change }
                    value={ this.state.form.comments } />
                <div className="text-center">
                	<Button type="button" className="btn-submit" onClick={ this.send }>
						Aceptar
					</Button>
                </div>
			</div>
		)
	}
}

export default ModalDelete;