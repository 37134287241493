import Calendar from './calendar.png';
import Search from './search.svg';
import PaperclipIcon from './paperclip.png';
import UserIcon from './user.png';
import UserAvatar from './user.svg';
import ArrowLeft from './arrow-left.png';
import LockIcon from './lock.png';
import PasswordInactive from './password-inactive.png';
import PasswordActive from './password-active.png';
import NotificationIcon from './notifications.png';
import LogoutIcon from './logout.png';
import ProfileIcon from './profile.png';
import DownloadIcon from './download.png';
import EditIcon from './edit.png';
import EditIconRed from './edit-red.png';
import AddIcon from './add.png';
import SearchIcon from './search.png';
import TrashIcon from './trash.png';
import ArrowUp from './arrow-up.png';
import ArrowBottom from './arrow-bottom.png';
import ViewIcon from './view.png';
import HashIcon from './hash.png';
import DownloadTableIcon from './download2.png';
import AddWhiteIcon from './add-white.png';
import EditIconWhite from './edit-white.png';
import ViewProviderIcon from './view-provider.png';
import LocationIcon from './location.png';
import MailIcon from './mail.png';
import PhoneIcon from './phone.png';
import TimeIcon from './time.png';
import TimeRedIcon from './time-red.png';
import PhoneRedIcon from './phone-red.png';
import MailRedIcon from './mail-red.png';
import EditCircleRed from './edit-circle-red.png';
import Switch from './switch.png';
import SwitchGreen from './switch-green.png';
import AddCircleRed from './add-circle-red.png';
import ViewUserIcon from './view-user.png';
import ArrowBottomBlack from './arrow-bottom-black.png';
import ArrowUpBlack from './arrow-up-black.png';
import CalendarWhite from './calendar-white.png';
import CommentsIcon from './comments.png';
import CommentsRedIcon from './comments-red.png';
import ShapeIcon from './shape.png';
import ColorIcon from './color.png';
import RotationIcon from './rotation.png';
import DeleteIcon from './delete.png';
import CheckRedIcon from './check-red.png';
import TimeCircleRed from './time-circle-red.png';
import CircleYellow from './circle-yellow.png';
import CircleYellowBell from './circle-yellow-bell.png';
import CircleGreen from './circle-green.png';
import CalendarRed from './calendar-red.png';
import CloseIcon from './close.png';
import HomeIcon from './home.png';
import CheckGreenIcon from './check-green.png';
import CanceledIcon from './canceled.png';

// Menu
import MenuHome from './menu/home.png';
import MenuBrands from './menu/brands.png';
import MenuCategories from './menu/categories.png';
import MenuDepartments from './menu/departments.png';
import MenuProducts from './menu/products.png';
import MenuProfiles from './menu/profiles.png';
import MenuProviders from './menu/providers.png';
import MenuStorage from './menu/storage.png';
import MenuUsers from './menu/users.png';
import MenuMaster from './menu/master.png';
import MenuOrders from './menu/orders.png';
import MenuWorks from './menu/works.png';
import MenuReports from './menu/reports.png';
import MenuChecklist from './menu/checklist.png';
import MenuHost from './menu/host.png';
import MenuHomeWhite from './menu/home-white.png';
import MenuBrandsWhite from './menu/brands-white.png';
import MenuCategoriesWhite from './menu/categories-white.png';
import MenuDepartmentsWhite from './menu/departments-white.png';
import MenuProductsWhite from './menu/products-white.png';
import MenuProfilesWhite from './menu/profiles-white.png';
import MenuProvidersWhite from './menu/providers-white.png';
import MenuStorageWhite from './menu/storage-white.png';
import MenuUsersWhite from './menu/users-white.png';
import MenuMasterWhite from './menu/master-white.png';
import MenuOrdersWhite from './menu/orders-white.png';
import MenuWorksWhite from './menu/works-white.png';
import MenuReportsWhite from './menu/reports-white.png';
import MenuDesignWhite from './menu/design-white.png';
import MenuChecklistWhite from './menu/checklist-white.png';
import MenuHostWhite from './menu/host-white.png';
import MenuAreaWhite from './menu/areas-white.png';
import MenuBoardWhite from './menu/boards-white.png';
import MenuProductionWhite from './menu/production-white.png';
import MenuRecipesWhite from './menu/recipes-white.png';
import MenuConditionedWhite from './menu/conditioned-white.png';
import MenuDishesWhite from './menu/dishes-white.png';
import MenuPlansWhite from './menu/plans-white.png';
import MenuProduction from './menu/production.png';

const Menu = {
	MenuHome,
	MenuBrands,
	MenuCategories,
	MenuDepartments,
	MenuProducts,
	MenuProfiles,
	MenuProviders,
	MenuStorage,
	MenuUsers,
	MenuMaster,
	MenuOrders,
	MenuWorks,
	MenuReports,
	MenuHomeWhite,
	MenuBrandsWhite,
	MenuCategoriesWhite,
	MenuDepartmentsWhite,
	MenuProductsWhite,
	MenuProfilesWhite,
	MenuProvidersWhite,
	MenuStorageWhite,
	MenuUsersWhite,
	MenuMasterWhite,
	MenuOrdersWhite,
	MenuWorksWhite,
	MenuReportsWhite,
	MenuDesignWhite,
	MenuChecklist,
	MenuChecklistWhite,
	MenuHost,
	MenuHostWhite,
	MenuAreaWhite,
	MenuBoardWhite,
	MenuProductionWhite,
	MenuDishesWhite,
	MenuRecipesWhite,
	MenuConditionedWhite,
	MenuPlansWhite,
	MenuProduction
}

export {
	Calendar,
	Search,
	ArrowLeft,
	PaperclipIcon,
	UserIcon,
	UserAvatar,
	LockIcon,
	PasswordInactive,
	PasswordActive,
	Menu,
	NotificationIcon,
	LogoutIcon,
	ProfileIcon,
	DownloadIcon,
	EditIcon,
	EditIconRed,
	AddIcon,
	SearchIcon,
	TrashIcon,
	ArrowUp,
	ArrowBottom,
	ViewIcon,
	HashIcon,
	DownloadTableIcon,
	AddWhiteIcon,
	EditIconWhite,
	ViewProviderIcon,
	PhoneIcon,
	MailIcon,
	LocationIcon,
	TimeIcon,
	TimeRedIcon,
	PhoneRedIcon,
	MailRedIcon,
	EditCircleRed,
	Switch,
	SwitchGreen,
	AddCircleRed,
	ViewUserIcon,
	ArrowBottomBlack,
	ArrowUpBlack,
	CalendarWhite,
	CommentsIcon,
	CommentsRedIcon,
	ShapeIcon,
	DeleteIcon,
	RotationIcon,
	ColorIcon,
	CheckRedIcon,
	TimeCircleRed,
	CircleYellow,
	CircleYellowBell,
	CircleGreen,
	CalendarRed,
	CloseIcon,
	HomeIcon,
	CheckGreenIcon,
	CanceledIcon
}