const INITIAL_STATE = {
	title: ''
}

const subheader = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_SUBHEADER':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default subheader;