import { Api } from 'utils';

class OrderService {
	
	static admin = {
		getProviders: async (params?: any) => Api.createResponse('admin/orders/get-providers',params),
		getPresentations: async (params?: any) => Api.createResponse('admin/orders/get-presentations',params),
		get: async (params?: any) => Api.createResponse('admin/orders/get',params),
		create: async (params?: any) => Api.createResponse('admin/orders/create',params),
		edit: async (params?: any) => Api.createResponse('admin/orders/edit',params),
		delete: async (params?: any) => Api.createResponse('admin/orders/delete',params),
		view: async (params?: any) => Api.createResponse('admin/orders/view',params),
		export: async (params?: any) => Api.createResponse('admin/orders/export',params),
	}

}

export default OrderService;