import React from 'react';
import { DownloadIcon } from 'assets/icons';

interface Props {
	onClick: () => void
}

const DownloadButton = (props: Props) => {
	return (
		<a href="#" className="download-button-component" onClick={ (e: any) => {
			e.preventDefault();
			if (props.onClick)
				props.onClick();
		} }>
			<div>
				<img src={ DownloadIcon } />
				<p>Descargar Reporte</p>
			</div>
		</a>
	)
}

export default DownloadButton;