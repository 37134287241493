import { Api } from 'utils';

class BrandService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/brands/get',params),
		create: async (params?: any) => Api.createResponse('admin/brands/create',params),
		update: async (params?: any) => Api.createResponse('admin/brands/update',params),
		delete: async (params?: any) => Api.createResponse('admin/brands/delete',params),
	}

}

export default BrandService;