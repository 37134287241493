import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CreateButton, Input, Select, Pagination, Table, Tooltip, Modal } from 'components';
import { ViewIcon, TrashIcon, EditIcon } from 'assets/icons';
import { WhiteImage } from 'assets/img';
import { ProductService } from 'services';
import ModalViewProduct from './modal-view';
import { Globals, Constants } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	category_id: '',
	type_id: ''
}

class Products extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'',
			'Producto',
			'Código',
			'Descripción',
			'Tipo',
			'Categoría',
			'Acción'
		],
		visible_view: false,
		product: null,
		categories: [],
		types: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Productos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Productos'
			}
		});

		this.load();
		this.loadData();
	}

	loadData = async () => {
		const res: any = await ProductService.admin.getData({
			withoutLoading: true
		});
		this.setState({
			categories: res.categories,
			types: res.types
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ProductService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.products.rows,
			last_page: res.products.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.props.history.push('/admin/products/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			product: item
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.props.history.push('/admin/products/edit/' + item.id);
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el producto?',async () => {
			await ProductService.admin.delete({
				product_id: item.id
			});
			this.load(true);
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			product: null
		});
	}
	
	render() {
		const { visible_view } = this.state;

		return (
			<div id="products">
				<Modal
		          className="modal-view-product"
		          visible={ visible_view }
		          title=""
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalViewProduct
		          	onEdit={ () => {
		          		this.setState({
		          			visible_view: false
		          			// @ts-ignore
		          		},() => this.props.history.push('/admin/products/edit/' + this.state.product?.id));
		          	} }
		          	product={ this.state.product }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-3">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Select
							name="category_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Categoría"
							value={ this.state.form.category_id }
							options={ this.state.categories.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-3">
						<Select
							name="type_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Tipo"
							value={ this.state.form.type_id }
							options={ this.state.types.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-3 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<Table title="Productos Registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>
									<img src={ i.image ? (Constants.BASE_STORAGE + i.image) : WhiteImage } className="image-category" />
								</td>
								<td>{ i.name }</td>
								<td>{ i.code }</td>
								<td>{ i.description }</td>
								<td>{ i.type?.name }</td>
								<td>{ i.category?.name }</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Products);