import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select } from 'components';
import moment from 'moment';
import { ReportService } from 'services';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	date: '',
	search: ''
}

class Portioning extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Producto fuente',
			'Fecha',
			'Nº de Porciones',
			'Existencia en almacén',
			'Porciones resultantes',
			'Sobrantes',
			'Faltantes'
		],
		form: INITIAL_STATE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Reporte de Porcionado'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.portioning({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD')
		});
		this.setState({
			data: res.work_order_details.rows,
			last_page: res.work_order_details.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="reports-portioning">
				<div className="row">
					<div className="col-md-4">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre del producto"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<DatePicker
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},() => this.load(true));
							} }
							value={ this.state.form.date }
						/>
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<Table title="Reporte de Porcionado" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						const weight = i?.order_weights?.map((i: any) => i.weight).reduce((a: number, b: number) => a + b,0) || 0;
						const leftover = weight - i?.destiny_weight;

						return (
							<tr key={ index }>
								<td className="name">
									<p>{ i?.destiny_product?.name }</p>
									<span>{ i?.destiny_presentation?.name }</span>
								</td>
								<td>{ moment(i?.date).format('DD-MM-YYYY HH:mm') }</td>
								<td>{ i?.destiny_weight }</td>
								<td>{ i?.existence }</td>
								<td>{ weight }</td>
								<td>{ leftover >= 0 ? leftover : '-' }</td>
								<td>{ leftover <= 0 ? (leftover * (-1)) : '-' }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Portioning);