import { Api } from 'utils';

class ChecklistService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/checklist/get',params),
		create: async (params?: any) => Api.createResponse('admin/checklist/create',params),
		edit: async (params?: any) => Api.createResponse('admin/checklist/edit',params),
		view: async (params?: any) => Api.createResponse('admin/checklist/view',params),
		delete: async (params?: any) => Api.createResponse('admin/checklist/delete',params),
		getUsers: async (params?: any) => Api.createResponse('admin/checklist/get-users',params),
		addUsers: async (params?: any) => Api.createResponse('admin/checklist/add-users',params),
	}

}

export default ChecklistService;