import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select } from 'components';
import moment from 'moment';
import { ReportService } from 'services';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	board_id: '',
	area_id: '',
	date: ''
}

class AddClients extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Fecha',
			'Mesa en espera',
			'Cliente',
			'Nº de Personas',
			'Hora de Llegada',
			'Mesa de salón',
			'Hora de Ingreso',
			'Observaciones'
		],
		form: INITIAL_STATE,
		boards: [],
		areas: [],
		total: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Reporte Ingreso Clientes'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReportService.admin.addClients({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.reservations.rows,
			last_page: res.reservations.count,
			areas: res.areas,
			total: res.total || 0
		},() => callback());
	}

	getBoards = async () => {
		const res: any = await ReportService.admin.getBoards({
			area_id: this.state.form.area_id
		});
		this.setState({
			boards: res.boards
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		const { total } = this.state;

		return (
			<div id="reports-add-clients">
				<div className="row">
					<div className="col-md-3">
						<DatePicker
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},this.load);
							} }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-3">
						<Select
							name="area_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true,() => {
									this.getBoards();
								});
							}) }
							placeholder="Area"
							value={ this.state.form.area_id }
							options={ this.state.areas.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					{
						this.state.form.area_id != '' && (
							<div className="col-md-3">
								<Select
									name="board_id"
									onChange={ (e: any) => this.change(e,() => {
										this.load(true);
									}) }
									placeholder="Mesa"
									value={ this.state.form.board_id }
									options={ this.state.boards.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
							</div>
						)
					}
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="text-right">
					<div className="container-total">
						<p><strong>Total Clientes:</strong> { total }</p>
					</div>
				</div>

				<Table title="Reporte Ingreso Clientes" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i.board?.name }</td>
								<td>{ i.name }</td>
								<td>{ i.quantity }</td>
								<td>{ i.start_date ? moment(i.start_date).format('hh:mm A') : '' }</td>
								<td>{ i.selected_board?.name }</td>
								<td>{ i.finish_date ? moment(i.finish_date).format('hh:mm A') : '' }</td>
								<td>{ i.comments }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(AddClients);