import React from 'react';
import { Button } from 'components';
import { CircleYellow, CalendarRed, TimeRedIcon, CircleGreen, TimeCircleRed, CanceledIcon } from 'assets/icons';
import moment from 'moment';
import { WorkService } from 'services';
import { Constants } from 'utils';

interface Props {
	onClose: () => void;
	work: any;
}

class ModalViewWork extends React.Component<Props> {

	state = {
		work: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await WorkService.admin.view({
			work_id: this.props.work?.id
		});
		this.setState({
			work: res.work
		});
	}

	render() {
		const work: any = this.state.work;

		let circle = CircleYellow;

		if (work?.init_date != null) {
			circle = TimeCircleRed;
		}

		if (work?.status == Constants.WORKS_STATUS.FINISH) {
			circle = CircleGreen;
		}

		return (
			<div id="modal-view-work">
				<div className="work-title">
					<p className="title">{ work?.user?.level?.name }</p>
					<img src={ circle } className="circle" />
				</div>
				{
					work?.cancel_by_cron == Constants.CANCEL_BY_CRON.YES && (
						<div className="canceled-title">
							<img src={ CanceledIcon } className="canceled-circle" />
							<p className="title">No Realizada</p>
						</div>
					)
				}
				{
					work?.date && (
						<React.Fragment>
							<p className="init-work">Inicio de tarea</p>
							<div className="row row-date">
								<div className="col-md-6">
									<div className="item-date">
										<img src={ CalendarRed } />
										<p>{ moment(work?.date).format('DD-MM-YYYY') }</p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="item-date">
										<img src={ TimeRedIcon } />
										<p>{ moment(work?.hour,'HH:mm:ss').format('hh:mm A') }</p>
									</div>
								</div>
							</div>
						</React.Fragment>
					)
				}
				{
					work?.description && (
						<React.Fragment>
							<hr />
							<p className="bold">Descripción</p>
							<p className="description">{ work?.description }</p>
						</React.Fragment>
					)
				}
				<hr />
				{
					work?.details?.map((item: any) => {
						let circle = CircleYellow;

						if (work?.finish_date != null) {
							if (item.status == Constants.WORKS_STATUS.FINISH) {
								circle = CircleGreen;
							}
							else {
								circle = TimeCircleRed;
							}
						}

						return (
							<div className="item">
								<div style={{ flex: .8 }}>
									<p>{ item.name }</p>
								</div>
								<div style={{ flex: .2 }} className="text-right">
									<img src={ circle } />
								</div>
							</div>
						)
					})
				}
				<div className="text-center">
					<Button type="button" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalViewWork;