import React from 'react';
import { Input, Button } from 'components';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { BgLogin } from 'assets/img';
import { ArrowLeft, LockIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import { Globals } from 'utils';

interface Props extends RouteComponentProps<any> {
	dispatch: any
}

class Reset extends React.Component<Props> {

	state = {
		visible: false,
		form: {
			password: '',
			password_confirmation: ''
		}
	}

	async componentDidMount() {
		const res: any = await AuthService.checkCode({
			code: this.props.match.params.code
		});
		if (!res.result) {
			this.props.history.replace('/');
		}
		else {
			this.setState({
				visible: true
			});
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recuperación de Contraseña',
				onBack: null
			}
		});
	}

	disabled = () => {
		const {
			password,
			password_confirmation
		} = this.state.form;
		return password == '' || password == null ||
		       password_confirmation == '' || password_confirmation == null;
	}

	submit = async (e: any) => {
		e.preventDefault();
		await AuthService.reset({
			code: this.props.match.params.code,
			password: this.state.form.password,
			password_confirmation: this.state.form.password_confirmation
		});
		this.props.history.replace('/login');
		Globals.showSuccess("Se ha cambiado su contraseña correctamente");
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		return (
			<div id="reset">
				<div className="row container-form">
					<div className="col-lg-6 container-bg" style={{ backgroundImage: `url(${ BgLogin })` }}></div>
					<form className="col-lg-6" onSubmit={ this.submit }>
						<div className="row row-title">
							<div className="col-2">
								<Link to="/login">
									<img src={ ArrowLeft } className="arrow-left-green" />
								</Link>
							</div>
							<div className="col-8">
								<h3 className="title-green">Recuperar Contraseña</h3>
							</div>
						</div>
						<div className="container-input">
							<Input
								label="Nueva Contraseña"
								type="password" 
								name="password"
								onChange={ this.change } />
							<Input
								label="Repetir Contraseña"
								type="password" 
								name="password_confirmation"
								onChange={ this.change } />
							<div className="text-center">
								<Button type="submit" disabled={ this.disabled() }>
									Guardar
								</Button>
							</div>
						</div>		
					</form>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Reset);