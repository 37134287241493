import { Api } from 'utils';

class ProviderService {
	
	static admin = {
		getData: async (params?: any) => Api.createResponse('admin/providers/get-data',params),
		get: async (params?: any) => Api.createResponse('admin/providers/get',params),
		create: async (params?: any) => Api.createResponse('admin/providers/create',params),
		update: async (params?: any) => Api.createResponse('admin/providers/update',params),
		delete: async (params?: any) => Api.createResponse('admin/providers/delete',params),
		status: async (params?: any) => Api.createResponse('admin/providers/status',params),
	}

}

export default ProviderService;