import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { OperationService } from 'services';
import { ContainerRed, Button, Input, Select, DatePicker, Tooltip, SelectSearch, Pagination } from 'components';
import { Globals, Constants } from 'utils';
import moment from 'moment';
import { TrashIcon } from 'assets/icons';

interface MatchParams {
	operation_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

const ITEM = {
	ACTIVE: 1,
	INACTIVE: 0
}

class CreateOperation extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: {
			name: '',
			category_id: '',
			percentage: ITEM.INACTIVE,
			time: ITEM.INACTIVE,
			quantity: ITEM.INACTIVE,
			temperature: ITEM.INACTIVE
		},
		categories: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Operaciones',
				onBack: () => {
					this.props.history.push('/admin/production/operations');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Operaciones / ${ this.props.match.params.operation_id ? 'Editar' : 'Crear' } operación`
			}
		});

		this.loadData();
	}

	loadData = async () => {
		const res: any = await OperationService.admin.view({
			withoutLoading: true,
			operation_id: this.props.match.params.operation_id
		});
		this.setState({
			categories: res.categories
		},() => {
			if (res.operation) {
				this.setState({
					form: {
						...this.state.form,
						name: res.operation?.name || '',
						category_id: res.operation?.category_id || '',
						percentage: res.operation?.percentage || ITEM.INACTIVE,
						time: res.operation?.time || ITEM.INACTIVE,
						quantity: res.operation?.quantity || ITEM.INACTIVE,
						temperature: res.operation?.temperature || ITEM.INACTIVE
					}
				});
			}
			else if (this.props.match.params.operation_id) {
				this.props.history.push('/admin/production/operations');
			}
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.match.params.operation_id) {
			await OperationService.admin.update({
				operation_id: this.props.match.params.operation_id,
				...this.state.form
			});
			Globals.showSuccess("Se ha modificado la operación correctamente");
			this.props.history.push('/admin/production/operations');
		}
		else {
			await OperationService.admin.create({
				...this.state.form
			});
			Globals.showSuccess("Se ha registrado la operación correctamente");
			this.props.history.push('/admin/production/operations');
		}
	}
	
	render() {
		return (
			<div id="create-operation">
				<ContainerRed title={ `${ this.props.match.params.operation_id ? 'Editar' : 'Crear' } Operación` }>	
					<div style={{ padding: '20px' }}>
						<div className="row">
							<div className="col-md-6">
								<Input
									className="input-table"
									value={ this.state.form.name }
									name="name"
									label="Nombre"
									onChange={ (e: any) => this.change(e) } />
							</div>
							<div className="col-md-6">
								<Select
									name="category_id"
									onChange={ (e: any) => this.change(e) }
									label="Categoría"
									value={ this.state.form.category_id }
									options={ this.state.categories.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
							</div>
						</div>
					</div>
					<div className="row-numbers">
						<div className="input-container" onClick={ () => {
							this.setState({
								form: {
									...this.state.form,
									percentage: this.state.form.percentage == ITEM.ACTIVE ? ITEM.INACTIVE : ITEM.ACTIVE
								}
							});
						} }>
							<div className={ `circle ${ this.state.form.percentage == ITEM.ACTIVE ? 'active' : '' }` }></div>
							<p>Porcentaje</p>					
						</div>
						<div className="input-container" onClick={ () => {
							this.setState({
								form: {
									...this.state.form,
									time: this.state.form.time == ITEM.ACTIVE ? ITEM.INACTIVE : ITEM.ACTIVE
								}
							});
						} }>
							<div className={ `circle ${ this.state.form.time == ITEM.ACTIVE ? 'active' : '' }` }></div>
							<p>Tiempo</p>
						</div>
						<div className="input-container" onClick={ () => {
							this.setState({
								form: {
									...this.state.form,
									quantity: this.state.form.quantity == ITEM.ACTIVE ? ITEM.INACTIVE : ITEM.ACTIVE
								}
							});
						} }>
							<div className={ `circle ${ this.state.form.quantity == ITEM.ACTIVE ? 'active' : '' }` }></div>
							<p>Kilogramos</p>
						</div>
						<div className="input-container" onClick={ () => {
							this.setState({
								form: {
									...this.state.form,
									temperature: this.state.form.temperature == ITEM.ACTIVE ? ITEM.INACTIVE : ITEM.ACTIVE
								}
							});
						} }>
							<div className={ `circle ${ this.state.form.temperature == ITEM.ACTIVE ? 'active' : '' }` }></div>
							<p>Temperatura</p>
						</div>
					</div>
					<div className="text-center">
						<Button type="submit" onClick={ this.submit }>
							Guardar
						</Button>
					</div>
				</ContainerRed>
			</div>
		)
	}
}

export default connect()(CreateOperation);