import React from 'react';
import { Input, Textarea, Select, Button } from 'components';
import { ProfileService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals, Constants } from 'utils';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Profile extends React.Component<Props> {

	state = {
		form: {
			name: '',
	        email: '',
	        username: '',
	        lastname: ''
		}
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Mis Datos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Mis Datos'
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await ProfileService.get({
			user_id: this.props.user.id
		});
		this.setState({
			form: {
				name: res.user.name,
				lastname: res.user.lastname,
		        email: res.user.email,
		        username: res.user.username
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await ProfileService.update({
			user_id: this.props.user.id,
			...this.state.form
		});
		Globals.showSuccess("Se ha actualizado el perfil correctamente");
		this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="profile">
				<form className="row" onSubmit={ this.submit }>	
					<div className="row col-12 container-form">
						<div className="col-md-6">
							<Input
								color="white"
								value={ this.state.form.name }
								name="name" 
								label="Nombre"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Input
								color="white"
								value={ this.state.form.lastname }
								name="lastname" 
								label="Apellido"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Input
								color="white"
								value={ this.state.form.email }
								name="email" 
								label="Correo Electrónico"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Input
								color="white"
								value={ this.state.form.username }
								name="username" 
								label="Nombre de Usuario"
								onChange={ this.change } />
						</div>
					</div>					
					<div className="col-12 text-center">
						<Button type="submit">
							Guardar
						</Button>													
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Profile);