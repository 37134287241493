import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Input, Select, CreateButton, Table, Pagination, Tooltip, Modal } from 'components';
import { TrashIcon, ViewIcon, EditIcon, Switch, SwitchGreen, ViewUserIcon, EditIconWhite } from 'assets/icons';
import { UserService } from 'services';
import { Globals, Constants } from 'utils';
import ModalViewUser from './modal-view';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	level_id: ''
}

class Users extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: INITIAL_STATE,
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Usuario',
			'Función',
			'Estatus',
			'Acción'
		],
		levels: [],
		user: null,
		visible: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Usuarios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Usuarios'
			}
		});

		this.loadLevels();
		this.load();
	}

	loadLevels = async () => {
		const res: any = await UserService.admin.getData({
			withoutLoading: true
		});
		this.setState({
			levels: res.levels
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await UserService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.users.rows,
			last_page: res.users.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	create = () => {
		this.props.history.push('/admin/users/create');
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			user: item
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.props.history.push('/admin/users/edit/' + item.id);
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el usuario?',async () => {
			await UserService.admin.delete({
				user_id: item.id
			});
			this.load(true);
		});
	}

	changeStatus = (item: any) => {
		Globals.confirm(`¿Desea ${ item.status == Constants.STATUS.ACTIVE ? 'desactivar' : 'activar' } el usuario?`,async () => {
			await UserService.admin.status({
				user_id: item.id
			});
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			user: null,
			visible: false
		});
	}
	
	render() {
		const { visible } = this.state;
		const user: any = this.state.user;

		return (
			<div id="users">
				<Modal
		          className="modal-view-user"
		          visible={ visible }
		          title={
	          		<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-user">
	      						<img src={ ViewUserIcon } className="view-user-icon" />
	      						<div>
			          				<h3>{ user?.name } { user?.lastname }</h3>
			          				<p>{ user?.level?.name }</p>
		          				</div>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<Tooltip title="Editar">
								<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,this.state.user) }>
									<img src={ EditIconWhite } />
								</a>
							</Tooltip>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onClose() }
		        >
		          <ModalViewUser
		          	user={ this.state.user }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-5">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-search-right"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-4">
						<Select
							name="level_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Cargo"
							value={ this.state.form.level_id }
							options={ this.state.levels.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-6"></div>
					<div className="col-md-6 text-right">
						<CreateButton onClick={ () => this.create() } />
					</div>
				</div>

				<Table title="Usuarios Registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>
									<p className="name">{ i.name }</p>
									<p className="email">{ i.email }</p>
								</td>
								<td>
									<p className="name">{ i.level?.name }</p>
									<p className="email">{ i.sublevel?.name }</p>
								</td>
								<td>
									<img onClick={ () => this.changeStatus(i) } src={ i.status == Constants.STATUS.ACTIVE ? SwitchGreen : Switch } className="image-switch" />
								</td>
								<td style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Users);