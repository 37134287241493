import React from 'react';
import { Input, ButtonsModal, PaperClip, Textarea, Checkbox, Select } from 'components';
import { HashIcon } from 'assets/icons';
import { CategoryService } from 'services';
import { Globals, Constants } from 'utils';
import { WhiteImage } from 'assets/img';

const WASHABLE = {
	YES: 1,
	NO: 0
}

const WEIGHABLE = {
	YES: 1,
	NO: 0
}

const FREEZABLE = {
	YES: 1,
	NO: 0
}

const ALCOHOLIC = {
	YES: 1,
	NO: 0
}

interface Props {
	onClose: () => void;
	category: any;
}

class ModalCreateCategory extends React.Component<Props> {

	state = {
		form: {
			name: '',
			code: '',
			image: '',
			description: '',
			washable: WASHABLE.NO,
			weighable: WEIGHABLE.NO,
			freezable: FREEZABLE.NO,
			alcoholic: ALCOHOLIC.NO,
			type_id: ''
		},
		types: []
	}

	componentDidMount() {
		const category = this.props.category;

		if (category) {
			this.setState({
				form: {
					...this.state.form,
					name: category?.name || '',
					code: category?.code || '',
					description: category?.description || '',
					washable: category?.washable || WASHABLE.NO,
					weighable: category?.weighable || WEIGHABLE.NO,
					freezable: category?.freezable || FREEZABLE.NO,
					alcoholic: category?.alcoholic || ALCOHOLIC.NO,
					type_id: category?.type_id || ''
				}
			});
		}

		this.load();
	}

	load = async () => {
		const res: any = await CategoryService.admin.getTypes();
		this.setState({
			types: res.types
		});
	}

	submit = async (e: any = null) => {
		if (e)
			e.preventDefault();
		if (this.props.category) {
			const res: any = await CategoryService.admin.update({
				...this.state.form,
				category_id: this.props.category?.id,
				hasFile: true,
				// @ts-ignore,
				image: this.state.form.image?.value || ''
			});
			Globals.showSuccess('Se ha modificado correctamente la categoría');
			this.props.onClose();
		}
		else {
			if (!this.state.form.image) {
				Globals.showError('Debe ingresar la imagen de la categoría');
				return false;
			}
			const res: any = await CategoryService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				image: this.state.form.image?.value || ''
			});
			Globals.showSuccess('Se ha creado correctamente la categoría');
			this.props.onClose();
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	render() {
		return (
			<div id="modal-create-category">
				<form onSubmit={ this.submit }>
					<div className="row content-form">
						<div className="col-md-6">
							<Input
								value={ this.state.form.name }
								name="name"
								label="Nombre"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<label htmlFor="code">Código</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<img src={ HashIcon } />
								</div>
								<Input
									maxLength={ 8 }
									value={ this.state.form.code }
									name="code"
									onChange={ this.change } />
							</div>
						</div>
						<div className="col-md-12">
							<Select
								label="Tipo"
								name="type_id"
								onChange={ this.change }
								value={ this.state.form.type_id }
								options={ this.state.types.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-12">
							<Textarea
								rows={ 3 }
								label="Descripción"
								name="description"
								onChange={ this.change }
			                    value={ this.state.form.description } />
						</div>
						<div className="col-md-12 weighable">
							<Checkbox
								label="Producto Pesable (Hortalizas, Frutas, Proteínas)"
								onChange={ () => {
									this.setState({
										form: {
											...this.state.form,
											weighable: this.state.form.weighable == WEIGHABLE.YES ? WEIGHABLE.NO : WEIGHABLE.YES,
											washable: WASHABLE.NO
										}
									});
								} }
								checked={ this.state.form.weighable == WEIGHABLE.YES }
								inline={ true } />
						</div>
						{
							this.state.form.weighable == WEIGHABLE.YES && (
								<div className="col-md-12">
									<Checkbox
										label="Producto Lavable (Hortalizas)"
										onChange={ () => {
											this.setState({
												form: {
													...this.state.form,
													washable: this.state.form.washable == WASHABLE.YES ? WASHABLE.NO : WASHABLE.YES
												}
											});
										} }
										checked={ this.state.form.washable == WASHABLE.YES }
										inline={ true } />
								</div>
							)
						}
						<div className="col-md-12">
							<Checkbox
								label="Producto Congelable"
								onChange={ () => {
									this.setState({
										form: {
											...this.state.form,
											freezable: this.state.form.freezable == FREEZABLE.YES ? FREEZABLE.NO : FREEZABLE.YES
										}
									});
								} }
								checked={ this.state.form.freezable == FREEZABLE.YES }
								inline={ true } />
						</div>
						<div className="col-md-12">
							<Checkbox
								label="Bebida Alcohólica"
								onChange={ () => {
									this.setState({
										form: {
											...this.state.form,
											alcoholic: this.state.form.alcoholic == ALCOHOLIC.YES ? ALCOHOLIC.NO : ALCOHOLIC.YES
										}
									});
								} }
								checked={ this.state.form.alcoholic == ALCOHOLIC.YES }
								inline={ true } />
						</div>
						<div className="col-md-12">
							<div className="logo-container">
								{/*
								// @ts-ignore */}
								<img className="selected-image" src={ this.state.form.image?.dataURL || (this.props.category?.image ? (Constants.BASE_STORAGE + this.props.category?.image) : WhiteImage) } />
								<PaperClip
									value={ this.state.form.image }
									successText="Imagen Adjuntada"
									pendingText="Adjuntar Imagen" 
									onChange={ (e: any) => {
										this.setState({
											form: {
												...this.state.form,
												image: e.target
											}
										})
									} } />
							</div>
						</div>
					</div>
					<ButtonsModal
						onClose={ () => this.props.onClose() } />
				</form>
			</div>
		)
	}
}

export default ModalCreateCategory;