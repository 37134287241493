import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon, ViewIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select, Modal, Tooltip } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import ModalView from './modal-view-works';
import { Globals } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class Works extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Responsable',
			'Asignadas',
			'Culminadas',
			'No Realizada',
			'Acciones'
		],
		form: INITIAL_STATE,
		visible: false,
		user: null,
		total_finish: 0,
		total_uncomplete: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Tareas Asignadas'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.works({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.users.rows,
			last_page: res.users.count,
			total_finish: res.total_finish || 0,
			total_uncomplete: res.total_uncomplete || 0
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	onClose = () => {
		this.setState({
			visible: false
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			user: item
		});
	}
	
	render() {
		const { visible, total_finish, total_uncomplete } = this.state;
		const user: any = this.state.user;

		return (
			<div id="reports-works">
				<Modal
		          className="modal-view-works"
		          visible={ visible }
		          title={
	          		<div className="row row-modal-title">
      					<div className="col-8">
      						<div className="container-view-order">
		          				<h3>{ user?.name + ' ' + user?.lastname }</h3>
		          			</div>
	          			</div>
	          			<div className="col-4 text-right">
	          				<a href="#" className="close-modal-view" onClick={ (e: any) => {
	          					e.preventDefault();
	          					this.onClose();
	          				} }>
	          					<i className="fa fa-close" />
	          				</a>
	          			</div>
	          		</div>
		          }
		          onClose={ () => this.onClose() }
		        >
		          <ModalView
		          	user={ user }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row">
					<div className="col-md-8">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por nombre"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="text-right">
					<div className="container-total">
						<p><strong>Tareas culminadas:</strong> { total_finish }</p>
						<p><strong>Tareas no realizadas:</strong> { total_uncomplete }</p>
					</div>
				</div>

				<Table title="Reporte de Tareas Asignadas" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.name + ' ' + i?.lastname }</td>
								<td>{ i.works_assigned.length }</td>
								<td>{ Globals.formatMiles((i.works_finished.length / i.works.length) * 100,true,'') }%</td>
								<td>{ Globals.formatMiles((i.works_cancelled.length / i.works.length) * 100,true,'') }%</td>
								<td>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Works);