import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker } from 'components';
import { ReportService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	since: '',
	until: ''
}

class Operations extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Nº Plan',
			'Producto fuente',
			'Cantidad (Kg)',
			'Producto procesado',
			'Tipo de Empaque',
			'Porciones por Empaque',
			'Cantidad de Empaques'
		],
		form: INITIAL_STATE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Reporte Diario de operaciones'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.operations({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD')
		});
		this.setState({
			data: res.work_order_details.rows,
			last_page: res.work_order_details.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="reports-operations">
				<div className="row">
					<div className="col-md-6">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Búsqueda por número de plan"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Desde"
							showYearDropdown={ true }
							maxDate={ moment(this.state.form.until || moment()).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										since: text
									}						
								},this.load);
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							placeholder="Hasta"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							minDate={ this.state.form.since && moment(this.state.form.since).toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										until: text
									}						
								},this.load);
							} }
							value={ this.state.form.until }
						/>
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<Table title="Reporte Diario de operaciones" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.order?.number }</td>
								<td className="name">
									<p>{ i?.origin_product?.name }</p>
									{ i?.origin_presentation != null && <span>{ i?.origin_presentation?.name }</span> }
									<span className="code">{ i?.origin_product?.code }</span>
								</td>
								<td>{ i?.origin_weight }</td>
								<td className="name">
									<p>{ i?.destiny_product?.name }</p>
									{ i?.destiny_presentation != null && <span>{ i?.destiny_presentation?.name }</span> }
									<span className="code">{ i?.destiny_product?.code }</span>
								</td>
								<td>{ i?.package?.name }</td>
								<td>{ i?.quantity_per_package }</td>
								<td>{ i?.package_quantity }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Operations);