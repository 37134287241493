import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { WorkService } from 'services';
import { ContainerRed, Button, Input, Select, DatePicker, Tooltip, SelectSearch } from 'components';
import { Globals, Constants } from 'utils';
import { CalendarWhite, TimeIcon, Switch, SwitchGreen, CheckRedIcon, TrashIcon } from 'assets/icons';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const URGENT = {
	YES: 1,
	NO: 0
}

class CreateWork extends React.Component<Props> {

	state = {
		form: {
			user_id: '',
			date: '',
			hour: '',
			work: '',
			urgent: URGENT.NO,
			description: ''
		},
		items: [],
		users: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Operaciones y tareas',
				onBack: () => {
					this.props.history.push('/admin/works');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Operaciones y tareas / Crear tarea`
			}
		});

		this.getUsers();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	getUsers = async () => {
		const res: any = await WorkService.admin.getUsers();
		this.setState({
			users: res.users
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.state.items.length == 0) {
			Globals.showError("Debe ingresar al menos una tarea");
			return false;
		}

		if (!this.state.form.date) {
			Globals.showError("Debe ingresar la fecha para continuar");
			return false;
		}

		if (!this.state.form.hour) {
			Globals.showError("Debe ingresar la hora para continuar");
			return false;
		}

		if (moment(moment(this.state.form.date).format('YYYY-MM-DD') + ' ' + moment(this.state.form.hour).format('HH:mm')) < moment()) {
			Globals.showError("Debe ingresar una fecha y hora mayores a la actual");
			return false;
		}

		await WorkService.admin.create({
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date,'DD/MM/YYYY').format('YYYY-MM-DD'),
			hour: this.state.form.hour && moment(this.state.form.hour).format('HH:mm:ss'),
			items: this.state.items,
			created_by_id: this.props.user?.id
		});
		Globals.showSuccess("Se ha registrado la tarea correctamente");
		this.props.history.push('/admin/works');
	}

	addItem = () => {
		if (this.state.form.work) {
			let items: any = [...this.state.items];
			items.push(this.state.form.work);
			this.setState({
				items,
				form: {
					...this.state.form,
					work: ''
				}
			});
		}
	}

	removeItem = (index: number) => {
		let items: any = [...this.state.items];
		items.splice(index,1);
		this.setState({
			items
		});
	}
	
	render() {
		const { items } = this.state;
		const user: any = this.state.users.find((i: any) => i.id == this.state.form.user_id);

		return (
			<div id="create-work">
				<ContainerRed title="Tarea">
					<SelectSearch
						placeholder="Seleccionar trabajador"
						onChange={ (user: any) => {
							this.setState({
								form: {
									...this.state.form,
									user_id: user.value
								}
							})
						} }
						color="gray"
						searchRight
						value={ user ? {
							value: user.id,
							label: (user?.sublevel_id == Constants.SUBLEVELS.SUPERVISOR ? '(Supervisor) ' : '') + user.level?.name + ' - ' + user.name + ' ' + user.lastname
						} : null }
						options={ this.state.users.map((item: any) => {
							return {
								value: item.id,
								label: (item?.sublevel_id == Constants.SUBLEVELS.SUPERVISOR ? '(Supervisor) ' : '') + item.level?.name + ' - ' + item.name + ' ' + item.lastname
							}
						}) } />
					<hr />
					<Input 
						value={ this.state.form.description }
						name="description" 
						placeholder="Escribir asunto (Opcional)"
						onChange={ this.change } />
					<div className="row row-add">
						<div className="col-10">
							<Input 
								value={ this.state.form.work }
								name="work" 
								placeholder="Asignar tarea"
								onKeyDown={ (e: any) => {
									if (e.key === 'Enter') {
										this.addItem();
									}
								} }
								onChange={ this.change } />
						</div>
						<div className="col-2 text-right">
							<img src={ CheckRedIcon } onClick={ this.addItem } />
						</div>
					</div>
					{
						items.map((item: string,index: number) => {
							return (
								<div className="item">
									<div style={{ flex: .8 }}>
										<p>{ item }</p>
									</div>
									<div style={{ flex: .2 }} className="text-right">
										<Tooltip title="Eliminar">
											<img src={ TrashIcon } className="trash" onClick={ () => {
												this.removeItem(index);
											} } />
										</Tooltip>
									</div>									
								</div>
							)
						})
					}

					{
						items.length == 0 && (
							<p className="no-items">No hay tareas asignadas</p>
						)
					}
					<hr />
					<p className="bold">Tiempo de tarea</p>
					<p className="subtitle">Seleccionar fecha y hora de inicio</p>
					<div className="row">
						<div className="col-md-6">
							<DatePicker
								calendarIcon={ CalendarWhite }
								placeholder="Fecha"
								showYearDropdown={ true }
								minDate={ moment().toDate() }
								onChange={ (date: string) => {
									this.setState({
										form: {
											...this.state.form,
											date
										}						
									});
								} }
								value={ this.state.form.date }
							/>
						</div>
						<div className="col-md-6">
							<DatePicker
								placeholder="Hora"
								showTimeSelect={ true }
								showTimeSelectOnly={ true }
								timeCaption="Hora"
								calendarIcon={ TimeIcon }
								onChange={ (hour: string) => {
									this.setState({
									form: {
										...this.state.form,
										hour
									}						
								});
								} }
								dateFormat="h:mm aa"
								value={ this.state.form.hour }
							/>
						</div>
					</div>
					<form className="row" onSubmit={ this.submit }>
						<div className="col-12 text-center">
							<Button type="submit">
								Guardar
							</Button>
						</div>
					</form>
				</ContainerRed>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(CreateWork);