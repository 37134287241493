import React from 'react';
import { ReportService } from 'services';
import { Pagination, Table, DatePicker, Select } from 'components';
import moment from 'moment';
import { Constants } from 'utils';

const OPTIONS: any = [
	{ value: Constants.WORK_REPORT_STATUS.PENDING, label: 'Pendientes' },
	{ value: Constants.WORK_REPORT_STATUS.FINISH, label: 'Culminadas' },
	{ value: Constants.WORK_REPORT_STATUS.CANCELLED, label: 'Canceladas' },
];

interface Props {
	onClose: () => void;
	user: any;
}

const INITIAL_STATE = {
	date: '',
	status: ''
}

class ModalViewWorks extends React.Component<Props> {

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Fecha',
			'Nombre',
			'Estatus',
			'Hora de Inicio',
			'Hora de Fin',
			'Observaciones'
		],
		form: INITIAL_STATE,
	}

	componentDidMount() {
		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.viewWorks({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			user_id: this.props.user?.id,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD')
		});
		this.setState({
			data: res.works.rows,
			last_page: res.works.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	render() {
		return (
			<div id="modal-view-works">
				<div className="row">
					<div className="col-md-4">
						<DatePicker
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},() => this.load(true));
							} }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-4">
						<Select
							name="status"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							placeholder="Todas"
							value={ this.state.form.status }
							options={ OPTIONS } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}
				<Table title="Tareas" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						let status_text = 'Pendiente';
						
						if (i.status == Constants.WORKS_STATUS.FINISH) {
							status_text = 'Culminada';

							if (i.details.filter((i: any) => i.status == Constants.WORKS_STATUS.PENDING).length > 0) {
								status_text = 'Incompleto';
							}
						}

						if (i.cancel_by_cron) {
							status_text = 'Cancelada';
						}

						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>
									<ul>
										{
											i.details.map((i: any) => {
												return (
													<li>{ i.name }</li>
												)
											})
										}
									</ul>
								</td>
								<td>{ status_text }</td>
								<td>{ i.init_date ? moment(i.init_date).format('hh:mm A') : '-' }</td>
								<td>{ i.finish_date ? moment(i.finish_date).format('hh:mm A') : '-' }</td>
								<td>{ i.comments }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default ModalViewWorks;