import React from 'react';
import { CheckGreenIcon, AddIcon } from 'assets/icons';
import { Table, Tooltip, Modal } from 'components';
import { TrashIcon, ViewIcon } from 'assets/icons';
import { Globals } from 'utils';
import { WorkService } from 'services';
import ModalViewWork from 'screens/admin/works/modal-view';

interface Props {
	works: any;
	load: () => void;
	count: number;
	onCreate: () => void;
	onViewMore: () => void;
}

class Works extends React.Component<Props> {

	state = {
		header: [
			'Trabajador',
			'Estatus',
			'Acción'
		],
		work: null,
		visible: false
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			work: item
		});
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la tarea?',async () => {
			await WorkService.admin.delete({
				work_id: item.id
			});
			this.props.load();
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			work: null
		});
	}
	
	render() {
		const { visible } = this.state;
		const work: any = this.state.work;

		return (
			<div id="home-works">
				<Modal
		          className="modal-view-work"
		          visible={ visible }
		          title={ work?.user?.name + ' ' + work?.user?.lastname } 
		          onClose={ () => this.onClose() }
		        >
		          <ModalViewWork
		          	work={ work }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="title-home">
					<div style={{ flex: .5 }}>
						<h3>Tareas registradas</h3>
						<div className="count-container">
							<img src={ CheckGreenIcon } />
							<p>{ this.props.count } Registradas</p>
						</div>
					</div>
					<div style={{ flex: .5 }} className="text-right">
						<div className="add-item" onClick={ () => {
							this.props.onCreate();
						} }>
							<img src={ AddIcon } />
							<p>Crear</p>
						</div>
					</div>
				</div>
				<Table title="" data={ this.props.works.length } header={ this.state.header }>
					{ this.props.works.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.user?.name + ' ' + i?.user?.lastname }</td>
								<td>{ i?.status_text }</td>
								<td>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>
				{
					this.props.count > this.props.works.length && (
						<div className="text-center">
							<a href="#" onClick={ (e: any) => {
								e.preventDefault();
								this.props.onViewMore();
							} } className="view-more">
								Ver Más
							</a>
						</div>
					)
				}
			</div>
		)
	}
}

export default Works;