import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory, Link } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { 
	ArrowLeft, 
	UserIcon, 
	NotificationIcon, 
	ProfileIcon,
	LockIcon,
	LogoutIcon
} from 'assets/icons';

interface Props {
	className?: string
}

const logout = (dispatch: any,history: any) => {
	Globals.confirm('¿Desea cerrar sesión?',() => {
		dispatch({
			type: 'SET_USER',
			payload: null
		});
		history.replace('/login');
		Globals.quitLoading();
	});
}

const Header = (props: Props) => {
	const header = useSelector((state: RootState) => state.header);
	const subheader = useSelector((state: RootState) => state.subheader);
	const user = useSelector((state: RootState) => state.user);
	const [open,setOpen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	return (
		<div id="header" className={ props.className }>
			<div className="container-blue-top">
				<div className="container-back-top">
					{
						header.onBack && (
							<div className="arrow-back" onClick={ header.onBack }>
								<img src={ ArrowLeft } />
							</div>
						)
					}
					<div className="container-titles">
						<h3 className="title-top">{ header.title }</h3>
						<h4 className="subtitle-top">{ subheader.title }</h4>
					</div>	
				</div>
				<div className="container-buttons-top">
					<button className="focusable-container" onBlur={ () => setOpen(false) }>
						{
							user && (
								<div className="container-user" onClick={ () => setOpen(!open) }>
									<img className="avatar-image" src={ UserIcon } />
									<p>{ user?.name }</p>
									<i className="fa fa-caret-down"></i>
								</div>
							)
						}
						{
							open && (
								<ul>
									<li onClick={ () => { setOpen(false); history.replace('/profile') } }>
										<img src={ ProfileIcon } />
										Mis Datos
									</li>
									<li onClick={ () => { setOpen(false); history.replace('/password') } }>
										<img src={ LockIcon } />
										Cambiar Contraseña
									</li>
									<li onClick={ () => { setOpen(false); logout(dispatch,history) } }>
										<img src={ LogoutIcon } />
										Cerrar Sesión
									</li>
								</ul>
							)
						}		
					</button>
					<a href="#" onClick={ (e: any) => e.preventDefault() }>
						<img className="notification-icon" src={ NotificationIcon } />
					</a>
				</div>
			</div>		
		</div>
	)	
}

export default Header;