import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { HomeIcon } from 'assets/icons';
import { Table, Pagination, Input, DatePicker, Select, DownloadButton } from 'components';
import moment from 'moment';
import { ReportService } from 'services';
import { Globals, Constants, Print } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class Location extends React.Component<Props> {

	timer: any = undefined;

	state = {
		page: 1,
		last_page: 1,
		data: [],
		header: [
			'Nombre',
			'Código',
			'Existencia',
			'Almacén',
			'U. Almacenamiento'
		],
		form: INITIAL_STATE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reportes',
				onBack: () => {
					this.props.history.push('/admin/reports');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: 'Reportes / Inventario y Ubicación'
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.location({
			withoutLoading,
			page: this.state.page,
			...this.state.form
		});
		this.setState({
			data: res.location.rows,
			last_page: res.location.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	download = async () => {
		const res: any = await ReportService.admin.exportLocation({
			...this.state.form,
		});
		Print(Constants.BASE_STORAGE + res.url);
	}
	
	render() {
		return (
			<div id="reports-reception">
				<div className="row">
					<div className="col-md-5">
						<Input
							searchRight
							value={ this.state.form.search }
							name="search"
							placeholder="Buscar por nombre o código"
							className="input-table"
							onSubmit={ () => this.load() }
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
				</div>
				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<div className="text-right" style={{ marginTop: '20px' }}>
					<DownloadButton onClick={ () => this.download() } />
				</div>

				<Table title="Inventario y Ubicación" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.detail?.product?.name }</td>
								<td>{ i?.detail?.product?.code }</td>
								<td>{ Globals.formatMiles(i?.weight,true,'') + ' ' + (i?.detail?.presentation?.unit?.name || i?.detail?.product?.unit?.name || '') }</td>
								<td>{ i?.container?.warehouse?.name }</td>
								<td>{ i?.container?.name }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(Location);