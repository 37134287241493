import React from 'react';
import { Constants } from 'utils';
import { Tooltip } from 'components';
import { EditIconRed } from 'assets/icons';

interface Props {
	onClose: () => void;
	product: any;
	onEdit: () => void;
}

class ModalViewProduct extends React.Component<Props> {

	render() {
		return (
			<div id="modal-view-product">
				<div className="row">
					<div className="col-md-6">
						<img className="image-category" src={ Constants.BASE_STORAGE + this.props.product?.image } />
					</div>
					<div className="col-md-6">
						<h3>{ this.props.product?.name }</h3>
						<p className="code"># { this.props.product?.code }</p>
						<p className="bold">Descripción</p>
						<p>{ this.props.product?.description }</p>
						<p className="bold red">{ this.props.product?.category?.name }</p>
						<div className="row">
							<div className="col-6">
								<p className="bold stock-title">Stock Mínimo</p>
								<p className="stock">{ this.props.product?.minimum_stock } { this.props.product?.unit?.name }</p>
							</div>
							<div className="col-6">
								<div className="text-right">
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => {
											e.preventDefault();
											this.props.onEdit();
										} }>
											<img src={ EditIconRed } />
										</a>
									</Tooltip>
								</div>
							</div>
						</div>						
					</div>
				</div>
			</div>
		)
	}
}

export default ModalViewProduct;