import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Select, ContainerRed, Button, Input, DatePicker, SelectSearch, Tooltip, Modal } from 'components';
import { ChecklistService } from 'services';
import { Constants, Globals, GetDay } from 'utils';
import { TimeIcon, CheckRedIcon, TrashIcon, CalendarWhite } from 'assets/icons';
import ModalDays from './modal-days';
import moment from 'moment';

interface MatchParams {
	checklist_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

const CONDITIONED_OPTIONS: any = [
	{ value: 1, label: 'Si' },
	{ value: 0, label: 'No' }
];

const CONDITIONED = {
	YES: 1,
	NO: 0
}

class CreateChecklist extends React.Component<Props> {

	state = {
		form: {
			name: '',
			level_id: '',
			since: '',
			until: '',
			supervisor_id: '',
			conditioned: CONDITIONED.NO,
			work: ''
		},
		days: {
			monday: false,
			tuesday: false,
		    wednesday: false,
		    thursday: false,
		    friday: false,
		    saturday: false,
		    sunday: false
		},
		items: [],
		levels: [],
		users: [],
		visible: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Checklist',
				onBack: () => {
					this.props.history.push('/admin/checklist');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Checklist / ${ this.props.match.params.checklist_id ? 'Editar' : 'Crear' } Checklist`
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ChecklistService.admin.getUsers({
			checklist_id: this.props.match.params.checklist_id
		});
		this.setState({
			levels: res.levels,
			users: res.users
		},() => {
			if (res.checklist) {
				this.setState({
					form: {
						...this.state.form,
						name: res.checklist?.name || '',
						level_id: res.checklist?.level_id || '',
						since: res.checklist?.since ? moment(res.checklist?.since,'HH:mm:ss').toDate() : '',
						until: res.checklist?.until ? moment(res.checklist?.until,'HH:mm:ss').toDate() : '',
						supervisor_id: res.checklist?.supervisor_id || '',
						conditioned: res.checklist?.conditioned || CONDITIONED.NO
					},
					items: res.checklist?.details.map((i: any) => {
						return {
							id: i.id,
							name: i.name
						}
					}),
					days: {
						monday: res.checklist?.days?.monday || false,
						tuesday: res.checklist?.days?.tuesday || false,
					    wednesday: res.checklist?.days?.wednesday || false,
					    thursday: res.checklist?.days?.thursday || false,
					    friday: res.checklist?.days?.friday || false,
					    saturday: res.checklist?.days?.saturday || false,
					    sunday: res.checklist?.days?.sunday || false
					},
				});
			}
			else if (this.props.match.params.checklist_id) {
				this.props.history.push('/admin/checklist');
			}
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.state.items.length == 0) {
			Globals.showError("Debe ingresar al menos una tarea");
			return false;
		}

		// @ts-ignore
		if (Object.keys(this.state.days).filter((i: string) => this.state.days[i] == true).length == 0) {
			Globals.showError("Debe ingresar al menos un día de la semana");
			return false;
		}

		if (this.state.form.conditioned == CONDITIONED.YES && !this.state.form.supervisor_id) {
			Globals.showError("Debe ingresar el supervisor de la tarea");
			return false;
		}

		if (this.props.match.params.checklist_id) {
			await ChecklistService.admin.edit({
				checklist_id: this.props.match.params.checklist_id,
				...this.state.form,
				items: this.state.items,
				since: this.state.form.since && moment(this.state.form.since).format('HH:mm:ss'),
				until: this.state.form.until && moment(this.state.form.until).format('HH:mm:ss'),
				days: this.state.days
			});
			Globals.showSuccess("Se ha modificado el checklist correctamente");
			this.props.history.push('/admin/checklist');
		}
		else {
			await ChecklistService.admin.create({
				...this.state.form,
				items: this.state.items,
				since: this.state.form.since && moment(this.state.form.since).format('HH:mm:ss'),
				until: this.state.form.until && moment(this.state.form.until).format('HH:mm:ss'),
				days: this.state.days
			});
			Globals.showSuccess("Se ha creado el checklist correctamente");
			this.props.history.push('/admin/checklist');
		}
	}

	addItem = () => {
		if (this.state.form.work) {
			let items: any = [...this.state.items];
			items.push({
				name: this.state.form.work
			});
			this.setState({
				items,
				form: {
					...this.state.form,
					work: ''
				}
			});
		}
	}

	removeItem = (index: number) => {
		let items: any = [...this.state.items];
		items.splice(index,1);
		this.setState({
			items
		});
	}

	onClose = (days = null) => {
		this.setState({
			visible: false
		},() => {
			if (days) {
				this.setState({
					days
				});
			}
		});
	}
	
	render() {
		const supervisor: any = this.state.users.find((i: any) => i.id == this.state.form.supervisor_id);
		const { items, visible } = this.state;
		// @ts-ignore
		const days_active = Object.keys(this.state.days).filter((key: string) => this.state.days[key] == true).length;
		// @ts-ignore
		const days = Object.keys(this.state.days).filter((key: string) => this.state.days[key] == true).map((key: string) => GetDay(key,true)).join(', ');

		return (
			<div id="create-checklist">
				<Modal
		          className="modal-checklist-days"
		          visible={ visible }
		          title="Seleccionar días"
		          onClose={ () => this.onClose() }
		        >
		          <ModalDays
		          	days={ this.state.days }
		            onClose={ (days: any) => this.onClose(days) } />
		      	</Modal>

				<ContainerRed title={ `${ this.props.match.params.checklist_id ? 'Editar' : 'Crear' } Checklist` }>	
					<form className="row" onSubmit={ this.submit }>
						<div className="col-md-6">
							<Select
								label="Área"
								name="level_id"
								onChange={ this.change }
								value={ this.state.form.level_id }
								options={ this.state.levels.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-6">
							<Input
								value={ this.state.form.name }
								name="name"
								label="Nombre"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Select
								label="Condicionada"
								name="conditioned"
								onChange={ (e: any) => this.change(e,() => {
									this.setState({
										form: {
											...this.state.form,
											supervisor_id: ''
										}
									});
								}) }
								value={ this.state.form.conditioned }
								options={ CONDITIONED_OPTIONS } />
						</div>
						<div className="col-md-6">
							{
								this.state.form.conditioned == CONDITIONED.YES && this.state.form.level_id && (
									<SelectSearch
										label="Supervisor"
										onChange={ (supervisor: any) => {
											this.setState({
												form: {
													...this.state.form,
													supervisor_id: supervisor.value
												}
											});
										} }
										color="gray"
										searchRight
										value={ supervisor ? {
											value: supervisor.id,
											label: supervisor.name + ' ' + supervisor.lastname
										} : null }
										options={ this.state.users.filter((i: any) => i.level_id == this.state.form.level_id).map((i: any) => {
											return {
												value: i.id,
												label: i.name + ' ' + i.lastname
											}
										}) } />
								)
							}
						</div>
						<div className="col-12">
							<hr />
						</div>
						<div className="col-12">
							<div className="row row-add">
								<div className="col-10">
									<Input 
										value={ this.state.form.work }
										name="work" 
										placeholder="Asignar tarea"
										onKeyDown={ (e: any) => {
											if (e.key === 'Enter') {
												this.addItem();
											}
										} }
										onChange={ this.change } />
								</div>
								<div className="col-2 text-right">
									<img src={ CheckRedIcon } onClick={ this.addItem } />
								</div>
							</div>
							{
								items.map((item: any,index: number) => {
									return (
										<div className="item">
											<div style={{ flex: .8 }}>
												<p>{ item?.name }</p>
											</div>
											<div style={{ flex: .2 }} className="text-right">
												<Tooltip title="Eliminar">
													<img src={ TrashIcon } className="trash" onClick={ () => {
														this.removeItem(index);
													} } />
												</Tooltip>
											</div>									
										</div>
									)
								})
							}

							{
								items.length == 0 && (
									<p className="no-items">No hay tareas asignadas</p>
								)
							}
						</div>
						<div className="col-12">
							<hr />
						</div>
						<div className="col-md-4">
							<div className="selected-days" onClick={ () => {
								this.setState({
									visible: true
								});
							} }>
								<img src={ CalendarWhite } />
								<p>{ days_active ? days : 'Seleccionar días' }</p>
							</div>
						</div>
						<div className="col-md-4">
							<DatePicker
								placeholder="Desde"
								showTimeSelect={ true }
								showTimeSelectOnly={ true }
								timeCaption="Hora"
								calendarIcon={ TimeIcon }
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'since'
										}
									});
								} }
								dateFormat="h:mm aa"
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-4">
							<DatePicker
								placeholder="Hasta"
								showTimeSelect={ true }
								showTimeSelectOnly={ true }
								timeCaption="Hora"
								calendarIcon={ TimeIcon }
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'until'
										}
									});
								} }
								dateFormat="h:mm aa"
								value={ this.state.form.until }
							/>
						</div>
						<div className="col-12 text-center">
							<Button type="submit">
								Guardar
							</Button>
						</div>
					</form>
				</ContainerRed>
			</div>
		)
	}
}

export default connect()(CreateChecklist);