import React from 'react';
import { Input, ButtonsModal } from 'components';
import { HashIcon } from 'assets/icons';
import { DepartmentService } from 'services';
import { Globals } from 'utils';

interface Props {
	onClose: () => void;
	department: any;
}

class ModalCreateDepartment extends React.Component<Props> {

	state = {
		form: {
			name: '',
			code: ''
		}
	}

	componentDidMount() {
		const department = this.props.department;

		if (department) {
			this.setState({
				form: {
					...this.state.form,
					name: department?.name || '',
					code: department?.code || ''
				}
			});
		}
	}

	submit = async (e: any = null) => {
		if (e)
			e.preventDefault();
		if (this.props.department) {
			const res: any = await DepartmentService.admin.update({
				...this.state.form,
				department_id: this.props.department?.id
			});
			Globals.showSuccess('Se ha modificado correctamente el departamento');
			this.props.onClose();
		}
		else {
			const res: any = await DepartmentService.admin.create({
				...this.state.form
			});
			Globals.showSuccess('Se ha creado correctamente el departamento');
			this.props.onClose();
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	render() {
		return (
			<div id="modal-create-department">
				<form onSubmit={ this.submit }>
					<div className="row content-form">
						<div className="col-md-12">
							<Input
								value={ this.state.form.name }
								name="name"
								label="Nombre"
								onChange={ this.change } />
						</div>
						<div className="col-md-12">
							<label htmlFor="code">Código</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<img src={ HashIcon } />
								</div>
								<Input
									maxLength={ 8 }
									value={ this.state.form.code }
									name="code"
									onChange={ this.change } />
							</div>
						</div>
					</div>
					<ButtonsModal
						onClose={ () => this.props.onClose() } />
				</form>
			</div>
		)
	}
}

export default ModalCreateDepartment;