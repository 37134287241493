import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { ConditionedService } from 'services';
import { ContainerRed, Button, Input, Select, DatePicker, Tooltip, SelectSearch, Pagination } from 'components';
import { Globals, Constants } from 'utils';
import moment from 'moment';
import { TrashIcon, CloseIcon, ArrowUp, ArrowBottom } from 'assets/icons';

interface MatchParams {
	conditioned_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

const ITEMS = {
	WEIGHT: 1,
	DECREASE: 2
}

class CreateConditioned extends React.Component<Props> {

	timer: any = undefined;

	state = {
		form: {
			search: '',
			name: '',
			department_id: ''
		},
		items: [],
		operations: [],
		departments: [],
		selected: ITEMS.DECREASE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Acondicionado',
				onBack: () => {
					this.props.history.push('/admin/production/conditioned');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Acondicionado / ${ this.props.match.params.conditioned_id ? 'Editar' : 'Crear' }`
			}
		});

		this.load();
		this.loadData();
	}

	loadData = async (withoutLoading: boolean = false) => {
		const res: any = await ConditionedService.admin.view({
			withoutLoading: true,
			conditioned_id: this.props.match.params.conditioned_id
		});
		this.setState({
			withoutLoading: true,
			departments: res.departments
		},() => {
			if (res.conditioned) {
				this.setState({
					form: {
						...this.state.form,
						department_id: res.conditioned?.department_id || '',
						name: res.conditioned?.name || '',
					},
					items: res.conditioned?.details.map((item: any) => {
						return {
							id: item.id,
							operation_name: item.operation?.name || '',
							operation_id: item.operation_id || ''
						}
					})
				});
			}
			else if (this.props.match.params.conditioned_id) {
				this.props.history.push('/admin/production/conditioned');
			}
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ConditionedService.admin.getData({
			withoutLoading,
			...this.state.form
		});
		this.setState({
			operations: res.operations
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.match.params.conditioned_id) {
			await ConditionedService.admin.update({
				conditioned_id: this.props.match.params.conditioned_id,
				...this.state.form,
				items: this.state.items,
			});
			Globals.showSuccess("Se ha modificado el acondicionado correctamente");
			this.props.history.push('/admin/production/conditioned');
		}
		else {
			await ConditionedService.admin.create({
				...this.state.form,
				items: this.state.items,
			});
			Globals.showSuccess("Se ha registrado el acondicionado correctamente");
			this.props.history.push('/admin/production/conditioned');
		}
	}

	reset = () => {
		this.setState({
			form: {
				...this.state.form,
				search: ''
			},
			page: 1
		},() => this.load(true));
	}

	add = (item: any) => {
		let items: any = [...this.state.items];
		if (items.map((i: any) => i.operation_id).indexOf(item.id) == -1) {
			items.push({
				operation_id: item.id,
				operation_name: item.name
			});
			this.setState({
				items
			});
		}
	}

	remove = (index: number) => {
		let items: any = [...this.state.items];
		items.splice(index,1);
		this.setState({
			items
		});
	}
	
	render() {
		return (
			<div id="create-conditioned">
				<div className="row row-container">
					<div className="col-md-8 col-white">
						<div style={{ padding: '20px' }}>
							<h3>Acondicionado</h3>
							<div className="row">
								<div className="col-md-6">
									<Input
										className="input-table"
										value={ this.state.form.name }
										name="name"
										label="Nombre del proceso"
										onChange={ (e: any) => this.change(e) } />
								</div>
								<div className="col-md-6">
									<Select
										name="department_id"
										onChange={ (e: any) => this.change(e) }
										label="Departamento"
										value={ this.state.form.department_id }
										options={ this.state.departments.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
								</div>
							</div>
						</div>
						{
							this.state.items.length > 0 && (
								<React.Fragment>
									<table style={{ width: '100%' }}>
										<thead>
											<tr>
												<th style={{ width: '100%' }}>Actividades</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.items.map((item: any,index: number) => {
													return (
														<tr>
															<td style={{ width: '100%' }}>
																<div style={{ display: 'flex' }}>
																	<img src={ CloseIcon } className="close-icon" onClick={ () => this.remove(index) } />
																	<p>{ item.operation_name }</p>
																</div>
															</td>
														</tr>
													)
												})
											}
										</tbody>
									</table>
									<div className="text-center">
										<Button type="submit" onClick={ this.submit }>
											Guardar
										</Button>
									</div>
								</React.Fragment>
							)
						}
						{
							this.state.items.length == 0 && (
								<p className="no-items">No se han agregado registros</p>
							)
						}
					</div>
					<div className="col-md-4 col-black">
						<div style={{ padding: '20px' }}>
							<Input
								searchRight
								value={ this.state.form.search }
								name="search"
								placeholder="Búsqueda por nombre"
								className="input-table"
								onSubmit={ () => this.load() }
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
							{
								this.state.form.search != '' && (
									<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
								)
							}
						</div>
						<div className="product-container">
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.WEIGHT ? null : ITEMS.WEIGHT
								});
							} }>
								<p>Peso</p>
								<img src={ this.state.selected == ITEMS.WEIGHT ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.WEIGHT && this.state.operations.filter((i: any) => i.category_id == Constants.OPERATION_CATEGORIES.WEIGHT).map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item) }>{ item.name }</p>
									)
								})
							}
							<div className="item-operation" onClick={ () => {
								this.setState({
									selected: this.state.selected == ITEMS.DECREASE ? null : ITEMS.DECREASE
								});
							} }>
								<p>Merma</p>
								<img src={ this.state.selected == ITEMS.DECREASE ? ArrowUp : ArrowBottom } className="arrow" />
							</div>
							{
								this.state.selected == ITEMS.DECREASE && this.state.operations.filter((i: any) => i.category_id == Constants.OPERATION_CATEGORIES.DECREASE).map((item: any) => {
									return (
										<p className="item-product" onClick={ () => this.add(item) }>{ item.name }</p>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(CreateConditioned);