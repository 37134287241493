import React from 'react';
import { Button, Input, Table } from 'components';
import { PlanService } from 'services';
import moment from 'moment';

interface Props {
	onClose: () => void;
	plan: any;
}

class ModalViewPlan extends React.Component<Props> {

	state = {
		plan: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await PlanService.admin.getPlan({
			plan_id: this.props.plan?.id
		});
		this.setState({
			plan: res.plan
		});
	}

	render() {
		const plan: any = this.state.plan;

		return (
			<div id="modal-view-plan">
				<div className="container-form">
					<div className="row">
						<div className="col-md-8">
							<Input
								className="input-top"
								value={ plan?.name }
								label="Nombre del plan"
								disabled={ true } />
						</div>
						<div className="col-md-4">
							<Input
								className="input-top"
								value={ plan?.department?.name }
								label="Departamento"
								disabled={ true } />
						</div>
					</div>
				</div>

				<div className="table-bottom">
					<Table title="Ordenes de trabajo" data={ plan?.details?.length } header={ [
						'Producto final',
						'Presentación',
						'Producción porciones',
						'Producto fuente',
						'Kg a procesar',
						'Fecha'
					] }>
						{ plan?.details?.map((i: any,index: number) => {
							return (
								<tr key={ index }>
									<td className="name">{ i?.destiny_product?.name }</td>
									<td>{ i?.destiny_presentation?.name }</td>
									<td>{ i?.destiny_weight }</td>
									<td>{ i?.origin_product?.name }</td>
									<td>{ i?.origin_weight }</td>
									<td>{ moment(i?.date).format('DD-MM-YYYY HH:mm') }</td>
								</tr>
							)
						}) }
					</Table>
				</div>

				<div className="text-center">
					<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
						Cerrar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalViewPlan;