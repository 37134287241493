import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Select, ContainerRed, Button, Input, DatePicker } from 'components';
import { ProviderService } from 'services';
import { HashIcon, PhoneIcon, LocationIcon, MailIcon, TimeIcon } from 'assets/icons';
import { Constants, Globals } from 'utils';
import moment from 'moment';

interface MatchParams {
	provider_id: string
}

interface Props extends RootState, RouteComponentProps<MatchParams> {
	dispatch: any
}

class CreateProvider extends React.Component<Props> {

	state = {
		form: {
			name: '',
            comercial_designation: '',
            document: '',
            document_type_id: Constants.DOCUMENT_TYPES.V,
            email: '',
            address: '',
            phone: '',
            state_id: '',
            municipality: '',
            contact_person: '',
            schedule_start: '',
            schedule_end: '',
            code: ''
		},
		document_types: [],
		states: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Proveedores',
				onBack: () => {
					this.props.history.push('/admin/providers');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: `Proveedores / ${ this.props.match.params.provider_id ? 'Editar' : 'Crear' } Proveedor`
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ProviderService.admin.getData({
			provider_id: this.props.match.params.provider_id
		});
		this.setState({
			document_types: res.document_types,
			states: res.states
		},() => {
			if (res.provider) {
				this.setState({
					form: {
						name: res.provider?.name || '',
			            comercial_designation: res.provider?.comercial_designation || '',
			            document: res.provider?.document || '',
			            document_type_id: res.provider?.document_type_id || '',
			            email: res.provider?.email || '',
			            address: res.provider?.address || '',
			            phone: res.provider?.phone || '',
			            state_id: res.provider?.state_id || '',
			            municipality: res.provider?.municipality || '',
			            contact_person: res.provider?.contact_person || '',
			            schedule_start: res.provider?.schedule_start ? moment(res.provider?.schedule_start,'HH:mm').toDate() : '',
			            schedule_end: res.provider?.schedule_end ? moment(res.provider?.schedule_end,'HH:mm').toDate() : '',
			            code: res.provider?.code || '',
					}
				});
			}
			else if (this.props.match.params.provider_id) {
				this.props.history.push('/admin/providers');
			}
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.match.params.provider_id) {
			await ProviderService.admin.update({
				provider_id: this.props.match.params.provider_id,
				...this.state.form,
				schedule_start: this.state.form.schedule_start && moment(this.state.form.schedule_start).format('HH:mm'),
				schedule_end: this.state.form.schedule_end && moment(this.state.form.schedule_end).format('HH:mm')
			});
			Globals.showSuccess("Se ha modificado el proveedor correctamente");
			this.props.history.push('/admin/providers');
		}
		else {
			await ProviderService.admin.create({
				...this.state.form,
				schedule_start: this.state.form.schedule_start && moment(this.state.form.schedule_start).format('HH:mm'),
				schedule_end: this.state.form.schedule_end && moment(this.state.form.schedule_end).format('HH:mm')
			});
			Globals.showSuccess("Se ha creado el proveedor correctamente");
			this.props.history.push('/admin/providers');
		}
	}
	
	render() {
		return (
			<div id="create-provider">
				<ContainerRed title={ `${ this.props.match.params.provider_id ? 'Editar' : 'Crear' } Proveedores` }>	
					<form className="row" onSubmit={ this.submit }>
						<div className="col-md-6">
							<Input 
								value={ this.state.form.name }
								name="name" 
								label="Nombre Fiscal"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<label htmlFor="code">Rif</label>
							<div className="input-group rif-container">
								<div className="input-group-prepend">
									<Select
										name="document_type_id"
										onChange={ this.change }
										value={ this.state.form.document_type_id }
										options={ this.state.document_types.map((i: any) => {
											return {
												value: i.id,
												label: i.name
											}
										}) } />
								</div>
								<Input
									value={ this.state.form.document }
									name="document"
									onChange={ this.change } />
							</div>
						</div>
						<div className="col-md-6">
							<Input 
								value={ this.state.form.comercial_designation }
								name="comercial_designation" 
								label="Denominación comercial (Opcional)"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<label htmlFor="code">Código</label>
							<div className="input-group">
								<div className="input-group-prepend">
									<img src={ HashIcon } />
								</div>
								<Input
									type="number"
									maxLength={ 8 }
									value={ this.state.form.code }
									name="code"
									onChange={ this.change } />
							</div>
						</div>
						<div className="col-md-6">
							<label htmlFor="email">
								<img src={ MailIcon } />
								Correo Electrónico
							</label>
							<Input 
								value={ this.state.form.email }
								name="email"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<label htmlFor="address">
								<img src={ LocationIcon } />
								Dirección
							</label>
							<Input 
								value={ this.state.form.address }
								name="address" 
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<label htmlFor="phone">
								<img src={ PhoneIcon } />
								Teléfono
							</label>
							<Input 
								type="number"
								value={ this.state.form.phone }
								name="phone" 
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Input 
								value={ this.state.form.contact_person }
								name="contact_person" 
								label="Persona de Contacto"
								onChange={ this.change } />
						</div>
						<div className="col-md-6">
							<Select
								name="state_id"
								label="Estado"
								onChange={ this.change }
								value={ this.state.form.state_id }
								options={ this.state.states.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-6">
							{/*<Input 
								value={ this.state.form.municipality }
								name="municipality" 
								label="Municipio"
								onChange={ this.change } />*/}
						</div>
						<div className="col-md-8">
							<div className="row">
								<div className="col-md-6">
									<DatePicker
										placeholder="Desde"
										showTimeSelect={ true }
										showTimeSelectOnly={ true }
										timeCaption="Hora"
										calendarIcon={ TimeIcon }
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'schedule_start'
												}
											});
										} }
										dateFormat="h:mm aa"
										value={ this.state.form.schedule_start }
									/>
								</div>
								<div className="col-md-6">
									<DatePicker
										placeholder="Hasta"
										showTimeSelect={ true }
										showTimeSelectOnly={ true }
										timeCaption="Hora"
										calendarIcon={ TimeIcon }
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'schedule_end'
												}
											});
										} }
										dateFormat="h:mm aa"
										value={ this.state.form.schedule_end }
									/>
								</div>
							</div>
						</div>
						<div className="col-12 text-center">
							<Button type="submit">
								Guardar
							</Button>
						</div>
					</form>
				</ContainerRed>
			</div>
		)
	}
}

export default connect()(CreateProvider);