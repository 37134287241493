import React from 'react';
import { CheckGreenIcon, AddIcon } from 'assets/icons';
import { Table } from 'components';
import { WhiteImage } from 'assets/img';
import { Constants } from 'utils';

interface Props {
	products: any;
	load: () => void;
	count: number;
	onCreate: () => void;
	onViewMore: () => void;
}

class Products extends React.Component<Props> {

	state = {
		header: [
			'',
			'Producto'
		],
		data: this.props.products
	}
	
	render() {
		return (
			<div id="home-products">
				<div className="title-home">
					<div style={{ flex: .5 }}>
						<h3>Productos</h3>
						<div className="count-container">
							<img src={ CheckGreenIcon } />
							<p>{ this.props.count } Registrados</p>
						</div>
					</div>
					<div style={{ flex: .5 }} className="text-right">
						<div className="add-item" onClick={ () => {
							this.props.onCreate();
						} }>
							<img src={ AddIcon } />
							<p>Crear</p>
						</div>
					</div>
				</div>
				<Table title="" data={ this.props.products.length } header={ this.state.header }>
					{ this.props.products.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>
									<img src={ i.image ? (Constants.BASE_STORAGE + i.image) : WhiteImage } className="image-category" />
								</td>
								<td>{ i.name }</td>
							</tr>
						)
					}) }
				</Table>
				{
					this.props.count > this.props.products.length && (
						<div className="text-center">
							<a href="#" onClick={ (e: any) => {
								e.preventDefault();
								this.props.onViewMore();
							} } className="view-more">
								Ver Más
							</a>
						</div>
					)
				}
			</div>
		)
	}
}

export default Products;