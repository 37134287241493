import React from 'react';
import { Button, SelectSearch, Tooltip } from 'components';
import { ChecklistService } from 'services';
import { TrashIcon } from 'assets/icons';

interface Props {
	onClose: () => void;
	checklist: any;
}

class ModalUsers extends React.Component<Props> {

	state = {
		checklist: null,
		users: [],
		users_selected: []
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ChecklistService.admin.getUsers({
			checklist_id: this.props.checklist?.id
		});
		this.setState({
			checklist: res.checklist,
			users: res.users,
			users_selected: res.checklist?.users.map((i: any) => i.user_id)
		});
	}

	removeItem = (index: number) => {
		let users_selected: any = [...this.state.users_selected];
		users_selected.splice(index,1);
		this.setState({
			users_selected
		});
	}

	submit = async () => {
		await ChecklistService.admin.addUsers({
			checklist_id: this.props.checklist?.id,
			users: this.state.users_selected
		});
		this.props.onClose();
	}

	render() {
		const users_selected: any = this.state.users_selected;

		return (
			<div id="modal-checklist-users">
				<SelectSearch
					placeholder="Seleccionar trabajador"
					onChange={ (user: any) => {
						let users_selected: any = [...this.state.users_selected];
						users_selected.push(user.value);
						this.setState({
							users_selected
						});
					} }
					color="gray"
					searchRight
					value={ null }
					options={ this.state.users.filter((i: any) => users_selected.indexOf(i.id) == -1).map((i: any) => {
						return {
							value: i.id,
							label: i.name + ' ' + i.lastname
						}
					}) } />
				{
					users_selected.map((item: any,index: number) => {
						const user: any = this.state.users.find((i: any) => i.id == item);

						return (
							<div className="item">
								<div style={{ flex: .8 }}>
									<p>{ user?.name + ' ' + user?.lastname }</p>
								</div>
								<div style={{ flex: .2 }} className="text-right">
									<Tooltip title="Eliminar">
										<img src={ TrashIcon } className="trash" onClick={ () => {
											this.removeItem(index);
										} } />
									</Tooltip>
								</div>									
							</div>
						)
					})
				}

				{
					users_selected.length == 0 && (
						<p className="no-items">No hay usuarios asignados</p>
					)
				}
				<div className="text-center">
					<Button type="button" onClick={ () => this.submit() }>
						Guardar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalUsers;