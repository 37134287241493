import { Api } from 'utils';

class CategoryService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/categories/get',params),
		create: async (params?: any) => Api.createResponse('admin/categories/create',params),
		update: async (params?: any) => Api.createResponse('admin/categories/update',params),
		delete: async (params?: any) => Api.createResponse('admin/categories/delete',params),
		getTypes: async (params?: any) => Api.createResponse('admin/categories/types',params),
	}

}

export default CategoryService;