import { Api } from 'utils';

class AreaService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/areas/get',params),
		create: async (params?: any) => Api.createResponse('admin/areas/create',params),
		update: async (params?: any) => Api.createResponse('admin/areas/update',params),
		delete: async (params?: any) => Api.createResponse('admin/areas/delete',params)
	}

}

export default AreaService;