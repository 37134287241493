import { Api } from 'utils';

class WarehouseService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/warehouses/get',params),
		getWarehouses: async (params?: any) => Api.createResponse('admin/warehouses/get-warehouses',params),
		view: async (params?: any) => Api.createResponse('admin/warehouses/view',params),
		changeStatus: async (params?: any) => Api.createResponse('admin/warehouses/change-status',params),
		getContainer: async (params?: any) => Api.createResponse('admin/warehouses/view-container',params),
		create: async (params?: any) => Api.createResponse('admin/warehouses/create',params),
		update: async (params?: any) => Api.createResponse('admin/warehouses/update',params),
		getContainers: async (params?: any) => Api.createResponse('admin/warehouses/get-containers',params),
		createContainer: async (params?: any) => Api.createResponse('admin/warehouses/create-container',params),
		updateContainer: async (params?: any) => Api.createResponse('admin/warehouses/update-container',params),
		deleteContainer: async (params?: any) => Api.createResponse('admin/warehouses/delete-container',params),
		delete: async (params?: any) => Api.createResponse('admin/warehouses/delete',params),
	}

}

export default WarehouseService;