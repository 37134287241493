import { Api } from 'utils';

class DishesService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/dishes/get',params),
		view: async (params?: any) => Api.createResponse('admin/dishes/view',params),
		getData: async (params?: any) => Api.createResponse('admin/dishes/get-data',params),
		create: async (params?: any) => Api.createResponse('admin/dishes/create',params),
		update: async (params?: any) => Api.createResponse('admin/dishes/edit',params),
		delete: async (params?: any) => Api.createResponse('admin/dishes/delete',params),
	}

}

export default DishesService;