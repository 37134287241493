import React from 'react';
import { ReportService } from 'services';
import { Constants } from 'utils';
import { Calendar } from 'assets/icons';
import moment from 'moment';

interface Props {
	onClose: () => void;
	requisition: any;
}

class ModalView extends React.Component<Props> {

	state = {
		requisition: null
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ReportService.admin.viewRequisition({
			requisition_id: this.props.requisition?.id
		});
		this.setState({
			requisition: res.requisition
		});
	}

	render() {
		const requisition: any = this.state.requisition;

		return (
			<div id="modal-view-product-requisition">
				{
					requisition != null && (
						<React.Fragment>
							<div className="row">
								<div className="col-md-8">
									<p className="level-container">{ requisition?.level?.name }</p>
									<p className="calendar-container">
										<img src={ Calendar } className="calendar" />
										{ moment(requisition?.created_at).format('DD-MM-YYYY') }
									</p>
								</div>
								<div className="col-md-4">
									<div className={ `status-container ${ requisition.status == Constants.STATUS_REQUISITION.ACTIVE ? 'active' : requisition.status == Constants.STATUS_REQUISITION.FINISH ? 'finish' : 'cancel' }` }>
										<p>{ requisition.status_text }</p>
									</div>
								</div>
							</div>
							<table style={{ width: '100%' }}>
								<tr>
									<th style={{ width: '33.33%', fontSize: '12px' }}>Producto</th>
									<th style={{ width: '33.33%', fontSize: '12px' }}>Cantidad</th>
									<th style={{ width: '33.33%', fontSize: '12px' }}>Unidad de Medida</th>
								</tr>
								{
									requisition?.details?.map((item: any, index: number) => {
										return (
											<tr>
												<td style={{ width: '33.33%', fontSize: '12px' }}>{ item?.product?.name }</td>
												<td style={{ width: '33.33%', fontSize: '12px' }}>{ item?.dispatch?.defrost_weight || item?.dispatch?.weight || item?.quantity }</td>
												<td style={{ width: '33.33%', fontSize: '12px' }}>{ (item?.presentation?.unit?.name || item?.product?.unit?.name || '') }</td>
											</tr>
										)
									})
								}
							</table>
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default ModalView;