import React from 'react';

interface Props {
	onClose: () => void;
	observations: string;
}

class ModalObservation extends React.Component<Props> {

	render() {
		return (
			<div id="modal-view-observation">
				<p>{ this.props.observations }</p>
			</div>
		)
	}
}

export default ModalObservation;