import { Api } from 'utils';

class WorkService {
	
	static admin = {
		get: async (params = {}) => Api.createResponse('admin/works/get',params),
		create: async (params = {}) => Api.createResponse('admin/works/create',params),
		view: async (params = {}) => Api.createResponse('admin/works/view',params),
		getUsers: async (params = {}) => Api.createResponse('admin/works/users',params),
		delete: async (params = {}) => Api.createResponse('admin/works/delete',params),
	}

}

export default WorkService;