import React from 'react';
import { Button } from 'components';

interface Props {
	onClose?: () => void,
	onSubmit?: () => void,
	closeText?: string,
	submitText?: string
}

const ButtonsModal = (props: Props) => {
	return (
		<div className="buttons-modal-component">
			<div style={{ flex: .5 }}>
				<Button type="button" onClick={ () => {
					if (props.onClose)
						props.onClose();
				} }>
					{ props.closeText || 'Cerrar' }
				</Button>
			</div>
			<div style={{ flex: .5 }}>
				<Button type="submit" onClick={ () => {
					if (props.onSubmit)
						props.onSubmit();
				} }>
					{ props.submitText || 'Guardar' }
				</Button>
			</div>
		</div>
	)
}

export default ButtonsModal;